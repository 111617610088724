import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { DatacastAPIGetStreams } from '../db/datacast';
import { DbStreamsMap } from '../db/streams';
import { DbAgentsMap } from '../db/agents';
import { useInterval } from '../hooks/useinterval';
import { formatUptime, formatPackets } from '../utils/misc';
import { StatusMap } from '../common/common';

const Streams = ({cloud}) => {
    const [streams, setStreams] = useState([]);
    const [delay] = useState(5000);

    const getStreams = async() => {

        const _streams = await DatacastAPIGetStreams(cloud);

        const _streamsMap = await DbStreamsMap(null, 'source');

        const _agentsMap = await DbAgentsMap();

        const _streamsShow = [];

        let even = true;

        for(let _stream of _streams) {
            if(_stream.Type === 'source') {
                even = !even;
            }
            
            _stream.even = even;

            if(_streamsMap[_stream.Source] || _stream.accessGroup !== null) {
                _stream.userSource = _streamsMap[_stream.Source]?_streamsMap[_stream.Source].name:_stream.Source;
      
                _stream.userAgent = _agentsMap[_stream.Agent]?_agentsMap[_stream.Agent].name:_stream.Agent;            
                _streamsShow.push(_stream);
            }
        }

        setStreams(_streamsShow);

    }

    useEffect(() => {
 
        
        getStreams().catch((error)=>console.error(error));
    
    },[cloud]); 

    useInterval(() => {

        getStreams().catch((error)=>console.error(error));

    }, delay);

    const headerTemplate = (data) => {

        const streamCount = calculateServerTotals(data.App);
        
        return (
            <React.Fragment>
                <div className='text-lg font-bold'>{`${(streamCount.server)?`Server: ${streamCount.server}`:'P2P:'} (Src ${streamCount.src}, Dst ${streamCount.dst})`}</div>
            </React.Fragment>
        );
    }
    

    const calculateServerTotals = (server) => {
        let srcCount = 0;
        let dstCount = 0;
        let serverName = '';

        if (server) {
            for (let stream of streams) {
                if (stream.App === server) {
                    if(stream.Type === 'source') {
                        srcCount++;
                        if(stream.DstName) {
                            serverName = stream.DstName;
                        }
                    }
                    else {
                        dstCount++;
                        if(stream.SrcName) {
                            serverName = stream.SrcName;
                        }
                    }
                }
            }
        }

        return {src:srcCount, dst:dstCount, server:serverName};
    }

    const uptimeBodyTemplate = (rowData) => {
        if(rowData.UpSecs !== null) {
            return (
                <React.Fragment>
                    {formatUptime(rowData.UpSecs)}
                </React.Fragment>
            );
        }
    }

    const packetsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {formatPackets(rowData.Packets)}
            </React.Fragment>
        );
    }
    
    const retryBodyTemplate = (rowData) => {

        const percent = (rowData.Packets > 0  && rowData.Retry > 0) ? `(${(rowData.Retry/rowData.Packets*100).toFixed(0)}%)` : '';

        return (
            <React.Fragment>
                {`${formatPackets(rowData.Retry)} ${percent}`}
            </React.Fragment>
        );
    }

    const droppedBodyTemplate = (rowData) => {

        const percent = (rowData.Packets > 0  && rowData.Dropped > 0) ? `(${(rowData.Dropped/rowData.Packets*100).toFixed(0)}%)` : '';

        return (
            <React.Fragment>
                {`${formatPackets(rowData.Dropped)} ${percent}`}
            </React.Fragment>
        );
    }

    const bitrateBodyTemplate = (rowData) => {
        const percent = rowData.Bitrate ? (rowData.Bitrate.toFixed(0)): 0;
        return ( percent );
    }

    const editBodyTemplate = (rowData) => {
        let className = '';
        
        if(rowData.Active === 0) {
            className=`${StatusMap['information'].className}`;
        }
        else if (rowData.Active === 2) {
            className=`${StatusMap['warning'].className}`;
        }
        else {
            className=`${StatusMap['success'].className}`;
        }

        return(
        <div className='flex justify-content-end'>
            <Link to={`/analysis/streams`} state={{Stream:rowData.StreamID}} style={{ textDecoration: 'none' }}>
                <Button className={className} icon="pi pi-chart-line"/>
            </Link>
        </div>
        );
    }

    const rowClass = (rowData) => {
        let even = rowData.even?"bg-blue-50":'bg-gray-50';
       
        if(rowData.Type === 'destination') {
            return (`${even} text-600`);
        }
        else if(rowData.Type === 'source') {
            return (`${even} text-blue-600 font-medium`);
        }
        else {
            return (`${even} text-orange-600`);
        }
    }

    return (
        <>
            <div className='flex align-content-center' style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='p-3 text-white font-bold'>STREAMS</div>
            </div>

            <div className="grid">
                <div className="col-12 p-3">
                    <DataTable value={streams} responsiveLayout="scroll" rowGroupMode="subheader" groupRowsBy="App"
                        rowGroupHeaderTemplate={headerTemplate}
                        rowClassName={rowClass}
                        filterDisplay="row"
                        sortMode="single" sortField="App" sortOrder={-1} scrollable scrollHeight="600px">
                        <Column field="userSource" header="Source" filter  sortable></Column>
                        <Column field="userAgent" header="Agent"   filter  sortable></Column>
                        <Column field="Bitrate" header="Bitrate (kbps)" body={bitrateBodyTemplate}></Column>
                        <Column field="Packets" header="Packets" body={packetsBodyTemplate}></Column>
                        <Column field="Retry" header="Retry" body={retryBodyTemplate}></Column>
                        <Column field="Dropped" header="Dropped" body={droppedBodyTemplate} sortable></Column>
                        <Column field="UpSecs" header="Up Time" body={uptimeBodyTemplate} style={{width:'5%'}}></Column>
                        <Column field="RTT" header="RTT" style={{width:'5%'}}></Column>
                        <Column body={editBodyTemplate} style={{width:'5%'}} ></Column>
                    </DataTable>
                </div>
            </div>
        </>        
    )
}

export default Streams;