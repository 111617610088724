import { DatacastAPIGetSourcesMap, DatacastAPIGetServers } from '../db/datacast';
import { DbStreams } from '../db/streams';


export const GetStreamInformation =  (stream, servers) => {
    
    const urlProps = {primary:{}, backup:{}};

    if(stream) {
        if(stream.primaryStreamId) {
            const server = servers.find((e)=>(e.ipAddr === stream.ipAddr && e.port === stream.port));
            const urlPrimaryProps = stream.primaryStreamId.match(/([^/]+){4}/g);
            if (urlPrimaryProps.length === 4) {
                urlProps.primary.domain = urlPrimaryProps[0];
                urlProps.primary.app = urlPrimaryProps[1];
                urlProps.primary.agent = urlPrimaryProps[2];
                urlProps.primary.source = urlPrimaryProps[3];
                urlProps.primary.server = server?{label:server.label, uuid:server.value}:null;
            }
        }

        if(stream.backupStreamId) {
            const server = servers.find((e)=>(e.ipAddr === stream.backupIpAddr && e.port === stream.backupPort));
            const urlBackupProps = stream.backupStreamId.match(/([^/]+){4}/g);
            if (urlBackupProps.length === 4) {
                urlProps.backup.domain = urlBackupProps[0];
                urlProps.backup.app = urlBackupProps[1];
                urlProps.backup.agent = urlBackupProps[2];
                urlProps.backup.source = urlBackupProps[3];
                urlProps.backup.server = server?{label:server.label, uuid:server.value}:null;
            }
        }
    }


    return urlProps;
}

export const GetServerOptions = async (datacastProperties) => {
    const _serverOptions = await DatacastAPIGetServers(datacastProperties.ip);
    const _apps = _serverOptions.reduce((arrApps, cur)=>{ 
        arrApps.push(cur.app);
        return  arrApps;
    },[]);

    _serverOptions.forEach((e)=>{
        e.apps = _apps;
    });

    return _serverOptions;
}


export const GetSourceOptions = async (datacastProperties) => {
    const dcastSources = await DatacastAPIGetSourcesMap(datacastProperties.ip, datacastProperties.accessGroup);
    const _cSources = await DbStreams(datacastProperties.userIp, 'source');
    const _sourceOptions = [];
    let _cSourcesMap = {};
    let _extras = {}

    //Go through all the cortex sources
    for (let source of _cSources) {
        //Check if the user is allowed all the sources or just a restricted list.
        if ( !datacastProperties.agentMap || datacastProperties.agentMap[source.agentId]) {
            _sourceOptions.push({
                label:source.name,
                value:source.uuid,
                uuid: source.uuid,
                server: {
                    ip: source.ipAddr,
                    port: source.port,
                    uuid: source.primaryServer
                }

            })
            
            _cSourcesMap[source.uuid] = source;
        }
        else {
            _extras[source.uuid] = source;
        }
    }

    //Go through all the datacast stream sources 
    //Add any of them that you have access to.
    //that have not yet been added.
    for(let sourceId in dcastSources) {
        let source = dcastSources[sourceId];
        
        if(!_cSourcesMap[sourceId] && ((datacastProperties.accessGroup === 'ALL' && source.GroupUuid !== null) || source.GroupUuid === datacastProperties.accessGroup) ) {
            //If they are restricted from a partciular source
            if(!_extras[sourceId]) {
                _sourceOptions.push({
                    label:sourceId,
                    value:source.value,
                    uuid: sourceId
                })
            }
        }
    }

    return _sourceOptions;
}


export const GetBackupOptions = async (datacastProperties, sourceId) => {
    const _backupOptions = [];
    const _serverOptions = await DatacastAPIGetServers(datacastProperties.ip);
    const _sourceOptions = await GetSourceOptions(datacastProperties);

    for(let _server of _serverOptions) {
        _backupOptions.push({
            destination: _server.destination,
            source: _server.source,
            ipAddr: _server.ipAddr,
            port: _server.port,
            label: _server.label,
            value: _server.value,
            stream: sourceId
        })
    }

    for(let _source of _sourceOptions) {
        if(_source.server) {
            const _server = _serverOptions.find((e)=>e.value === _source.server.uuid);
            if(_server) {
                _backupOptions.push({
                    destination: _server.destination,
                    source:_server.source,
                    ipAddr: _source.server.ip,
                    port: _source.server.port,
                    label: _source.label,
                    value: _source.uuid,
                    server:_server,
                    stream: _source.uuid
                })
            }
        }
    }

    return _backupOptions;
}

export const StatusMap = {
    success:{
        className:'bg-green-600 border-green-600',
    },
    warning:{
        className:'bg-yellow-600 border-yellow-600'
    },
    error:{
        className:'bg-red-600 border-red-600'
    },
    information:{
        className:'bg-gray-200 border-gray-200'
    }
}