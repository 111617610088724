import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { SrtServerAPIGetHealth } from '../db/srtserver';

import ServerConnect from '../serverconnect/serverconnect';

const Server = ({server, cloud}) => {
    const [serverData, setServerData] = useState(server);
    const [displayConnections, setDisplayConnections] = useState(false);

    useEffect(() => {

        const _serverData = {...serverData};

        if(_serverData.status !== null) { 

            if(_serverData.ip) {
                const match = _serverData.ip.match(/^(http:\/\/|https:\/\/|www\.)/g);
                const _ip = (match) ? _serverData.ip: `http://${_serverData.ip}:${_serverData.controlport}`;
                const newIp = _ip.replace(/\/\s*$/,'');

                SrtServerAPIGetHealth(newIp).then((result) => {
                    const [cpu, memory, disk] = result;

                    if(cpu) {
                        const _cpuUse = 100 - cpu.currentLoadIdle;
                        _serverData.cpuUse = _cpuUse;
                    }
                    if(memory) {
                        const _memoryUse = (memory.active / memory.total)*100;
                        _serverData.memoryUse = _memoryUse;
                    }

                    if(disk && disk.length > 0) {
                        const disk1 = disk[0];
                        const _diskUse = disk1.use;
                        _serverData.diskUse = _diskUse;
                    }

                    setServerData(_serverData);

                }).catch((error) => {
                    console.error(error);
                })

            }
        }
        
        
    },[server]); //Run each time id changes.


    const footer = (uuid) => {

        return(
            <div className="flex justify-content-center align-items-end" >
                <div>
                    <Link to={`/srtserver`} state={uuid} style={{ textDecoration: 'none' }} >
                        <Button label="View" icon="pi pi-eye" className="p-button-secondary ml-2" />
                    </Link>
                </div>
                <div>
                    <Button label="Edit" icon="pi pi-pencil" className="p-button-primary ml-2" onClick={()=>setDisplayConnections(true)} />
                </div>
            </div>
        )       
    }

    const textClassMap = (use) => {
        if(use > 80) {
            return('flex text-red-500 text-sm');
        }
        else if( use > 50) {
            return('flex text-yellow-500 text-sm');
        }
        else {
            return('flex text-green-500 text-sm');
        }

    }

    const header = (server) => {
        return (
        <div className='flex justify-content-between align-items-center' style={{backgroundColor:"rgb(64,64,64)", height:"3em"}}>
            <div className='flex ml-3 text-white text-2xl w-6'>{server.name}</div>
            <div className='flex justify-content-end w-6'>
                <div className='flex-column px-1'>
                    <div className='flex text-white text-sm'>CPU</div>
                    <div className={textClassMap(server.cpuUse)}>{(server.cpuUse)?`${server.cpuUse.toFixed(0)}%`:'N/A'}</div>
                </div>
                <div className='flex-column px-1 text-sm'>
                    <div className='flex text-white'>MEM</div>
                    <div className={textClassMap(server.memoryUse)}>{(server.memoryUse)?`${server.memoryUse.toFixed(0)}%`:'N/A'}</div>
                </div>
                <div className='flex-column px-1 text-sm'>
                    <div className='flex text-white'>DISK</div>
                    <div className={textClassMap(server.diskUse)}>{(server.diskUse)?`${server.diskUse.toFixed(0)}%`:'N/A'}</div>
                </div>
            </div>
        </div>
        )
    } 

    const borderMap = (server) => {

        if(server.srcs > 0 || server.lnks > 0 || server.dests > 0) {
            if(server.cpuUse > 80 || server.memoryUse > 80 || server.diskUse > 80) {
                return 'border-red-500';
            }
            else {
                return 'border-green-500';
            }
        }
        else {

            return 'border-300';
        }
    }
  
    return(
        <div>
            <Dialog header="Network" visible={displayConnections} style={{ width: '50vw' }} onHide={() => setDisplayConnections(false)}>
                <ServerConnect cloud={cloud} server={serverData.uuid} />
            </Dialog>
            <div key={serverData.uuid} className={`flex m-5 border-3 border-round-top ${borderMap(serverData)} `} style={{ borderRadius:'10px'}}>
                <Card   header={header(serverData)}
                        style={{ width: '20rem', height: '17rem'}}
                        footer={footer(serverData.uuid)}
                >
                    <div className="flex justify-content-center">
                    <p className="m-0" style={{lineHeight: '1.5'}}>
                        {`IP: ${serverData.ip}`}
                    </p>
                    </div>
                    <div className="flex justify-content-center">
                        <p className="m-0" style={{lineHeight: '1.5'}}>
                            {`${serverData.geo.city}, ${serverData.geo.region}, ${serverData.geo.country}`}
                        </p>
                    </div>
                    <div className="flex justify-content-center">
                        <p className="m-0" style={{lineHeight: '1.5'}}>
                            {`${serverData.geo.timezone}`}
                        </p>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default Server;