import React, { useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';

import { postData } from '../utils/db';

const ServerConnect = ({cloud, server}) => {

    const [originalSettings, setOriginalSettings] = useState([]);
    const [connections, setConnections] = useState([]);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        
        const fetchConnections = async () => {
            const response = await fetch(`${cloud}/api/v1/datacast/srtservers/connects/${server}`);
            if(response.ok) {
                
                const data = await response.json();
                if(data){
                    setConnections(data);
                    //Not only does the array have to be new by the ojects as well.
                    const newArray = data.map((item)=>{
                    return {...item}});
                    setOriginalSettings(newArray);
                }            
            }
        }

        fetchConnections().catch((error)=>{

            console.error(error)
        });
        
    },[cloud, server]); //Run each time id changes.

    const enableBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Checkbox checked={rowData.connected}></Checkbox>
            </React.Fragment>
        );
    }

    const connectEditor = (options) => {
        return <Checkbox checked={options.value} onChange={(e) => options.editorCallback(e.target.checked) } />;
    }

    const onCellEditComplete = (e) => {

        let { rowData, newValue, field, originalEvent: event, rowIndex } = e;

        setIsDirty(newValue !== originalSettings[rowIndex].connected);

        rowData[field] = newValue;
        event.preventDefault();
    }

    const onUpdateServer = () => {
    
        let changes = [];
        for(let idx=0; idx < originalSettings.length; idx++) 
        {
            if(originalSettings[idx].connected !== connections[idx].connected) {
                changes.push(connections[idx]);
            }
        }

        postData(`${cloud}/api/v1/datacast/srtservers/connects/${server}`, changes).then((data)=>{ 
            setIsDirty(false);
        });
        
    }

    return(
        <>
            <DataTable value={connections} editMode="cell" responsiveLayout="scroll">
                <Column field="connected" header="Connect" body={enableBodyTemplate} headerStyle={{width: '3em'}}
                        editor={(options)=>connectEditor(options)} onCellEditComplete={(e)=>onCellEditComplete(e)}>

                </Column>
                <Column field="Name" header="Server"></Column>
                <Column field="Upstreams" header="Stream ID"></Column>
            </DataTable>
            {
            (isDirty)? 
                <div className='m-2 flex justify-content-end'>
                    <Button className="bg-primary" label='Update' onClick={()=>onUpdateServer()}></Button>
                </div>
                : ''
            }
        </>
    )

}

export default ServerConnect;