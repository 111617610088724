import React, { useEffect, useState, useContext, useRef } from 'react';

import { useLocation } from "react-router-dom";

import Chart from 'react-apexcharts';
import { Dropdown } from 'primereact/dropdown';
import { plotOptions } from './chartoptions';
import { Button } from 'primereact/button';
import { Slider } from 'primereact/slider';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { DatacastAPIGetStreams, DatacastAPIGetStreamDropped, DatacastAPIGetAllStreamStats } from '../db/datacast';
import { DbAgents } from '../db/agents';
import { DbStreamsMap } from '../db/streams';
import { formatUptime, getLocalTimeTicks } from '../utils/misc';
import { DatacastContext } from '../context/datacastcloud';



const StreamAnalysis = () => {

    const [statData, setStatData] = useState([]);
    const [streams, setStreams] = useState([]);
    const [tableStream, setTableStream] = useState([]);
    const [selectedStream, setSelectedStream] = useState(null);

    const [drops, setDrops] = useState([]);
    const [selectedDrop, setSelectedDrop] = useState(null);

    const [ datacastProperties ] = useContext(DatacastContext);

    const location = useLocation();


    const stream = location.state?.Stream;

    const [selectedTime, setSelectedTime] = useState(new Date());
    const [currentDate] = useState(new Date());
    
    const op = useRef(null);
  
  
    const timeIntervalOptions = [
      {label: '10 MIN', value:5},
      {label: '20 MIN', value:10},
      {label: '30 MIN', value:15},
      {label: '1 HR', value:30}
    ];
  
    const [selectedIntervalOption, setSelectedIntervalOption] = useState(timeIntervalOptions[0].value);
    const [sliderProperties, setSliderProperties] = useState({step:1, max:Math.floor(24*60 / timeIntervalOptions[0].value)});
    const [sliderValue, setSliderValue] = useState(Math.floor(24*60 / timeIntervalOptions[0].value) - 1);

    const onSelectedTimeChange = (value) => {
    
      setSelectedDrop(value);
      if(value && value.StatDate) {
        const newDate = new Date(value.StatDate);
        setSelectedTime(newDate);
        
        setSliderValue((newDate.getTime() - (currentDate.getTime() - 24*3600*1000))/(selectedIntervalOption*60000));

        DatacastAPIGetAllStreamStats(datacastProperties.ip, selectedStream.StreamID, newDate, selectedIntervalOption).then((result)=>{
          setStatData(result);
        }).catch((err)=>console.error(err));
      
      }
    }
  
    const onIntervalChange = (value) => {
  
      setSelectedIntervalOption(value);
  
      let ratio = null;
  
      if (value > selectedIntervalOption) {
        ratio = value / selectedIntervalOption;
        
      }
      else {
        ratio = selectedIntervalOption / value;
      }
  
      const newValue = (value > selectedIntervalOption) ? Math.floor(sliderValue / ratio): Math.floor(sliderValue * ratio);
  
      //Convert slider value
      setSliderProperties({step:1, max:Math.floor(24*60 / value)});
      setSliderValue(newValue);
  
      DatacastAPIGetAllStreamStats(datacastProperties.ip, selectedStream.StreamID, selectedTime, value).then((result)=>{
        setStatData(result);
      }).catch((err)=>console.error(err));
    }

    const onSliderEnd = (value) => {
      
      const _selectedTime = (currentDate.getTime() - 24*3600*1000) + value*selectedIntervalOption*60*1000;
      
      DatacastAPIGetAllStreamStats(datacastProperties.ip, selectedStream.StreamID, new Date(_selectedTime), selectedIntervalOption).then((result)=>{
        setStatData(result);
      }).catch((err)=>console.error(err));
    }

    const onSliderChange = (value) => {
      
      const _selectedTime = (currentDate.getTime() - 24*3600*1000) + value*selectedIntervalOption*60000;
      setSliderValue(value);

      setSelectedTime(new Date(_selectedTime));
    }

    const dropTemplate = (rowData) => {
      
      return (getLocalTimeTicks(new Date(rowData.StatDate)));
    }

    const updateData = () => {
      DatacastAPIGetAllStreamStats(datacastProperties.ip, selectedStream.StreamID, selectedTime, selectedIntervalOption).then((result)=>{
        setStatData(result);
      }).catch((err) => console.error(err));
    }




    useEffect(() => {

      const getStreams = async (ip) => {

        const _selectedTime = new Date(new Date().getTime() - selectedIntervalOption*60000);

        const _streams = await DatacastAPIGetStreams(datacastProperties.ip);
        setStreams(_streams);

        const streamsMap = await DbStreamsMap(datacastProperties.userIp, 'SOURCE');

        const _agents = await DbAgents(datacastProperties.userIp);
        
        const agentsMap = {};
        for(let _agent of _agents) {
          agentsMap[_agent.uuid] = _agent;
        }
        
       
        for(let _stream of _streams) {
          _stream.StreamName = streamsMap[_stream.Source] ? streamsMap[_stream.Source].name : _stream.Source;
          _stream.AgentName = agentsMap[_stream.Agent]?agentsMap[_stream.Agent].name : _stream.Agent;
        }

        
        if(_streams.length > 0) {
          if(stream) {
            const _stream = _streams.find((el)=>el.StreamID === stream);
            setTableStream([_stream]);
            onStreamChanged(_stream, _selectedTime);
          }
          else {

            if(selectedStream === null) {
              setTableStream([_streams[0]]);
              onStreamChanged(_streams[0], _selectedTime);
            }
            else {
              onStreamChanged(selectedStream.StreamID, _selectedTime);
            }
          }
        }

      }

      
      getStreams(datacastProperties.ip).catch((error) => {
          console.error(error);
      });
      


    },[stream]); //Run each time id changes.



    const onStreamChanged = (value, time) => {

      const _selectedStream = streams.find((element)=>element.StreamID === value);

      const _selectedTime = (time)?time:selectedTime;
      //If you could find a selected stream, if not.
      if(_selectedStream) {
        setSelectedStream(_selectedStream);
        setTableStream([_selectedStream]);
        DatacastAPIGetStreamDropped(datacastProperties.ip, _selectedStream.StreamID).then((result)=>{
          setDrops(result);
        }).catch((err) => console.error(err));

        DatacastAPIGetAllStreamStats(datacastProperties.ip, _selectedStream.StreamID, _selectedTime, selectedIntervalOption).then((result)=>{
          setStatData(result);
        }).catch((err) => console.error(err));

      }
      else {
        setSelectedStream(value);
        setTableStream([value]);
        DatacastAPIGetStreamDropped(datacastProperties.ip, value.StreamID).then((result)=>{
          
          setDrops(result);
        }).catch((err) => console.error(err));
        
        DatacastAPIGetAllStreamStats(datacastProperties.ip, value.StreamID, _selectedTime, selectedIntervalOption).then((result)=>{
          setStatData(result);
        }).catch((err) => console.error(err));
      }

    }

    const refreshData = () => {
      DatacastAPIGetAllStreamStats(datacastProperties.ip, selectedStream.StreamID, selectedTime, selectedIntervalOption).then((result)=>{
        setStatData(result);
      }).catch((err) => console.error(err));
    }

    const streamTemplate = (option) => {

      if(option) {
        return (
            <div className="country-item">
                <div>{`${option.Type === 'destination'?'DST':'SRC'} ${option.StreamID} - ${option.StreamName}/${option.AgentName}/${option.App} `}</div>
            </div>
        );
      }
    }

    const moveSlide = (step) => {
      const newValue = sliderValue + step;

      setSliderValue(newValue);

      const newDateTime = (currentDate.getTime() - 24*3600*1000) + newValue * (selectedIntervalOption*60000);
      const newDate = new Date(newDateTime);

      setSelectedTime(newDate);
      updateData({date:newDate});
    }

    const uptimeBodyTemplate = (rowData) => {
      if(rowData.UpSecs !== null) {
          return (
              <React.Fragment>
                  {formatUptime(rowData.UpSecs)}
              </React.Fragment>
          );
      }
  }    

    const availability = (rowData) => {
        const percent = 100 - ((rowData.Dropped / rowData.Packets * 100).toFixed(3));
        return isNaN(percent)?0:percent;
    }

    const droppedBodyTemplate = (rowData) => {
        const percent = 100 - ((rowData.Dropped / rowData.Packets * 100).toFixed(3));
        if(rowData.Dropped !== null) {
            return (
                <React.Fragment>
                    {`${rowData.Dropped} (${(availability(rowData) === 0) ?'N/A':`${percent}%`})`}
                </React.Fragment>
            );
        }
        else {
            return;
        }
    }

    const alertBodyTempate = (rowData) => {

      if(rowData.Alert > 0) {
          return (
              <Button label={'!'} className="p-button-sm p-button-rounded p-button-danger" />
          );
      }
      else {
          return(
              <Button icon="pi pi-check" className="p-button-sm p-button-rounded p-button-success" />
          )
      }
      
  }

  const typeTemplate = (rowData) => {
    if(rowData.Type === 'destination') {
      return (`DST (${rowData.SrcName?rowData.SrcName:'P2P'})`);
    }
    else if(rowData.Type === 'source') {
      return (`SRC (${rowData.DstName?rowData.DstName:'P2P'})`);
    }
    else {
      return (`LINK (${rowData.SrcName} - ${rowData.DstName})`);
    }

  }

    return(
      <>
        <div className='flex align-items-center' style={{backgroundColor:"rgb(64,64,64)"}}>
          <div className='p-3 text-white font-bold'>STREAMS</div>
          <Dropdown className="border-round-md" itemTemplate={streamTemplate} valueTemplate={streamTemplate} optionLabel="Agent" optionValue="StreamID" value={selectedStream && selectedStream.StreamID} options={streams} onChange={(e)=>onStreamChanged(e.value)} placeholder="Select a Stream"/>
          <Button icon="pi pi-refresh" className="p-button-rounded p-button-success p-button-text" onClick={refreshData}/>
        </div>
        <div className='w-full'>
          <div className='flex align-items-center m-2 justify-content-between'>
            <div className='flex align-items-center'>
              <div className='text-xl font-medium'>Stream Analysis</div>
            </div>
            <div>
              {getLocalTimeTicks(selectedTime)}
            </div>
            <Dropdown options={timeIntervalOptions} optionLabel="label" optionValue="value" value={selectedIntervalOption} onChange={(e)=>onIntervalChange(e.value)} />
          </div>

          <div className="w-full">
            <Chart options={plotOptions} series={statData} type="line" height={300} />
          </div>

          <div className='flex align-content-center  w-full border-dashed border-1 surface-border surface-100 border-round-md'>
            <div className='flex w-2 m-3 align-items-center'>
              <Button icon="pi pi-table" style={{backgroundColor:"rgb(64,64,64)"}}  label="Select Drop" onClick={(e) => op.current.toggle(e)} />
              <OverlayPanel ref={op} id="overlay_panel" showCloseIcon >
                <DataTable value={drops} rows={10} paginator selection={selectedDrop} onSelectionChange={(e)=>onSelectedTimeChange(e.value)} >
                  <Column selectionMode="single" headerStyle={{width:'3em'}} />
                  <Column field="StatDate" header="Time" body={dropTemplate} sortable />
                  <Column field="Dropped" header="Dropped Packets"  sortable />
                </DataTable>

              </OverlayPanel>
            </div>
            <div className='flex w-10 align-items-center m-3'>
              <div className='flex align-items-center'>
                {getLocalTimeTicks(new Date(currentDate.getTime() - 24*3600*1000))}
                <Button  className='p-button-text' icon="pi pi-chevron-left" onClick={()=>moveSlide(-1)}/>
              </div>
              <Slider className='w-full' {...sliderProperties} value={sliderValue} onChange={(e)=>onSliderChange(e.value)} onSlideEnd={(e)=>onSliderEnd(e.value)} />
              <div className='flex align-items-center'>
                <Button className='p-button-text' icon="pi pi-chevron-right" onClick={()=>moveSlide(1)} />
                {getLocalTimeTicks(currentDate)}
              </div>
            </div>
          </div>
          <div className='mt-3'>
            <DataTable value={tableStream} >
                <Column field="Type" header="Type" body={typeTemplate}></Column>
                <Column field="StreamName" header="Source"></Column>
                <Column field="AgentName" header="Agent"></Column>
                <Column field="Bitrate" header="Bitrate (kbps)"></Column>
                <Column field="Packets" header="Packets"></Column>
                <Column field="Retry" header="Retry"></Column>
                <Column field="Dropped" header="Dropped" body={droppedBodyTemplate} ></Column>
                <Column field="UpSecs" header="Up Time" body={uptimeBodyTemplate} ></Column>
                <Column field="Alerts" header="Alerts" body={alertBodyTempate} ></Column> 
            </DataTable>
          </div>
        
        </div>
      </>
    )
}

export default StreamAnalysis;