import React, { useState, useEffect, useContext, useRef } from 'react';

import { Link } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';

import { DatacastContext } from '../context/datacastcloud';
import { DbAgents } from '../db/agents';
import { DbStreams } from '../db/streams';
import { DbAlerts, DbAlertsDelete } from '../db/alerts';
import { useInterval } from '../hooks/useinterval';
import { getLocalTime } from '../utils/misc';

const Alerts = () => {
    const [ datacastProperties ] = useContext(DatacastContext);
    const [alerts, setAlerts] = useState([]);
    const [delay] = useState(5000);
    const [refreshKey, setRefreshKey] = useState(0);

    const toast = useRef(null);

    const getAgentsandStreams = async() => {
        const _agents = await DbAgents(datacastProperties.userIp);
        const _agentObj = {};
        const _sourcesObj = {};

        if(_agents) {
            
            for(let agent of _agents) {
                _agentObj[agent.uuid] = agent;
            }
        }

        const _sources = await DbStreams(datacastProperties.userIp, 'source');

        if(_sources) {
            
            for(let source of _sources) {
                _sourcesObj[source.uuid] = source;
            }
        }


        const _alerts = await DbAlerts(datacastProperties.userIp, datacastProperties.id);

        if(_alerts) {
            for(let alert of _alerts) {
                if(_agentObj && _agentObj[alert.agent]) {
                    alert.AgentT = _agentObj[alert.agent].name;
                }
                else {
                    alert.AgentT =  alert.agent;
                }
    
                if(_sourcesObj && _sourcesObj[alert.source]) {
                    alert.SourceT = _sourcesObj[alert.source].name;
                }
                else {
                    alert.SourceT =  alert.source;
                }
            }

            setAlerts(_alerts);
        }
        
    }


    useEffect(() => {
    
        getAgentsandStreams().then((result) => {

        }).catch((error) => {
            console.error(error);
        });

    
    },[refreshKey]); 

    useInterval(() => {

        getAgentsandStreams().then((result) => {
            
        }).catch((error) => {
            console.error(error);
        });

    }, delay);


    const timeBodyTemplate = (rowData) => {
        
        const alertTime = getLocalTime(new Date(rowData.createdAt));
        return (
            <React.Fragment>
                
                {alertTime}
                
            </React.Fragment>
        );
    }


    const categories = [
        {label:'Danger', value:1},
        {label:'Warning', value:2},
        {label:'Information', value:3}
    ]

    const categoryMap = {
        1:'Danger',
        2:'Warning',
        3:'Information'
    }

    const categoryBodyTemplate = (rowData) => {
        
        return (
            <div className='flex justify-content-center'>
                <Badge value={categoryMap[rowData.category]} size="small" severity={categoryMap[rowData.category].toLowerCase()}></Badge>
            </div>
        )
    }

    const categoryRowFilterTemplate = (options) => {
        return <Dropdown optionLabel="label" optionValue="value" filterBy="value" value={options.value} options={categories} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={categoryItemTemplate} placeholder="Select a Category" className="p-column-filter" showClear />;
    }

    const categoryItemTemplate = (option) => {
        
        return (
                <Badge value={option.label} size="small" severity={option.label.toLowerCase()}></Badge>
        );
    }


    const messageBodyTemplate = (rowData) => {
        
        return (
                `${rowData.streamType.toUpperCase()}: ${rowData.message}`
        );
    }

    const accept = (alertIds) => {
        DbAlertsDelete(datacastProperties.userIp, datacastProperties.id, alertIds).then((result) => {
            setRefreshKey(oldKey => oldKey +1)
            if(result.error === 0) {
                showResult('success', `Deleted user alert`, result.message);
            }
            else {
                showResult('danger', `Could not delete user alert`, result.message);
            }
        }).catch((error) => {
            showResult('danger', `Could not delete user alert`, error);
        });
    }

    const reject = () => {
        showResult('warn', `Delete canceled`, 'Deleting alerts canceled');
    }

    const onDeleteAlert = (event, alertIds) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to delete alerts?',
            icon: 'pi pi-exclamation-triangle',
            accept: ()=>accept(alertIds),
            reject
        });

    }

    const showResult = (severity, summary, detail) => {
        toast.current.show({severity:severity, summary:summary, detail:detail});
    }

    const editAlerts = (rowData) => {

        return (
            <div className='flex align-items-center justify-content-end'>
                <div>
                    <Link to="/analysis/paths" state={{Source:rowData.source, Agent:rowData.agent, Server:null}} style={{ textDecoration: 'none' }}>
                        <div className='flex justify-content-start align-items-center'>
                            <Button className={`p-button-rounded p-button-text`} icon="pi pi-chart-line" />
                        </div>
                    </Link>
                </div>
                <div>
                    <Button className='p-button-rounded p-button-text p-button-danger p-button-sm' icon='pi pi-times' onClick={(e)=>onDeleteAlert(e, [rowData.id])} />
                </div>
            </div>
        );
    }
    
    

    return (
                
        <>
            <Toast ref={toast} />
            <ConfirmPopup />
            <div className='flex align-content-center justify-content-between' style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='p-3 text-white font-bold'>ALERTS<span className="text-base font-normal ml-1 text-white">({alerts.length})</span></div>
                <Button className='p-button-rounded m-2 bg-white text-900' label="Clear All" icon="pi pi-times" onClick={(e)=>onDeleteAlert(e, [])} />
            </div>
            <div className="grid">
                <div className="col-12 p-3">
                    <DataTable value={alerts} responsiveLayout="scroll" columnResizeMode="fit" 
                        dataKey="id"
                        sortMode="single" sortField="createdAt" sortOrder={-1} 
                        filterDisplay="row"
                        emptyMessage="No alerts found."
                        stripedRows scrollable scrollHeight="600px">
                        <Column field="createdAt" body={timeBodyTemplate} header="Time" sortable></Column>
                        <Column field="SourceT" header="Source" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} filter ></Column>
                        <Column field="AgentT" header="Agent"  filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} filter ></Column>
                        <Column field="category" header="Category" dataType="numeric" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={categoryBodyTemplate} filter filterElement={categoryRowFilterTemplate}></Column>
                        <Column field="message" header="Message" body={messageBodyTemplate}></Column>
                        <Column field="edit" body={editAlerts} style={{width:'3em'}} />
                    </DataTable>
                </div>
            </div>
        </>
    
    )
}

export default Alerts;