import React, { useState, useEffect, useRef } from 'react';


import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { DbSrtServerConfig, DbSrtServerConfigFile } from '../db/srtserver';

import EditConfig from './editconfig';

async function putData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },

      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    return response.json(); // parses JSON response into native JavaScript objects
}

const Config = ({ip}) => {
    const toast = useRef(null);

    const [configurationFile, setConfigurationFile] = useState('');
    const [configuration, setConfiguration] = useState(null);
    const [configEdit, setConfigEdit] = useState(3);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {

        const fetchData = async (ip) => {
            const _config = await DbSrtServerConfig(ip);
            const _configFile = await DbSrtServerConfigFile(ip);

            setConfigurationFile(_configFile);
            setConfiguration(_config);  
            
        }
        

        fetchData(ip).catch((err) => console.error(err));

        
    },[ip, refreshKey]); //Run each time id changes.

    const applyConfig = () => {
        putData(`${ip}/api/v1/srtserver/applyConfig`).then((data)=>{
            if(data.code === 0) {
                showSuccess(data.message);
                setConfigStatus(4);
            }
            else if(data.code > 0){
                showWarn(data.message);
            }
            else {
                showError(data.message);
            }

            setRefreshKey(oldKey => oldKey +1);
        });
    }

    const makeConfigDefault = () => {
        putData(`${ip}/api/v1/srtserver/defaultConfig`).then((data)=>{
            if(data.error === 0) {
                showSuccess(data.message);
            }
            else if(data.code > 0){
                showWarn(data.message);
            }
            else {
                showError(data.message);
            }

            setRefreshKey(oldKey => oldKey +1);
        });
    }

    const showSuccess = (message) => {
        toast.current.show({severity:'success', summary:'Success Message', detail:message, life: 3000});
    }

    const showWarn = (message) => {
        toast.current.show({severity:'warn', summary: 'Warn Message', detail:message, life: 3000}); 
    }

    const showError = (message) => {
        toast.current.show({severity:'error', summary: 'Error Message', detail:message, life: 3000});
    }

    const setConfigStatus = (value) => {
        setConfigEdit(value);
    }


    return(
        <div className="surface-card p-4 border-round">
            <Toast ref={toast} />
            {(configEdit === 2)?
                <EditConfig ip={ip} config={configuration} close={setConfigStatus} />
            :
            <>
                <div className="flex">
                    <div className="flex col-2">
                        <Button icon="pi pi-pencil" className="p-button" onClick={()=>setConfigStatus(2)} label="Edit" /> 
                    </div>
                    <div className="flex col-2">
                        <Button icon="pi pi-check" className="p-button bg-green-500" onClick={()=>applyConfig()} label="Apply" disabled={configEdit !== 1}/> 
                    </div>
                    <div className="flex col-2">
                        <Button icon="pi pi-save" className="p-button bg-orange-500" onClick={()=>makeConfigDefault()} label="Save" disabled={configEdit !== 4}/>
                    </div>
                </div>
                <div className='p-2 text-xl text-600'>{`Active Configuration ${configEdit === 1?'- Unsaved changes':''}`}</div>
                <div className={`${(configEdit === 1)? 'p-2 border-dashed border-red-500 border-round-md':'p-2 border-dashed border-blue-500 border-round-md'}`}>
                    <pre>
                    {configurationFile}
                    </pre>
                </div>
            </>
            }
        </div>

    )
}

export default Config;