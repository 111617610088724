import React, { useEffect, useState, useRef, useContext } from 'react';

import Chart from 'react-apexcharts';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

import { Dropdown } from 'primereact/dropdown';
import { Slider } from 'primereact/slider';

import { getLocalTimeTicks } from '../utils/misc';
import { plotRttOptions } from './chartoptions';
import { DatacastAPIGetServerDrops,  DatacastAPIGetStreamStatsOverview, DatacastAPIGetServersAvailability, DatacastAPIGetStreamStats} from '../db/datacast';
import { DbStreamsMap } from '../db/streams';
import { DbAgentsMap } from '../db/agents';
import { DatacastContext } from '../context/datacastcloud';

const ServerAnalysis = () => {
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);
  const [serverDrops, setServerDrops] = useState([]);
  
  const [selectedDrop, setSelectedDrop] = useState(null);
  const [plotData, setPlotData] = useState([]);
  const [streamDrops, setStreamDrops] = useState([]);
  const [streamSelection, setStreamSelection] = useState([]);
  
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  
  const [ datacastProperties ] = useContext(DatacastContext);
  const op = useRef(null);

  const statOptions = [
    {   label:'Inst Rcv Bitrate', value:'RcvBitrate' },
    {   label:'Inst Snd Bitrate', value:'SndBitrate' },      
    {   label:'Inst Rcv Packets', value:'RcvPackets' },     
    {   label:'Inst Snd Packets', value:'SndPackets' },      
    {   label:'Inst Rcv Lost', value:'RcvLost' },         
    {   label:'Inst Snd Lost', value:'SndLost' },         
    {   label:'Inst Dropped', value:'Dropped' },         
    {   label:'Inst Retry', value:'Retry' },  
    {   label:'Rtt', value:'Rtt' }
  ];
  const [selectedStat, setSelectedStat] = useState(statOptions[statOptions.length - 1].value);


  const timeIntervalOptions = [
    {label: '10 MIN', value:5},
    {label: '20 MIN', value:10},
    {label: '30 MIN', value:15},
    {label: '1 HR', value:30}
  ];

  const [selectedIntervalOption, setSelectedIntervalOption] = useState(timeIntervalOptions[0].value);
  const [sliderProperties, setSliderProperties] = useState({step:1, max:Math.floor(24*60 / timeIntervalOptions[0].value)});
  const [sliderValue, setSliderValue] = useState(Math.floor(24*60 / timeIntervalOptions[0].value) - 1);

  useEffect(() => {
    setCurrentDate(new Date());

    DatacastAPIGetServersAvailability(datacastProperties.ip).then((result) => {
      
      if(result && result.length > 0) {

        setServers(result);
        onSelectedServerChange(result[0].Uuid);
      }

    }).catch((error) => {
        console.error(error);
    });

  },[]); //Run each time id changes.

  const SetStreams = async (server, time, interval, stat) => {
    const streamsMap = await DbStreamsMap(datacastProperties.userIp, 'SOURCE');

    const agentsMap = await DbAgentsMap(datacastProperties.userIp);
    
    const _streamDrops = await DatacastAPIGetStreamStatsOverview(datacastProperties.ip, server, time, interval, stat);
    for(let _stream of _streamDrops) {
      _stream.StreamName = streamsMap[_stream.StreamUuid] ? streamsMap[_stream.StreamUuid].name : _stream.StreamUuid;
      _stream.AgentName = agentsMap[_stream.Agent]?agentsMap[_stream.Agent].name : _stream.Agent;
    }

    setStreamDrops(_streamDrops);
  }

  const getServerInfo = async(server) =>{
    
    const _serverDrops = await DatacastAPIGetServerDrops(datacastProperties.ip, server );
    setServerDrops(_serverDrops);

    await SetStreams(server, selectedTime, selectedIntervalOption.value, selectedStat);
    
  }
    
  //// Server
  const onSelectedServerChange = (value) => {

    setSelectedServer(value);
    getServerInfo(value).then((result) => {
      
    }).catch((error) => {
        console.error(error);
    });

  }

  const onSelectedTimeChange = (value, Uuid) => {
    
    setSelectedDrop(value);
    if(value && value.StatDate2) {
      const newDate = new Date(`${value.StatDate2} Z`);
      setSelectedTime(newDate);
      
      setSliderValue((newDate.getTime() - (currentDate.getTime() - 24*3600*1000))/(selectedIntervalOption*60000));
    

      if(Uuid) {
        SetStreams(Uuid, newDate, selectedIntervalOption, selectedStat).catch((error) => {
            console.error(error);
        });
      }
      else {
        
        SetStreams(selectedServer, newDate, selectedIntervalOption, selectedStat).catch((error) => {
            console.error(error);
        });
      }
    }
  }

  const onIntervalChange = (value) => {

    setSelectedIntervalOption(value);

    let ratio = null;

    if (value > selectedIntervalOption) {
      ratio = value / selectedIntervalOption;
    }
    else {
      ratio = selectedIntervalOption / value;
    }

    const newValue = (value > selectedIntervalOption) ? Math.floor(sliderValue / ratio): Math.floor(sliderValue * ratio);

    //Convert slider value
    setSliderProperties({step:1, max:Math.floor(24*60 / value)});
    setSliderValue(newValue);

    updateData(null, value);
  }

  const updateData = (inTime, interval) => {

    const _selectedTime = (inTime)?inTime:selectedTime;

    const serviceIds = streamSelection.map((current)=>{
      return(current.StreamID);
    })

    const _interval = (interval)?interval:selectedIntervalOption;

    if (selectedServer && _selectedTime && serviceIds && _interval) {
      
      DatacastAPIGetStreamStats(datacastProperties.ip, selectedServer, _selectedTime, serviceIds, _interval, selectedStat).then((result) => {
        setPlotData(result);
      }).catch((error) => {
          console.error(error);
      });

    }
    
  }

    const onSliderEnd = (value) => {
      
      const _selectedTime = (currentDate.getTime() - 24*3600*1000) + value*selectedIntervalOption*60*1000;
      updateData(new Date(_selectedTime));
    }

    const onSliderChange = (value) => {
      
      const _selectedTime = (currentDate.getTime() - 24*3600*1000) + value*selectedIntervalOption*60*1000;
      setSliderValue(value);

      setSelectedTime(new Date(_selectedTime));
    }

    const dropTemplate = (rowData) => {
      
      return (getLocalTimeTicks(new Date(`${rowData.StatDate2} Z`)));
    }

    const onStatChange = (value) => {
      setSelectedStat(value);
    }

    const moveSlide = (step) => {
      const newValue = sliderValue + step;

      setSliderValue(newValue);

      const newDateTime = (currentDate.getTime() - 24*3600*1000) + newValue * (selectedIntervalOption*60000);
      const newDate = new Date(newDateTime);

      setSelectedTime(newDate);
      updateData(newDate, null);
    }

    return(
        <div className='w-full'>
          <div className='flex align-items-center' style={{backgroundColor:"rgb(64,64,64)"}}>
            <div className='p-3 text-white font-bold'>SERVERS</div>
            <Dropdown className="border-round-md" optionLabel="Name" optionValue="Uuid" value={selectedServer} options={servers} onChange={(e)=>onSelectedServerChange(e.value)} placeholder="Select a Server"/>
          </div>
          <div className='flex align-items-center m-2 justify-content-between'>
            <div className='flex align-items-center'>
              <div className='text-xl font-medium'>Analysis</div>
              <Button icon="pi pi-chart-line" label="Plot" className="mx-2 p-button-rounded"  onClick={(e)=>updateData()} />
            </div>
            <div>
              {getLocalTimeTicks(selectedTime)}
            </div>
            <div>
              <Dropdown className='mr-2' options={statOptions} optionLabel="label" optionValue="value" value={selectedStat} onChange={(e)=>onStatChange(e.value)} />
              <Dropdown options={timeIntervalOptions} optionLabel="label" optionValue="value" value={selectedIntervalOption} onChange={(e)=>onIntervalChange(e.value)} />
            </div>
          </div>

          <div className='w-full'>
              <Chart options={plotRttOptions} series={plotData} type="line" height={300} width='100%' />
          </div>

          <div className='flex align-content-center  w-full border-dashed border-1 surface-border surface-100 border-round-md'>
            <div className='flex w-2 m-3 align-items-center'>
              <Button icon="pi pi-table" style={{backgroundColor:"rgb(64,64,64)"}}  label="Select Drop" onClick={(e) => op.current.toggle(e)} />
              <OverlayPanel ref={op} id="overlay_panel" showCloseIcon >
                <DataTable value={serverDrops} rows={10} paginator selection={selectedDrop} onSelectionChange={(e)=>onSelectedTimeChange(e.value)} >
                  <Column selectionMode="single" headerStyle={{width:'3em'}} />
                  <Column field="StatDate2" header="Time" body={dropTemplate} sortable />
                  <Column field="Dropped" header="Dropped Packets"  sortable />
                </DataTable>

              </OverlayPanel>
            </div>
            <div className='flex w-10 align-items-center m-3'>
              <div className='flex align-items-center'>
                {getLocalTimeTicks(new Date(currentDate.getTime() - 24*3600*1000))}
                <Button  className='p-button-text' icon="pi pi-chevron-left" onClick={()=>moveSlide(-1)}/>
              </div>
              <Slider className='w-full' {...sliderProperties} value={sliderValue} onChange={(e)=>onSliderChange(e.value)} onSlideEnd={(e)=>onSliderEnd(e.value)} />
              <div className='flex align-items-center'>
                <Button className='p-button-text' icon="pi pi-chevron-right" onClick={()=>moveSlide(1)} />
                {getLocalTimeTicks(currentDate)}
              </div>
            </div>
          </div>

          <div className='mt-3'>
            <DataTable value={streamDrops} selectionMode="multiple" selection={streamSelection} dataKey="StreamID" 
                       onSelectionChange={(e)=>setStreamSelection(e.value)} 
                       sortField="StandardDev" sortOrder={-1}
                       responsiveLayout="scroll" scrollable scrollHeight="300px"
                       >
              <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
              <Column field="StreamID" header="Stream ID"></Column>
              <Column field="Type" header="Type" sortable></Column>
              <Column field="StreamName" header="Source" sortable></Column>
              <Column field="AgentName" header="Agent" sortable></Column>
              <Column field="StandardDev" header="StdDev" sortable></Column>
              <Column field="Dropped" header="Drops" sortable></Column>
            </DataTable>
          </div>
        </div>
    )
}

export default ServerAnalysis;