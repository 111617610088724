import React, { useState, useContext, useEffect } from 'react';

import { useLocation, useNavigate } from "react-router-dom";

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Slider } from 'primereact/slider';
import { Panel } from 'primereact/panel';
import { Checkbox } from 'primereact/checkbox';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { ToggleButton } from 'primereact/togglebutton';

import { DbStreamSet } from '../db/streams';
import { GetSourceOptions, GetBackupOptions, GetServerOptions } from '../common/common';
import { DbAlert, DbAlertSet, DbCreateAlert } from '../db/alerts';
import { DatacastContext } from '../context/datacastcloud';
import { DbAgents } from '../db/agents';

const EditStream = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [stream, setStream] = useState(location.state?.stream);

    const agent = location.state?.agent;
    const prevUrl = location.state?.from;

    const typeOptions = (agent.type === 'MAP')?[
        {label:'SOURCE', value:'SOURCE', disabled:true},
        {label:'DESTINATION', value:'DESTINATION', disabled:false}]:
        [{label:'SOURCE', value:'SOURCE', disabled:false},
         {label:'DESTINATION', value:'DESTINATION', disabled:false}];

    const [disableInput, setDisableInput] = useState(true);
    const [ dirty, setDirty ] = useState(false);
    const [ serverOptions, setServerOptions ] = useState([]);
    const [ backupServerOptions, setBackupServerOptions ] = useState([]);
    const [ primaryOptions, setPrimaryOptions ] = useState([]);
    const [ backupOptions, setBackupOptions ] = useState([]);
    const [agentOptions, setAgentOptions] = useState([]);
    const [p2pAgent, setP2pAgent] = useState({label:'N/A', value:''});

    const [ sourceOptions, setSourceOptions ] = useState([]);
    const [ datacastProperties ] = useContext(DatacastContext);

    const [override, setOverride] = useState(false);
    const modes = ['CLOUD', 'P2P'];
    const [mode, setMode] = useState(modes[0]);

    const srtModes = ['RENDEZVOUS', 'LISTENER', 'CALLER'];
    const [srtMode, setSrtMode] = useState(srtModes[0]);

    const nameMap ={
        0:'Connected',
        1:'Disconnected',
        2:'Availability',
        3:'Data Stopped',
        4:'Data Started'
    }

   const subscriptionTemplate = [{name: nameMap[0], type: 0, enabled:0}, {name: nameMap[1], type: 1, enabled:0}, {name: nameMap[2], type: 2, enabled:0}, {name: nameMap[3], type: 3, enabled:0}, {name: nameMap[4], type: 4, enabled:0}]

    const [createAlerts, setCreateAlerts] = useState(false);
    const [alerts, setAlerts] = useState([]);

    const onAlertChange = (e) => {

        let _alerts = [...alerts];

        const alertIndex = _alerts.findIndex(element=> element.type === e.value.type)
        if(alertIndex !== -1 ) {
            _alerts[alertIndex].enabled = (e.checked)?1:0;
        }

        setDirty(true);


        setAlerts(_alerts);
    }

    



    useEffect(() => {

        const Initialize = async () => {

            const _sourceOptions = await GetSourceOptions(datacastProperties);
            setSourceOptions(_sourceOptions);

            const sourceOption = _sourceOptions.find(element=>element.value === stream.uuid);

            //Get agents for direct connections.
            const _agents = await DbAgents(datacastProperties.userIp);
            const _agentOptions = _agents.filter((e)=>(e.uuid !== agent.uuid && e.externalIp.length > 0)).map((a)=>{
                return {
                    label:a.name,
                    uuid:a.uuid,
                    externalIp: a.externalIp,
                    port:(sourceOption && sourceOption.server)?sourceOption.server.port : stream.port
                }
            });

            if(stream.primaryStreamId) {
                const found = stream.primaryStreamId.match(/mode=(listener|rendezvous|caller)/i);
                if (found) {
                    setSrtMode(found[1].toUpperCase());
                }
                
            }

            setAgentOptions([{label:'N/A', uuid:''}, ..._agentOptions]);

            //Get alert configuration for stream.
            const result = await DbAlert(datacastProperties.userIp,datacastProperties.id, stream.id);
            if(result.error === 0) {
                const _alerts = result.data;
                
                if(_alerts && _alerts.length > 0) {
                    let template = subscriptionTemplate;

                    for( let idx in template) {
                        let item = template[idx];
                        let found = _alerts.find((e)=>e.type === item.type);
                        if(found) {
                            template[idx] = {...found};
                        }
                    }

                    setAlerts(template);
                }
                else {
                    setCreateAlerts(true);
                    setAlerts(subscriptionTemplate);
                }
            }

            //Get servers
            const _serverOptions = await GetServerOptions(datacastProperties);

            //Set the backup server
            if(stream.source.length > 0) {
                setStream({...stream, backupServer:stream.source});
            }

            //If the source field, which should be named backupSource has a value then it means
            //It is not a server.
            const _primaryOptions = _serverOptions.filter((e)=>(stream.source.length > 0 || e.value !== stream.backupServer));

            setPrimaryOptions([{label:'N/A', value:''}, ..._primaryOptions]);


            const _backupOptions2 = await GetBackupOptions(datacastProperties, stream.uuid);
            setBackupServerOptions(_backupOptions2);

            
            const _backupOptions = ((stream.type === 'DESTINATION')?_backupOptions2:_serverOptions).filter((e)=>e.value !== stream.primaryServer);
            setBackupOptions([{label:'N/A', value:''}, ..._backupOptions]);
            

            setServerOptions(_serverOptions);

            const foundPrimary = _serverOptions.find((e)=>(e.ipAddr === stream.ipAddr && e.port === stream.port));

            if(!foundPrimary) {
                //The agent uuid is stored in the backupStreamId. This will need to be moved eventually.
                const foundP2p = _agentOptions.find((e)=>e.uuid === stream.backupStreamId);
                if(foundP2p) {
                    setP2pAgent(foundP2p.uuid);
                    setMode('P2P');
                    setOverride(false);
                }
                else {
                    setOverride(true);
                }
            }
            else {
                setOverride(false);
            }

            if(stream.type === 'SOURCE') {
                setDisableInput(datacastProperties.agentGroup.editSource === 0);
            }
            else {
                setDisableInput(datacastProperties.agentGroup.editStream === 0);
            }

            return true;
        }

        
        Initialize(datacastProperties).catch((error) => {
            console.error(error);
        });

    },[agent, datacastProperties]); 

    const userInput = (field, value) => {

        if(field === 'primaryServer') {
            const _backupOptions = backupServerOptions.filter((e)=>e.value !== value);
            setBackupOptions([{label:'N/A', value:''}, ..._backupOptions]);   
        }
        else if (field === 'backupServer') {
            
            const _primaryOptions = serverOptions.filter((e)=>e.value !== value);

            setPrimaryOptions(_primaryOptions);
        }


        let newValue = '';
        if ((field === 'primaryStreamId' ) || (field === 'backupStreamId' )) {
            newValue = (typeof value === 'string')? value.substring(0,144) : value;
        }
        else if (field === 'disabled') {
            newValue = (value)?0:1;
        }
        else {
            newValue = (typeof value === 'string')? value.substring(0,36) : value;
        }



        setStream({...stream, [field]:newValue})
        setDirty(true);
    }


    const calculateAutoStreamValues = () => {
        const _stream = {...stream};
        const sourceOption = sourceOptions.find(element=>element.value === stream.uuid);

        if(mode === 'CLOUD') {
            const _primaryServer = serverOptions.find(element => element.value === stream.primaryServer);
            const _backupServer = (_stream.type === 'DESTINATION'?backupOptions : serverOptions ).find(element => element.value === stream.backupServer);

            if(_stream.type === 'DESTINATION') {
                
                if(sourceOption) {
                    _stream.name = sourceOption.label;
                    _stream.uuid = sourceOption.uuid;
                }

                if( _primaryServer ) {
                    _stream.primaryStreamId = `${_primaryServer.destination}/${agent.uuid}/${_stream.uuid}`;
                    _stream.ipAddr = _primaryServer.ipAddr;
                    _stream.port = _primaryServer.port;
                }

                if(_backupServer) {
                    _stream.backupStreamId = `${_backupServer.destination}/${agent.uuid}/${_backupServer.stream}`;
                    _stream.backupServer = _backupServer.server?_backupServer.server.value : stream.backupServer;
                    _stream.backupIpAddr = _backupServer.ipAddr;
                    _stream.backupPort = _backupServer.port;
                    _stream.source = _backupServer.server?_backupServer.stream:'';
                }

            }
            else {
                if(_primaryServer) {
                    _stream.primaryStreamId = `${_primaryServer.source}/${agent.uuid}/${stream.uuid}`;
                    _stream.ipAddr = _primaryServer.ipAddr;
                    _stream.port = _primaryServer.port;
                }

                if(_backupServer) {
                    _stream.backupStreamId = `${_backupServer.source}/${agent.uuid}/${stream.uuid}`;
                    _stream.backupIpAddr = _backupServer.ipAddr;
                    _stream.backupPort = _backupServer.port;
                }
            }

            _stream.source = _stream.source?_stream.source:'';
        }
        else {
            
            const _peerAgent = agentOptions.find(element => element.uuid === p2pAgent);
            if(_peerAgent) {
                if(_stream.type === 'DESTINATION') {
                    if(sourceOption) {
                        _stream.name = sourceOption.label;
                        _stream.uuid = sourceOption.uuid;
                        _stream.primaryStreamId = `direct.datacast.com/direct/${_peerAgent.uuid}/${_stream.uuid}&mode=${srtMode.toLocaleLowerCase()}`;
                        _stream.ipAddr = (srtMode !== 'LISTENER')?_peerAgent.externalIp:'';
                        _stream.port = parseInt(sourceOption.server.port);
                        _stream.backupStreamId = _peerAgent.uuid;
                        _stream.backupIpAddr = '';
                        _stream.backupPort = 0;
                        _stream.primaryServer = '';
                        _stream.backupServer = '';
                    }
                }
                else {
                    _stream.primaryStreamId = `direct.datacast.com/direct/${agent.uuid}/${_stream.uuid}&mode=${srtMode.toLocaleLowerCase()}`;
                    _stream.ipAddr = (srtMode !== 'LISTENER')?_peerAgent.externalIp:'';
                    _stream.backupStreamId = _peerAgent.uuid;
                    _stream.backupIpAddr = '';
                    _stream.backupPort = 0;
                    _stream.primaryServer ='';
                    _stream.backupServer = '';
                }
            }
        }

        return _stream;
    }

    const createStream = () => {

        let _stream = stream;
        if(!override) {
            _stream = calculateAutoStreamValues();
        }
        
        DbStreamSet(datacastProperties.userIp, _stream).then((result) => {
            if(result.error === 0) {

                if(createAlerts) {
                    const stream = result.data;
                    const _alert = {
                        userAlert:{
                            userId:datacastProperties.id,
                            streamId:stream.id
                        },
                        alerts:[ ...alerts ]
                    }
    
                    DbCreateAlert(datacastProperties.userIp, _alert).then((result) => {
    
                        setDirty(false);
                        
                        if(prevUrl === 'streams') {
                            navigate(`/editagent`, {state:{agent:{...agent}, from:'editstream'}});
                        }
                        else if (prevUrl === 'status') {
                            navigate('/status');
                        }
                        else {
                            navigate(`/agents`);
                        }
                        
            
                    }).catch((error) => {
                        console.error(error);
                    });
                }
                else {
                    if(alerts && alerts.length > 0)  {
                        DbAlertSet(datacastProperties.userIp, alerts).then((result) => {

                            setDirty(false);
                            if(prevUrl === 'streams') {
                                navigate(`/editagent`, {state:{agent:{...agent}, from:'editstream'}});
                            }
                            else if (prevUrl === 'status') {
                                navigate('/status');
                            }
                            else {
                                navigate(`/agents`);
                            }
                
                
                        }).catch((error) => {
                            console.error(error);
                        });
                    }
                }
                

            
                setDirty(false);

                if(prevUrl === 'streams') {
                    navigate(`/editagent`, {state:{agent:{...agent}, from:'editstream'}});
                }
                else if (prevUrl === 'status') {
                    navigate('/status');
                }
                else {
                    navigate(`/agents`);
                }
            }

        }).catch((error) => {
            console.error(error);
        });
    }

    const cancelStream = () => {
        
        if(prevUrl === 'streams') {
            navigate(`/editagent`, {state:{agent:{...agent}, from:'editstream'}});
        }
        else if (prevUrl === 'status') {
            navigate('/status');
        }
        else {
            navigate(`/agents`);
        }
    }

    const accept = (value) => {
        setOverride(value);
        if(value === false) {
            //Need to revert everything back.
            const _stream = calculateAutoStreamValues();
            setStream(_stream);
            setDirty(true);
        }
    }

    const onOverride = (event, value) => {
        confirmPopup({
            target: event.currentTarget,
            message: value?`Are you sure you want to override automatic values?`:`Are you sure you want to discard custom values`,
            icon: 'pi pi-exclamation-triangle',
            accept: ()=>accept(value)
        });
    }

    const goBack = () => {
        if(prevUrl === 'streams') {
            navigate(`/editagent`, {state:{agent:{...agent}, from:'editstream'}});
        }
        else if (prevUrl === 'status') {
            navigate('/status');
        }
        else {
            navigate(`/agents`);
        }
    }

    const onP2pAgent = (value) => {
        
        setDirty(true);
        setP2pAgent(value);
    }

    const onSetSrtMode = (value) => {
        setDirty(true);
        setSrtMode(value);
    }

    return (
        <>
            <ConfirmPopup />
            <div className="justify-content-between" style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='flex align-items-center'>
                    <Button className='p-button-text p-button-lg' icon="pi pi-arrow-left" onClick={()=>goBack()}></Button>
                    <div className='p-3 text-white font-bold'>Edit Stream</div>
                </div>
            </div>  
            <div className='border-solid surface-border border-1'>
                <div className='flex justify-content-end'>
                    { 
                        (datacastProperties.admin )?
                        <ToggleButton className={override?'border-green-400 border-2':'border-red-400 border-2'} onLabel="Auto" offLabel="Override" checked={override} onChange={(e) => onOverride(e, e.value)} />
                        :<></>
                    }
                </div>
                <div className='p-6'>    
                    <div className='grid'>
                        {
                            (!override) ?
                            <div className='flex col-12 flex-wrap'>
                                <div className='col-6'>
                                    <div className='font-bold mb-1'>Type</div>
                                    <div className='flex'>
                                        <Dropdown className='surface-100 w-full border-round-md border-none'  value={stream.type} options={typeOptions} onChange={(e) => userInput('type',e.target.value)} disabled={1}/>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='font-bold mb-1'>{`Source ${datacastProperties.admin?`(${stream.uuid})`:''}`}</div>
                                    <div className='flex '>
                                        <InputText className={`${(stream.type === 'DESTINATION')?'hidden ':''} surface-100 w-full border-round-md border-none`}  keyfilter={/[\w\d-'"?!\s]{1,36}/i} value={stream.name}  onChange={(e) => userInput('name',e.target.value)} disabled={disableInput}/>
                                        <Dropdown className={`${(stream.type === 'DESTINATION')?'':'hidden '} surface-100 w-full border-round-md border-none`} optionLabel="label" optionValue="value" value={stream.uuid} options={sourceOptions} optionDisabled="disabled" onChange={(e) => userInput('uuid',e.value)} disabled={disableInput}/>
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className='font-bold mb-1'>Mode</div>
                                    <div className='flex w-full'>
                                        <Dropdown className="surface-100 w-full border-round-md border-none" value={mode} options={modes} onChange={(e) => setMode(e.value)} placeholder="Select  mode" />
                                    </div>
                                </div>
                                { (mode === 'CLOUD')?
                                    <>
                                        <div className='col-4'>
                                            <div className='font-bold mb-1'>Primary Server</div>
                                            <div className='flex w-full'>
                                                <Dropdown className='surface-100 w-full border-round-md border-none' optionLabel="label" optionValue="value" value={stream.primaryServer} options={primaryOptions} onChange={(e) => userInput('primaryServer',e.value)} disabled={disableInput}/>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='font-bold mb-1'>Backup</div>
                                            <div className='flex w-full'>
                                                <Dropdown className='surface-100 w-full border-round-md border-none' optionLabel="label" optionValue="value" value={stream.backupServer} options={backupOptions} onChange={(e) => userInput('backupServer',e.value)} disabled={disableInput}/>
                                            </div>
                                        </div>
                                    </>
                                :
                                <>
                                    <div className='col-4'>
                                        <div className='font-bold mb-1'>Mode</div>
                                        <div className='flex w-full'>
                                            <Dropdown className="surface-100 w-full border-round-md border-none" value={srtMode} options={srtModes} onChange={(e) => onSetSrtMode(e.value)} placeholder="Select SRT mode" />
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='font-bold mb-1'>Agent</div>
                                        <div className='flex w-full'>
                                            <Dropdown className='surface-100 w-full border-round-md border-none' optionLabel="label" optionValue="uuid" value={p2pAgent} options={agentOptions} onChange={(e) => onP2pAgent(e.value)} disabled={disableInput}/>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='font-bold mb-1'>Port</div>
                                        <div className='flex w-full'>
                                            <InputNumber className='w-full' min={65500} max={65535} inputClassName='surface-100 w-full border-round-md border-none' value={stream.port} onValueChange={(e) => userInput('port',e.value)}  mode="decimal" minFractionDigits={0} useGrouping={false} disabled={disableInput || stream.type === 'DESTINATION'}/>
                                        </div>
                                    </div>
                                </>
                                }
                            </div>:
                            <div className='col-12'>
                                <div className='flex flex-wrap'>
                                <div className='col-12'>
                                    <div className='font-bold mb-1'>{`Stream Name ${datacastProperties.admin?`(${stream.uuid})`:""}`}</div>
                                    <div className='flex w-full'>
                                        <InputText className={`surface-100 w-full border-round-md border-none`}  keyfilter="alphanum" value={stream.name}  onChange={(e) => userInput('name',e.target.value)} disabled={disableInput}/>
                                    </div>
                                </div>
                                <div className='col-3' >
                                    <div className='font-bold mb-1'>{`Primary IP`}</div>
                                    <div className='flex w-full'>
                                        <InputText className='surface-100 w-full border-round-md border-none' 
                                            value={stream.ipAddr}  onChange={(e) => userInput('ipAddr',e.target.value)}
                                            keyfilter={/(?:[0-9.])/}
                                            disabled={disableInput}
                                         />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className='font-bold mb-1'>{`Port`}</div>
                                    <div className='flex w-full'>
                                        <InputNumber className='w-full' min={1024} max={65499} inputClassName='surface-100 w-full border-round-md border-none' value={stream.port} onValueChange={(e) => userInput('port',e.value)}  mode="decimal" minFractionDigits={0} useGrouping={false} disabled={disableInput}/>
                                    </div>
                                </div>
                                <div className='col-3' >
                                    <div className='font-bold mb-1'>{`Backup IP`}</div>
                                    <div className='flex w-full'>
                                        <InputText className='surface-100 w-full border-round-md border-none' 
                                            value={stream.backupIpAddr}  onChange={(e) => userInput('backupIpAddr',e.target.value)}
                                            keyfilter={/(?:[0-9.])/}
                                            disabled={disableInput}
                                         />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className='font-bold mb-1'>{`PORT`}</div>
                                    <div className='flex w-full'>
                                        <InputNumber className='w-full' min={1024} max={65499} inputClassName='surface-100 w-full border-round-md border-none' value={stream.backupPort} onValueChange={(e) => userInput('backupPort',e.value)}  mode="decimal" minFractionDigits={0} useGrouping={false} disabled={disableInput}/>
                                    </div>
                                </div>
                                <div className='col-6' >
                                    <div className='font-bold mb-1'>{`Primary Stream ID`}</div>
                                    <div className='flex w-full'>
                                        <InputText className='surface-100 w-full border-round-md border-none' 
                                            value={stream.primaryStreamId}  onChange={(e) => userInput('primaryStreamId',e.target.value)}
                                            keyfilter={/(?:[\w/?.])/i}
                                            disabled={disableInput}
                                         />
                                    </div>
                                </div>
                                <div className='col-6' >
                                    <div className='font-bold mb-1'>{`Backup Stream ID`}</div>
                                    <div className='flex w-full'>
                                        <InputText className='surface-100 w-full border-round-md border-none' 
                                            value={stream.backupStreamId}  onChange={(e) => userInput('backupStreamId',e.target.value)}
                                            keyfilter={/(?:[\w/])/i}
                                            disabled={disableInput}
                                         />
                                    </div>
                                </div>
                            </div>
                            </div>
                        }
                        {
                            (agent.type === 'GATEWAY')?
                                <div className='flex col-12 flex-wrap'>
                                <div className='col-6' >
                                    <div className='font-bold mb-1'>{`${(stream.type === 'DESTINATION')?'OUTPUT':'INPUT'} IP ADDRESS`}</div>
                                    <div className='flex w-full'>
                                        <InputText className='surface-100 w-full border-round-md border-none' 
                                            value={stream.agentIpAddr}  onChange={(e) => userInput('agentIpAddr',e.target.value)}
                                            keyfilter={/(?:[0-9.])/}
                                            disabled={disableInput}
                                         />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='font-bold mb-1'>{`${(stream.type === 'DESTINATION')?'OUTPUT':'INPUT'} PORT`}</div>
                                    <div className='flex w-full'>
                                        <InputNumber className='w-full' min={1024} max={65535} inputClassName='surface-100 w-full border-round-md border-none' value={stream.agentPort} onValueChange={(e) => userInput('agentPort',e.value)}  mode="decimal" minFractionDigits={0} useGrouping={false} disabled={disableInput}/>
                                    </div>
                                </div>
                                </div>
                            :
                            ''
                        }
                        <div className='col-6'>
                            <div className='font-bold mb-4'>Latency ({stream.latency} ms)</div>
                            <div className='flex w-full'>
                                <Slider className='surface-100 w-full border-round-md border-none' value={stream.latency} onChange={(e) => userInput('latency',e.value)}  min="10" max="10000" disabled={disableInput}/>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='font-bold mb-4'>Enable</div>
                            <div className='flex w-full align-items-center'>
                                <Checkbox name="enable" value={stream.disabled} onChange={(e) => userInput('disabled', e.value)} checked={stream.disabled === 0}  disabled={disableInput}/>
                            </div>
                        </div>
                        <div className='col-12 mt-3'>
                            <Panel header="Subscribe To Stream Alerts">
                            {
                                alerts.map((alert) => {
                                    return (
                                        <div key={alert.type} className="field-checkbox ml-2">
                                            <Checkbox inputId={alert.type} name="alert" value={alert} onChange={onAlertChange} checked={alert.enabled === 1}  disabled={disableInput}/>
                                            <label htmlFor={alert.type}>{nameMap[alert.type]}</label>
                                        </div>
                                    )
                                })
                            }
                            </Panel>
                        </div>
                        <div className='col-12'>
                            {
                                (dirty)?
                                    <div className='flex justify-content-end mt-4' >
                                        <div>
                                            <Button label="Cancel" className="p-button-danger" icon="pi pi-times" onClick={cancelStream} />
                                        </div>
                                        <div className='pl-2'>
                                            <Button label="Update" className="p-button-primary" icon="pi pi-check" onClick={createStream} />
                                        </div>
                                    </div>
                                :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>            
    
    )
}

export default EditStream;