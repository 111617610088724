import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, useFormState } from 'react-hook-form';


import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';

import { DbSrtServerConfigSet } from '../db/srtserver';
import { default_app } from './app';
import './configuration.css';

const default_config ={
    worker_threads:1,//1;
    worker_connections:300, //300;
    log_file:'logs/error.log', //logs/error.log;
    log_level:'info',
    stat_post_url:`http://127.0.0.1:4000/api/v1/srtserver/stat`, //stat_post_url http://192.168.31.106:8001/sls/stat;
    stat_post_interval:5,//5 #s
    record_hls_path_prefix:'/tmp/mov/sls',
    vod:'/tmp/mov/sls/$listen/$domain_publisher/$app_publisher/$stream_name/vod.m3u8', //'file name: /tmp/mov/sls/$listen/$domain_publisher/$app_publisher/$stream_name/vod.m3u8'
    server: {
        listen:8080,
        latency:125,
        max_payload_size: 1328,
        domain_player:'live.datacast.com', //Support multiple
        domain_publisher:'uplive.datacast.com',
        backlog:100,
        idle_streams_timeout:10, //#s -1: unlimited
        on_event_url:'http://127.0.0.1/api/v1/srtserver/event',//; #?method=on_connect|on_close&role_name=&srt_url=%s
        app:{
            app_player:'live', //live
            app_publisher: 'live', 
            record_hls:'off', //#on, off 
            record_hls_segment_duration:10, // #unit s
            relayEnable:false,
            relay:{
                type:'push', //push (Send all of your data to another instance), pull (pull all app data from another instance)
                mode:'all', //push(all; hash), pull(loop, hash)
                reconnect_interval:10,
                idle_streams_timeout:10, //#s -1: unlimited
                upstreams:'192.168.31.106:8080?streamid=uplive.sls.com/live'    //push(192.168.31.106:8080?streamid=uplive.sls.com/live), 
                                                                    //pull(127.0.0.1:9090?streamid=live.sls.com/live 192.168.1.100:8080/?streamid=live.test.com/live)
            }
        }
    }
}

const EditConfig = ({ip, config, close}) => {

    const [enableRelay, setEnableRelay] = useState(false);
    const [configuration, setConfiguration] = useState(config);
    const [apps, setApps] = useState([]);
    const [appChange, setAppChange] = useState(false);
    const [configEdit, setConfigEdit] = useState(false);
    const { control, handleSubmit, setValue } = useForm({defaultValues:config});

    const { isDirty } = useFormState({control}); 

    const navigate = useNavigate();


    useEffect(() => {
        const setObjectFormValues = (object, chain) => {

            Object.keys(object).forEach(function(prop) {
                if(prop === 'relayEnable') {
                    setEnableRelay(object[prop].relayEnable);
                }
    
                //null is of type object.
                if(typeof object[prop] !== 'object' || object[prop] === null){

                    object[prop] = (object[prop] !== null)?object[prop] : '';
                    
                    if(chain === null){
                        setValue(`${prop}`, object[prop]);
                        
                    }
                    else {
                        setValue(`${chain}.${prop}`, object[prop]);
                    }
                    
                }
                else {
                    if(chain === null) {
                        return setObjectFormValues(object[prop], `${prop}`);
                    }
                    else {
                        return setObjectFormValues(object[prop], `${chain}.${prop}`);
                    }
                }
            })
        }

        setObjectFormValues(config, null);

        const _apps = [];
            for(let app of config.server.apps) {

                _apps.push({
                    app_player: app.app_player,
                    app_publisher: app.app_publisher,
                    record_hls: app.record_hls,
                    record_hls_segment_duration: app.record_hls_segment_duration,
                    relayEnable: app.relayEnable,
                    type:app.relay.type,
                    mode:app.relay.mode,
                    reconnect_interval:app.relay.reconnect_interval,
                    idle_streams_timeout:app.relay.idle_streams_timeout,
                    upstreams:app.relay.upstreams
                });
            }

        setApps(_apps);
        setConfiguration(config);
    }, [config]);


    const onSubmit = (data) => {
        
        const _apps = [];
        apps.forEach((app) => {
            _apps.push({
                app_player: app.app_player,
                app_publisher: app.app_player,
                record_hls: app.record_hls,
                record_hls_segment_duration: app.record_hls_segment_duration,
                relayEnable: app.relayEnable,
                relay: {
                    type:app.type,
                    mode:app.mode,
                    reconnect_interval:app.reconnect_interval,
                    idle_streams_timeout:app.idle_streams_timeout,
                    upstreams:app.upstreams
                }
            })
        })
        
        data.server.apps = _apps;
        
        DbSrtServerConfigSet(ip, data).then((result)=>{
            /*if(data.code === 0) {
                showSuccess(data.message);
              }
              else if(data.code > 0){
                showWarn(data.message);
              }
              else {
                showError(data.message);
              }*/
              close(1);

        }).catch((err)=>console.error(err));
    };


    const addApp = (event) => {
        const _apps = [...apps];
        _apps.push(default_app);

        setAppChange(true);

        setApps(_apps);
        event.preventDefault();
    }

    const numberEditor = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} />
    }

    const recordEditor = (options) => {

        const appData = options.rowData;

        let optionValues = undefined;
        if(options.field === 'record_hls') {
            optionValues = ['on', 'off'];
        }
        else if(options.field === 'type' ) {
            optionValues = ['pull', 'push'];
        }
        else {
            if(appData.type === 'pull') {
                optionValues = ['loop','hash'];
            }
            else {
                optionValues = ['all', 'hash'];
            }
        }

        return (
            <Dropdown value={options.value} options={optionValues}
                onChange={(e) => options.editorCallback(e.value)} placeholder="Select a Status" />
        );
    }

    const checkEditor = (options) => {
        return <Checkbox onChange={(e) => options.editorCallback(e.target.checked)} checked={options.value}></Checkbox>;
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const relayEnableTemplate = (rowData) => {
        return (
            <Checkbox checked={rowData.relayEnable}></Checkbox>
        );
    }

    const onRowEditComplete = (e) => {

        setAppChange(true);
        let _apps = [...apps];
        let { newData, index } = e;

        _apps[index] = newData;

        setApps(_apps);
    }

    const headerTemplate = (options, name) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
                <div className={className}>
                    <div>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            {name}
                        </span>  
                    </div>
                    <div className='align-content-center'>
                        <Button className="p-button-text p-button-rounded p-button-danger" icon="pi pi-times" onClick={()=>close(0)}/>
                        <Button className="p-button-text p-button-rounded p-button-success" type="submit" icon="pi pi-check" disabled={!(isDirty || appChange)}/>
                    </div>
                </div>
        )
    }

    return(
        <div className="srt-config">
            <div>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid"> 
                <Panel headerTemplate={(options)=>headerTemplate(options, 'SYSTEM')} toggleable collapsed={true}>
                    <div className='flex flex-wrap'>
                        <div className="p-input-group">
                            <div className="p-text-label">Worker Threads</div>
                            <div className="p-input-label">
                                <Controller name="worker_threads" control={control} render={({ field }) => (   
                                    <InputNumber id={field.worker_threads} tooltip="Should match the number of CPUs" min="1" value={field.value} onChange={(e) => field.onChange(e.value)} />
                                )} />
                            </div>
                        </div>
                        <div className='p-input-group'>
                            <div className="p-text-label">Worker Connections</div>
                            <div className="p-input-label">
                                <Controller name="worker_connections" control={control} render={({ field }) => (   
                                    <InputNumber id={field.worker_connections} min="2" value={field.value} onChange={(e) => field.onChange(e.value)} />
                                )} />
                            </div>
                        </div>
                    
                
                        <div className="p-input-group">
                            <div className="p-text-label">Log Level</div>
                            <div className="p-input-label">
                                <Controller name="log_level" control={control} render={({ field }) => (   
                                    <Dropdown id={field.log_level} value={field.value} onChange={(e) => {field.onChange(e.value)}} options={['trace', 'debug', 'info', 'warning', 'error', 'fatal']} />
                                )} />
                            </div>
                        </div>

                        <div className="p-input-group">
                            <div className="p-text-label">Log File</div>
                            <div className="p-input-label">
                                <Controller name="log_file" control={control} render={({ field }) => (   
                                    <InputText {...field} />
                                )} />
                            </div>
                        </div>    

                        <div className="p-input-group">
                            <div className="p-text-label">Statistics Interval</div>
                            <div className="p-input-label">
                                <Controller name="stat_post_interval" control={control} render={({ field }) => (   
                                    <InputNumber id={field.stat_post_interval} value={field.value} onChange={(e) => field.onChange(e.value)} disabled={true} />
                                )} />
                            </div>
                        </div>
                        <div className="p-input-group">
                            <div className="p-text-label">Statistics URL</div>
                            <div className="p-input-label">
                                <Controller name="stat_post_url" control={control} render={({ field }) => (   
                                    <InputText {...field} disabled={true} />
                                )} />
                            </div>
                        </div> 

                        <div className="p-input-group">
                            <div className="p-text-label">HLS Prefix</div>
                            <div className="p-input-label">
                                <Controller name="record_hls_path_prefix" control={control} render={({ field }) => (   
                                    <InputText {...field} />
                                )} />
                            </div>
                        </div>
                        <div className="p-input-group">
                            <div className="p-text-label">VOD</div>
                            <div className="p-input-label">
                                <Controller name="vod" control={control} render={({ field }) => (   
                                    <InputText {...field} />
                                )} />
                            </div>
                        </div>    
                </div>
                </Panel>
                <Panel className="mt-4" headerTemplate={(options)=>headerTemplate(options, 'SERVER')} toggleable collapsed={true}>
                
                <div className="flex flex-wrap">
                    <div className="p-input-group">
                        <div className="p-text-label">Listener Port</div>
                        <div className="p-input-label">
                            <Controller name="server.listen" control={control} render={({ field }) => (   
                                <InputNumber  min="1024" value={field.value} onChange={(e) => field.onChange(e.value)} />
                            )} />
                        </div>
                    </div> 
                    <div className="p-input-group">
                        <div className="p-text-label ">SRT Latency</div>
                        <div className="p-input-label">
                            <Controller name="server.latency" control={control} render={({ field }) => (   
                                <InputNumber min="20" value={field.value} onChange={(e) => field.onChange(e.value)} />
                            )} />
                        </div>
                    </div>   
                    <div className="p-input-group">
                        <div className="p-text-label ">Max Payload Size</div>
                        <div className="p-input-label">
                            <Controller name="server.max_payload_size" control={control} render={({ field }) => (   
                                <InputNumber value={field.value} onChange={(e) => field.onChange(e.value)} />
                            )} />
                        </div>
                    </div>     
                    <div className="p-input-group">
                        <div className="p-text-label ">Publisher</div>
                        <div className="p-input-label">
                            <Controller name="server.domain_publisher" control={control} render={({ field }) => (   
                                <InputText {...field} />
                            )} />
                        </div>
                    </div>
                    <div className='p-input-group'>
                        <div className="p-text-label ">Player</div>
                        <div className="p-input-label">
                            <Controller name="server.domain_player" control={control} render={({ field }) => (   
                                <InputText {...field} />
                            )} />
                        </div>
                    </div>      

                    <div className="p-input-group">
                        <div className="p-text-label ">Backlog Connections</div>
                        <div className="p-input-label">
                            <Controller name="server.backlog" control={control} render={({ field }) => (   
                                <InputNumber value={field.value} onChange={(e) => field.onChange(e.value)} />
                            )} />
                        </div>
                    </div> 

                    <div className="p-input-group">
                        <div className="p-text-label ">Idle Streams Timeout</div>
                        <div className="p-input-label">
                            <Controller name="server.idle_streams_timeout" control={control} render={({ field }) => (   
                                <InputNumber value={field.value} onChange={(e) => field.onChange(e.value)} />
                            )} />
                        </div>
                    </div> 

                    <div className="p-input-group">
                        <div className="p-text-label ">Event URL</div>
                        <div className="p-input-label8">
                            <Controller name="server.on_event_url" control={control} render={({ field }) => (   
                                <InputText {...field} disabled={true} />
                            )} />
                        </div>
                    </div>   
                </div>
                </Panel>
                
                <Panel className="mt-4" headerTemplate={(options)=>headerTemplate(options, 'APPS')} toggleable collapsed={true}>
                    <DataTable value={apps} responsiveLayout="scroll" editMode="row" dataKey='app_player' onRowEditComplete={onRowEditComplete}>
                        <Column field="app_player" header="Server" editor={(options) => textEditor(options)} style={{ width: '10%' }}></Column>
                        <Column field="record_hls" header="Record"  editor={(options) => recordEditor(options)} style={{ width: '10%' }}></Column>
                        <Column field="record_hls_segment_duration" header="Segment Duration" editor={(options) => numberEditor(options)}></Column>
                        <Column field="relayEnable" header="Relay" body={relayEnableTemplate} editor={(options) => checkEditor(options)} style={{ width: '10%' }}></Column>
                        <Column field="type" header="Type" editor={(options) => recordEditor(options)} style={{ width: '10%' }}></Column>
                        <Column field="mode" header="Mode" editor={(options) => recordEditor(options)} style={{ width: '10%' }}></Column>
                        <Column field="reconnect_interval" header="Interval" editor={(options) => numberEditor(options)} style={{ width: '10%' }}></Column>
                        <Column field="idle_streams_timeout" header="Timeout" editor={(options) => numberEditor(options)} style={{ width: '10%' }}></Column>
                        <Column field="upstreams" header="Url" editor={(options) => textEditor(options)} style={{ width: '30%' }}></Column>
                        <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>
                    <div>
                        <Button icon="pi pi-plus" className="p-button-rounded p-button-success" aria-label="Add Row" onClick={(e)=>addApp(e)} />
                    </div>

                </Panel>

                </form>
            </div>
        </div>

    )
}

export default EditConfig;