import React, { useEffect, useState } from 'react';
import { formatUptime } from '../../utils/misc';

const SrtStats = ({stats, type}) => {

    const [statistics, setStatistics] = useState({});
    const [iType, setIType] = useState('dst');

    useEffect(() => {
        if(stats) {
            setStatistics(stats);
        }

        if(type) {
            setIType(type);
        }
    },[stats, type]); //Run each time id changes.

    return(
        <>
            <div className='flex-column h-full text-xs border-round-lg surface-600 p-2 text-white'>
                <div className='flex justify-content-center text-lg'>{`${(iType === 'dst') ? 'Received (DST': 'Sent (SRC'}) Statistics`}</div>
                <div className='flex justify-content-center'>
                    <div className='m-2'><div className='text-sm'>{'RTT (ms)'}</div><div>{statistics.msRTT}</div></div>
                    <div className='m-2'><div className='text-sm'>{'Up Time'}</div><div>{formatUptime(Math.round(statistics.msTimeStamp/1000))}</div></div>
                    <div className='m-2'><div>{'Bitrate (Mbps)'}</div><div>{`${(iType === 'dst') ? statistics.mbpsRecvRate: statistics.mbpsSendRate}`}</div></div>
                </div>
                { (iType === 'dst')?
                <div className='flex-column  border-top-1'>
                    <div className='flex justify-content-between m-2'>
                        
                        <div className='flex-column'>
                            <div className='flex justify-content-center text-base font-italic'>Interval</div>
                            <div className='flex'>
                                <div className='m-2'><div>{'Packets'}</div><div>{statistics.pktRecv}</div></div>
                                <div className='m-2'><div>{'Dropped'}</div><div>{statistics.pktRcvDrop}</div></div>
                                <div className='m-2'><div>{'Lost'}</div><div>{statistics.pktRcvLoss}</div></div>
                            </div>
                        </div>
                        <div></div>
                        <div className='flex-column'>
                            <div className='flex justify-content-center text-base font-italic'>Cummulative</div>
                            <div className='flex'>
                                <div className='m-2'><div>{'Packets'}</div><div>{statistics.pktRecvTotal}</div></div>
                                <div className='m-2'><div>{'Dropped'}</div><div>{statistics.pktRcvDropTotal}</div></div>
                                <div className='m-2'><div>{'Lost'}</div><div>{statistics.pktRcvLossTotal}</div></div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                :
                <div className='flex-column  border-top-1'>
                    <div className='flex justify-content-between m-2'>
                        
                        <div className='flex-column'>
                            <div className='flex justify-content-center text-base font-italic'>Interval</div>
                            <div className='flex'>
                            <div className='ml-2'><div>{'Packets'}</div><div>{statistics.pktSent}</div></div>
                        <div className='ml-2'><div>{'Dropped'}</div><div>{statistics.pktSndDrop}</div></div>
                        <div className='ml-2'><div>{'Retransmitted'}</div><div>{statistics.pktRetrans}</div></div>
                        <div className='ml-2'><div>{'Lost'}</div><div>{statistics.pktSndLoss}</div></div>
                            </div>
                        </div>
                        <div></div>
                        <div className='flex-column'>
                            <div className='flex justify-content-center text-base font-italic'>Cummulative</div>
                            <div className='flex'>
                                <div className='ml-2'><div>{'Packets'}</div><div>{statistics.pktSentTotal}</div></div>
                        <div className='ml-2'><div>{'Dropped'}</div><div>{statistics.pktSndDropTotal}</div></div>
                        <div className='ml-2'><div>{'Retransmitted'}</div><div>{statistics.pktRetransTotal}</div></div>
                        <div className='ml-2'><div>{'Lost'}</div><div>{statistics.pktSndLossTotal}</div></div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                }
            </div>
        </>
    )
}

export default SrtStats;