import React, { useState, useEffect, useContext, useRef } from 'react';


import { useLocation, useNavigate } from "react-router-dom";

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import Streams from './streams';
import { DbAgentSet } from '../db/agents';
import { DatacastAPIUpdateAgent } from '../db/datacast';
import { DatacastContext } from '../context/datacastcloud';
import Tunnels from './tunnels';

const EditAgent = () => {
 
    const [ datacastProperties ] = useContext(DatacastContext);

    const [origAgent, setOrigAgent] = useState(null);

    const location = useLocation();
    const initAgent = location.state?.agent;
    const prevUrl = location.state?.from;

    const [agent, setAgent] = useState(initAgent);

    const [dirty, setDirty] = useState(false);
    const [showStreams, setShowStreams] = useState(true);
    const [showTunnels, setShowTunnels] = useState(false);

    const toast = useRef(null);

    const navigate = useNavigate();


    useEffect(() => {

        setOrigAgent(agent);
    },[agent]); 
    
    const typeOptions = [
        'GATEWAY',
        'MAP'
    ]


    const cancelAgent = () => {

        setAgent(origAgent);
        setDirty(false);
    }

    const updateAgent = () => {
        if(dirty) {

            DatacastAPIUpdateAgent(datacastProperties.ip, {
                AgentName:agent.name,
                Uuid:agent.uuid
            }).then((result) => {

            }).catch((error) => {
                console.error(error);
            });

            DbAgentSet(datacastProperties.userIp, agent).then((result) => {
                const _agent= result.data;
                
                if(result.error === 0) {
                    setDirty(false);
                    setAgent(_agent);
                    setOrigAgent(_agent);

                    showResult('success', 'Agent Updated', result.message);
                }
                else {
                    showResult('error', 'Agent Update Failed', result.message);
                }

            }).catch((error) => {
                console.error(error);
            });
        }

    }

    const userInput = (field, value) => {

        setAgent({...agent, [field]:value.substring(0,36)});
        setDirty(true);
    }

    const showResult = (severity, summary, detail) => {
        toast.current.show({severity:severity, summary:summary, detail:detail, life: 3000});
    }

    const goBack = () => {
        if(prevUrl === 'agents') {
            navigate('/agents');
        }
        else if (prevUrl === 'status') {
            navigate('/');
        }
        else {
            navigate('/agents');
        }
    }

    const changeTab = (stream) => {
        if(stream) {
            setShowStreams(true);
            setShowTunnels(false);
        }
        else {
            setShowStreams(false);
            setShowTunnels(true);
        }
    }

    return (
        <>
            <Toast ref={toast} />

            <div style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='flex align-items-center'>
                    <Button className='p-button-text p-button-lg' icon="pi pi-arrow-left" onClick={()=>goBack(-1)}></Button>
                    <div className='p-3 text-white font-bold'>EDIT AGENT</div>
                </div>
            </div> 
            <div className='border-solid surface-border border-1'>
                <div className='p-6'>
                    
                    <div className='grid col-12'>
                        <div className='col-6'>
                            <div className='font-bold mb-1'>Name</div>
                            <div className='flex w-full'>
                                <InputText className='surface-100 w-full border-round-md border-none' value={agent.name} onChange={(e) => userInput('name',e.target.value)} disabled={!datacastProperties.agentGroup.editAgent}/>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='font-bold mb-1'>UNIQUE IDENTIFIER</div>
                            <div className='flex '>
                                <InputText className='surface-100 w-full border-round-md border-none' keyfilter={/[a-z0-9-]{1,36}/i} value={agent.uuid}  onChange={(e) => userInput('uuid',e.target.value)} disabled={!datacastProperties.agentGroup.editAgent}/>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='font-bold mb-1'>TYPE</div>
                            <div className='flex w-full'>
                                <Dropdown className='surface-100 w-full border-round-md border-none' value={agent.type} options={typeOptions} onChange={(e) => userInput('type',e.target.value)} disabled/>
                            </div>
                        </div>
                        <div className='col-12'>
                            {
                            (dirty)?
                                <div className='flex justify-content-end mt-4' >
                                    <div>
                                        <Button label="Revert" className="p-button-danger" icon="pi pi-times" onClick={cancelAgent} />
                                    </div>
                                    <div className='pl-2'>
                                        <Button label="Update" className="p-button-primary" icon="pi pi-check" onClick={updateAgent} />
                                    </div>
                                </div>
                            :
                                <></>
                            }
                        </div>
                        <div className='col-12 mt-3' style={{backgroundColor:"rgb(64,64,64)"}}>
                            <div className='flex justify-content-between'>
                                <Button className='p-1 text-white shadow-3 border-solid border-white' label="Streams" text outlined onClick={()=>changeTab(true)}/> 
                                {(datacastProperties.sshIp)? <Button className='p-1 ml-1 text-white shadow-3 border-solid' label="Advanced" text outlined onClick={()=>changeTab(false)}/>:<></>}
                            </div>
                        </div>
                        {
                            (showStreams)?<Streams agent={agent} />:<></>
                        }
                        {
                            (showTunnels)?<Tunnels agent={agent} />:<></>
                        }
                    </div>
                </div>
            </div>
        </>
    
    )
}

export default EditAgent;