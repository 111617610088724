export function getLocalShortTime(date) {
    return (`${date.toLocaleTimeString()}`);
}


export function getLocalTime(date) {
    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    return (`${month[date.getMonth()]} ${date.getDate()} ${date.toLocaleTimeString()}`);
}

export function getLocalTimeTicks(date) {
    return date.toLocaleString('en-US', {hour12: false});
}

export function getUTCTimestamp(date) {
    return (`${date.getUTCFullYear()}-${("00" + (date.getUTCMonth() + 1)).slice(-2)}-${("00" + date.getUTCDate()).slice(-2)} ${("00" + date.getUTCHours()).slice(-2)}:${("00" + date.getUTCMinutes()).slice(-2)}:00`);
}

export function getUTCTimestampCompressed(date) {
    return (`${("00" + (date.getUTCMonth() + 1)).slice(-2)}/${("00" + date.getUTCDate()).slice(-2)} ${("00" + date.getUTCHours()).slice(-2)}:${("00" + date.getUTCMinutes()).slice(-2)}`);
}

export function formatPackets(packets) {
    if (packets && packets > 0) {
        if(packets > 1000000) {

            return `${(packets/1000000).toFixed(3)} M`;
        }
        else if (packets > 1000) {
            return `${(packets/1000).toFixed(3)} k`;
        }
        else {
            return packets;
        }
    }
    else {
        return 'N/A'
    }
}

export function formatUptime(seconds){

    var weeks = Math.floor(seconds / (7*24*60*60));
    var days = Math.floor(seconds / (24*60*60));
    var hours = Math.floor(seconds / (60*60));
    var minutes = Math.floor(seconds / 60);

    if(seconds < 60) {
        return `${seconds} s`;
    }
    else if(seconds < 3600){
        return `${minutes} m`;
    }
    else if(seconds < 3600*24) {
        return `${hours} h`;
    }
    else if(seconds < 3600*24*7) {
        return `${days} d`;
    }
    else {
        return `${weeks} w`;
    }
  
}