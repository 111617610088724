
export const SrtServerAPIGetHealth = async (ip) => {
    let cpu = null;
    let memory = null;
    let disk = null;
    let network = null;

    try {
        let response = await fetch(`${ip}/api/v1/system/systeminfo/cpu`, {
            mode:'cors'
        });
        
        if(response.ok) {
            cpu = await response.json();
        }

        response = await fetch(`${ip}/api/v1/system/systeminfo/memory`, {
            mode:'cors'
        });
        if(response.ok) {
            memory = await response.json();
        }


        response = await fetch(`${ip}/api/v1/system/systeminfo/disk`, {
            mode:'cors'
        });
        if(response.ok) {
            disk = await response.json();
        }

        response = await fetch(`${ip}/api/v1/system/systeminfo/network`, {
            mode:'cors'
        });
        if(response.ok) {
            network = await response.json();
        }

        return ([cpu, memory, disk, network]);
    }
    catch(err)
    {
        console.error(err);
        return [];
    }
}
