import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { DatacastAPIGroupStreamsSet, DatacastAPIGroupStreams } from '../db/datacast';
import { DbStreams } from '../db/streams';
import { DatacastContext } from '../context/datacastcloud';

const EditGroup = () => {
 
    const [ datacastProperties ] = useContext(DatacastContext);
    const [ streams, setStreams ] = useState([]);
    const [ dirty, setDirty ] = useState(false);

    const [totalRecords, setTotalRecords] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedStreams, setSelectedStreams] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    const group = location.state;

    useEffect(() => {

        const getStreams = async () => {
            const _sourcesObj = {};

            const _sources = await DbStreams(datacastProperties.userIp, 'source');

            if(_sources) {
                
                for(let source of _sources) {
                    _sourcesObj[source.uuid] = source;
                }
            }

            const _streams = await DatacastAPIGroupStreams(datacastProperties.ip, group.GroupUuid);
            const _streamsToShow = [];
            if(_streams) {

                const _selectedStreams = [];
                for(let stream of _streams) {
                    stream.Name = _sourcesObj[stream.Source] ? _sourcesObj[stream.Source].name : stream.Name || stream.Source;
                    

                    if(stream.GroupUuid !== null || _sourcesObj[stream.Source]) {

                        stream.disabled = (_sourcesObj[stream.Source] === undefined);

                        if(stream.GroupUuid === group.GroupUuid) {
                            _selectedStreams.push(stream);
                        }

                        _streamsToShow.push(stream);
                    }

                }

                setSelectedStreams(_selectedStreams);
                setTotalRecords(_streamsToShow.length);
                setStreams(_streamsToShow);
            }
        }

        getStreams().catch((error) => {
            console.error(error);
        });

    },[group, datacastProperties]); 


    const onSelectionChange = (event) => {
        setDirty(true);
        const value = event.value;
        setSelectedStreams(value);
        setSelectAll(value.length === totalRecords);
    }

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            setSelectAll(true);
            setSelectedStreams(streams);
            setDirty(true);
        }
        else {
            setSelectAll(false);
            setSelectedStreams([]);
        }
    }

    const cancelStreams = () => {
        navigate('/admin');
    }

    const updateStreams = () => {
        if(dirty) {
            DatacastAPIGroupStreamsSet(datacastProperties.ip, group.GroupUuid, selectedStreams).then((result) => {
                
                if(result.error === 0) {
                    setDirty(false);
                }

            }).catch((error) => {
                console.error(error);
            });
        }

    }

    const rowClassName = (data) => {
        return data.disabled ? 'p-disabled':'';
    }

    return (
        <> 
        <div style={{backgroundColor:"rgb(64,64,64)"}}>
            <div className='flex align-items-center'>
                <Link to="/admin" className="align-items-center" style={{ textDecoration: 'none' }} >
                    <Button className='p-button-text p-button-lg' icon="pi pi-arrow-left"></Button>
                </Link>
                <div className='p-3 text-white font-bold'>GROUP PERMISSIONS</div>
            </div>
        </div>                   
        <div className='grid col-12'>
            <div className='col-12'>
                <DataTable value={streams} editMode="cell" responsiveLayout="scroll"
                    onSelectionChange={onSelectionChange} 
                    selectAll={selectAll}
                    selection={selectedStreams}
                    onSelectAllChange={onSelectAllChange}
                    rowClassName={rowClassName}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field="Name" header="Source"  ></Column>
                    <Column field="App" header="Server" ></Column>
                </DataTable>
            </div>
        </div>
        <div className='col-12'>
            {
            (dirty)?
                <div className='flex justify-content-end mt-4' >
                    <div>
                        <Button label="Cancel" className="p-button-danger" icon="pi pi-times" onClick={cancelStreams} />
                    </div>
                    <div className='pl-2'>
                        <Button label="Update" className="p-button-primary" icon="pi pi-check" onClick={updateStreams} />
                    </div>
                </div>
            :
                <></>
            }
        </div>
        </>            
    
    )
}

export default EditGroup;