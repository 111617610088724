import React, {useState, useContext} from 'react';

import { useNavigate } from 'react-router-dom';

import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { DbCortexCreate } from '../db/cortex';
import { DatacastAPICreateAgentGroup } from '../db/datacast';
import uuid from 'react-uuid';

const CreateCortex = () => {

    const defaultValues = {
        uuid: uuid(),
        name: '',
        cloudIp: '',
        cortexIp: ''
    }

    const [cortex, setCortex] = useState(defaultValues);
    const navigate = useNavigate();
    
    const create = () => {
        
        DbCortexCreate(undefined, cortex).then((result) => {

            const dGroup = {
                GroupUuid:cortex.uuid,
                GroupName: cortex.name
            }

            if(result.error === 0) {
                DatacastAPICreateAgentGroup(undefined, dGroup).then((result)=>{
                    navigate('/');
                })  
            }
            else {
                navigate('/');
            }

        }).catch((error) => {
            console.error(error);
        });
        
    }

    const userInput = (field, value) => {

        setCortex({...cortex, [field]:value.substring(0,36)})
    }


    return (
        <>
            <div className='flex flex-column flex-wrap align-items-center h-screen'>
                <div className='sm:col-12 md:col-4 lg:col-3'>
                    <div className='flex justify-content-center'>
                        <div className='flex-column align-items-center w-full'>
                            <div className='flex justify-content-center mt-7'>
                                <Image src="img/logo_big.png" />
                            </div>

                        </div>
                    </div>
                </div>
                <div className='flex mt-3'>
                    <div className='text-gray-300 text-3xl'>CORTEX CONFIGURATION</div>
                </div>
                <div className='flex-column'>
                    <div className='flex p-2 align-items-center'>
                        <div className='flex-column'>
                            <div className='m-2'>Name</div>
                            <div className='m-2'>
                                <InputText value={cortex.name} tooltip='Displayed at the top of the Cortex instance' keyfilter={/[\w\d]{1,36}/i} onChange={(e) => userInput('name',e.target.value)} placeholder="Cortex Name" />
                            </div>
                        </div>
                        <div className='flex-column'>
                            <div className='m-2'>UUID</div>
                            <div className='m-2'>
                                <InputText value={cortex.uuid} disabled/>
                            </div>
                        </div>
                    </div>
                    <div className='flex p-2 align-items-center'>
                        <div className='flex-column'>
                            <div className='m-2'>Datacast IP</div>
                            <div className='m-2'>
                                <InputText value={cortex.cloudIp} tooltip='The IP to connect to Datacast Cloud' keyfilter={/[0-9.:]{1,36}/i} onChange={(e) => userInput('cloudIp',e.target.value)} placeholder="CLOUD IP" />
                            </div>
                        </div>
                        <div className='flex-column'>
                            <div className='m-2'>Cortex IP</div>
                            <div className='m-2'>
                                <InputText value={cortex.cortexIp} tooltip='The IP to connect to Datacast Cloud' keyfilter={/[0-9.:]{1,36}/i} onChange={(e) => userInput('cortexIp',e.target.value)} placeholder="CORTEX IP"/>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-content-center mt-3'>
                        <div className='flex'>
                            <Button label="Create" className="p-button-primary" icon="pi pi-check" onClick={create} disabled={!(cortex.name.length > 5 && cortex.uuid.length > 5)}/>
                        </div>
                    </div>
                </div>
            </div>
        </>            
    
    )
}

export default CreateCortex;