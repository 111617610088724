export const plotOptions = {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          
        }
      }
    },
    tooltip: {
      x:{
        format:'MM/dd/yy HH:mm:ss'
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [2,3,4]
    },
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 1
    },
    xaxis: {
      type: 'datetime',
      labels:{
        datetimeUTC:false
      }
    },
    yaxis: [
      {
        title: {
          text:'units'
        }
      }
    ],
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left'
    },
  };

  export const plotRttOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          
        }
      }
    },
    tooltip: {
      x:{
        format:'MM/dd/yy HH:mm:ss'
      }
    },
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 1
    },
    xaxis: {
      type: 'datetime',
      labels:{
        datetimeUTC:false
      }
    },
    yaxis: [
      {
        title: {
          text:'units'
        }
      }
    ],
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left'
    },
  };

