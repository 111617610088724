import React, { useState, useEffect, useRef } from 'react';

import { useForm, Controller, useFormState } from 'react-hook-form';


import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';

export const default_app ={
    app_player:'live', //live
    app_publisher: 'live', 
    record_hls:'off', //#on, off 
    record_hls_segment_duration:10, // #unit s
    relayEnable:false,
    type:'pull',
    mode:'loop',
    reconnect_interval:10,
    idle_streams_timeout:10, //#s -1: unlimited
    upstreams:'192.168.31.106:8080?streamid=uplive.sls.com/live'
}


const SrtApp = ({config, app}) => {
    const toast = useRef(null);

    const [enableRelay, setEnableRelay] = useState(false);

    const { control, handleSubmit, setValue } = useForm({defaultValues:default_app});

    const { isDirty } = useFormState({control}); 

    const setObjectFormValues = (object, chain) => {
        Object.keys(object).forEach(function(prop) {
            if(prop === 'relayEnable') {
                setEnableRelay(object[prop].relayEnable);
            }

            if(typeof object[prop] !== 'object'){
                
                if(chain === null){
                    setValue(`${prop}`, object[prop]);
                    
                }
                else {
                    setValue(`${chain}.${prop}`, object[prop]);
                }
                
            }
            else {
                if(chain === null) {
                    return setObjectFormValues(object[prop], `${prop}`);
                }
                else {
                    return setObjectFormValues(object[prop], `${chain}.${prop}`);
                }
            }
        })
    }

    useEffect(() => {


        
    },[]); //Run each time id changes.

    const onSubmit = (data) => {
        console.log("Submitting form");
        console.log(data);
    };



    return(
        <div className="surface-card p-4 border-round">
            <Toast ref={toast} />
            
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid"> 
                <div className="col-6">
                    <div className="font-medium text-3xl text-900 mb-3">App Configuration</div>
                    <div className="text-500 mb-5">Some stuff</div>
                    <ul className="list-none p-0 m-0 border-top-1 surface-border">  
                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                            <div className="text-500 w-full md:w-4 font-medium">Player</div>
                            <div className="text-900 w-full md:w-8">
                                <Controller name="app_player" control={control} render={({ field }) => (   
                                    <InputText {...field} />
                                )} />
                            </div>
                        </li>   
                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                            <div className="text-500 w-full md:w-4 font-medium">Publisher</div>
                            <div className="text-900 w-full md:w-8">
                                <Controller name="app_publisher" control={control} render={({ field }) => (   
                                    <InputText {...field} />
                                )} />
                            </div>
                        </li>   
                        
                        <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-4 font-medium">Enable HLS Recording</div>
                            <div className="text-900 w-full md:w-8">
                                <Controller name="record_hls" control={control} render={({ field }) => (   
                                    <Dropdown value={field.value} onChange={(e) => field.onChange(e.value)} options={['off', 'on']} />
                                )} />
                            </div>
                        </li>

                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                            <div className="text-500 w-full md:w-4 font-medium">HLS Segment Duration</div>
                            <div className="text-900 w-full md:w-8">
                                <Controller name="record_hls_segment_duration" control={control} render={({ field }) => (   
                                    <InputNumber value={field.value} min="2" onChange={(e) => field.onChange(e.value)} />
                                )} />
                            </div>
                        </li> 

                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                            <div className="text-500 w-full md:w-4 font-medium">Enable Relay</div>
                            <div className="text-900 w-full md:w-8">
                                <Controller name="relayEnable" control={control} render={({ field }) => (   
                                    <Checkbox onChange={(e) => {field.onChange(e.checked); setEnableRelay(e.checked)}} checked={field.value}></Checkbox>
                                )} />
                            </div>
                        </li>
                    </ul>
                </div>
                {(enableRelay)?
                <div className="col-6">
                    <div className="font-medium text-3xl text-900 mb-3">Relay Configuration</div>
                    <div className="text-500 mb-5">Connecting to other servers.</div>
                    <ul className="list-none p-0 m-0 border-top-1 surface-border">  
                        <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-4 font-medium">Type</div>
                            <div className="text-900 w-full md:w-8">
                                <Controller name="relay.type" control={control} render={({ field }) => (   
                                    <Dropdown value={field.value} onChange={(e) => field.onChange(e.value)} options={['push', 'pull']} />
                                )} />
                            </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-4 font-medium">Mode</div>
                            <div className="text-900 w-full md:w-8">
                                <Controller name="relay.mode" control={control} render={({ field }) => (   
                                    <Dropdown value={field.value} onChange={(e) => field.onChange(e.value)} options={['all', 'loop', 'hash']} />
                                )} />
                            </div>
                        </li>     

                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                            <div className="text-500 w-full md:w-4 font-medium">Reconnect Interval</div>
                            <div className="text-900 w-full md:w-8">
                                <Controller name="relay.reconnect_interval" control={control} render={({ field }) => (   
                                    <InputNumber value={field.value} min="2" onChange={(e) => field.onChange(e.value)} />
                                )} />
                            </div>
                        </li> 

                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                            <div className="text-500 w-full md:w-4 font-medium">Idle Streams Timeout</div>
                            <div className="text-900 w-full md:w-8">
                                <Controller name="relay.idle_streams_timeout" control={control} render={({ field }) => (   
                                    <InputNumber value={field.value} min="2" onChange={(e) => field.onChange(e.value)} />
                                )} />
                            </div>
                        </li> 

                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                            <div className="text-500 w-full md:w-4 font-medium">Upstream URL</div>
                            <div className="text-900 w-full md:w-8">
                                <Controller name="relay.upstreams" control={control} render={({ field }) => (   
                                    <InputText {...field} />
                                )} />
                            </div>
                        </li>   

                    </ul>
                </div>
                :
                ''
                }

                <div className="col-4">
                    <div className="flex border-top-1 surface-border pt-3 justify-content-end">
                        <Button className="flex w-2 p-button-text" icon="pi pi-times" label="Cancel" />
                        <Button className="flex w-2" type="submit" icon="pi pi-check" label="Save" disabled={!isDirty}/>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default SrtApp;