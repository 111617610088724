import React, {useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { DbCreateUser, DbUsers } from '../db/users';
import { Message } from 'primereact/message';

import bcrypt from 'bcryptjs';

import { DatacastContext } from '../context/datacastcloud';

const CreateUser = () => {
 
    const [showMessage, setShowMessage] = useState(false);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({});

    const navigate = useNavigate();

    const defaultValues = {
        name: '',
        email: '',
        password: ''
    }

    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });
    const [datacastProperties] = useContext(DatacastContext);
    
    const create = async (user) => {

        const salt = bcrypt.genSaltSync(10);
        const hashedPassword = bcrypt.hashSync(user.password, salt) // hash created previously created upon sign up

        setFormData(user);

        const result = await DbCreateUser(datacastProperties.userIp, {
            name:user.name,
            email:user.email,
            password:hashedPassword
        });

        if(result.error === 0) {
            setShowMessage(true);
        }
        else {
            setError(result.message);
        }
        
       /* DbCreateUser(datacastProperties.userIp, {
            name:user.name,
            email:user.email,
            password:hashedPassword,
        }).then((result) => {
            console.log(result);
            if(result.error === 0) {
                setShowMessage(true);
            }

        }).catch((error) => {
            console.error(error);
        });*/
        
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => onHideMessage(false)} /></div>;
    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    const onHideMessage = () => {
        navigate('/');
        setShowMessage(false)
    }

    return (
        <>
            <Dialog visible={showMessage} onHide={() => onHideMessage()} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="flex justify-content-center flex-column pt-6 px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <div className='m-2 text-lg font-bold'>Registration Successful!</div>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        Your account <b>{formData.name}</b> is registered under email <b>{formData.email}</b>. Please contact administrator for activation.
                    </p>
                </div>
            </Dialog>
            <div className='flex flex-column flex-wrap justify-content-between align-items-center h-screen'>
                <div className='sm:col-12 md:col-4 lg:col-3'>
                    <div className='flex justify-content-center'>
                        <div className='flex-column align-items-center w-full'>
                            <div className='flex justify-content-center mt-7'>
                                <Image src="img/logo_big.png" />
                            </div>
                            <div className='flex justify-content-center mt-4'>
                                LET'S GET STARTED
                            </div>
                            <div className='flex justify-content-center mt-4'>
                                Already have an account? 
                                <Link to="/">
                                    Login
                                </Link>
                            </div>
                            {
                                    (error.length > 0) ?
                                    <div className='flex p-2 justify-content-center'>
                                        <Message severity="error" text={`${error}`}></Message>
                                    </div>
                                    :<></>
                            }
                            <form onSubmit={handleSubmit(create)}>
                                <div className='flex-column justify-content-center align-items-center mt-8'>
                                    <div className='flex-column justify-content-center'>
                                        <div className='flex font-bold'>Name</div>
                                        <Controller name="name" control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field} autoFocus className='border-round-lg border-none surface-50 w-full' />
                                        )} />    
                                        {getFormErrorMessage('name')}                                           
                                    </div>
                                </div>
                                <div className='flex-column justify-content-center align-items-center mt-2'>
                                    <div className='flex-column justify-content-center'>
                                        <div className='flex font-bold'>E-MAIL</div>
                                        <Controller name="email" control={control}
                                            rules={{ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }}}
                                            render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field}  className='border-round-lg border-none surface-50 w-full' />
                                        )} /> 
                                        {getFormErrorMessage('email')}    
                                    </div>
                                    <div className='flex-column jusify-content-center mt-2'>
                                        <div className='flex font-bold'>PASSWORD</div>
                                        <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                            <Password id={field.name} {...field} className='w-full' inputClassName='border-none border-round-lg surface-50 w-full' toggleMask={true} header={passwordHeader} footer={passwordFooter}/>
                                        )} />
                                        {getFormErrorMessage('password')}
                                    </div>
                                </div>
                                <div className='flex mt-6'>
                                    <Button type="submit" className='w-full bg-blue-600 p-4 border-round-lg ' label="CREATE" ></Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className='col-12 lg:col-8'>
                    <div className='flex justify-content-center mb-8' style={{backgroundColor:"rgb(64,64,64)"}}>
                        <div className='flex justify-content-center align-items-center p-4'>
                            <div className='text-white  text-sm'>INTERNATIONAL DATACASTING
                                <span className='text-white  text-xs'> &copy; 2023</span>
                            </div>
                        </div>
                    </div>
                </div>    
                
            </div>
        </>            
    
    )
}

export default CreateUser;