import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';

import Server from './server';

import { useInterval } from '../hooks/useinterval';

const Servers = ({cloud, delay}) => {
    const [servers, setServers] = useState([]);
    const toast = useRef(null);
    const [inDelay, setInDelay] = useState(5000);
    const [appServers, setAppServers] = useState([]);

    const fetchServicesData = async () => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        const response = await fetch(`/api/v1/datacast/srtservers`, requestOptions);

        if(response.ok) {
            const data = await response.json();

            if(data){
                const _appServers = [];

                data.forEach(element => {

                    const [domain, player] =  element.playerurl.split("/");
                    _appServers.push({
                        value:element.uuid, //live
                        label:element.name
                    });
                });

                setAppServers(_appServers);
                setServers(data);
            }            
        }
        
    }

    useEffect(() => {

        if(delay) {
            
            setInDelay(delay);
        }

        
        fetchServicesData().catch((error) => {console.error(error)});
            
        
    },[cloud, delay]); //Run each time id changes.

    useInterval(() => {
        fetchServicesData().catch((error) => {console.error(error)});
    }, inDelay);


    return (
        <>
            <Toast ref={toast} />
            <div className='flex align-content-center' style={{backgroundColor:"rgb(64,64,64)"}}>
                <span className='p-3 text-white font-bold'>SERVERS <span className="text-white font-normal ml-3 ">{`(${servers.length})`}</span></span>
            </div>

            <div className="flex flex-wrap p-3">
                {
                    servers.map((server) => {
                        return(
                            <Server key={server.uuid} server={server} apps={appServers} cloud={cloud} />
                        )
                    })
                }
            </div>
        </>
    )
}

export default Servers;