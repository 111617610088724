import React, {useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';

import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';

import { DatacastContext } from '../context/datacastcloud';
import { DbLogin, DbUserSession } from '../db/users';
import { DbAgentGroup } from '../db/agentgroups';

const Login = () => {

    const [showMessage, setShowMessage] = useState(false);
    const [credentials, setCredentials] = useState({});
    const [datacastProperties, setDatacastProperties] = useContext(DatacastContext);

    const defaultValues = {
        email:'',
        password:''
    }

    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });

    const processLogin = async(_credentials) => {
        const result = await DbLogin(datacastProperties.userIp, { ..._credentials });
        const user = result.data;
            
        if(result.error === 0) {
            
            if(user.agentGroup !== null && user.agentGroup !== 'ALL') {
                const _agentGroup = await DbAgentGroup(datacastProperties.userIp,user.agentGroup );

                const agentMap = {};
                if(_agentGroup) {
                    for(let agent of _agentGroup.agent_groups_agents) {
                        agentMap[agent.agent_id] = agent;
                    }
                }
                else {
                    _agentGroup = {
                        createAgent:1,
                        destroyAgent:1,
                        editAgent:1,
                        createStream:1,
                        destroyStream:1,
                        editStream:1,
                        editSource:1,
                        createSource:1,
                        destroySource:1
                    }
                    user.agentGroup = 'ALL';
                }


                setDatacastProperties((prevState)=>({...prevState, 
                    loggedIn:true, 
                    name:user.name, 
                    email:user.email, 
                    id:user.id, 
                    accessGroup:user.accessGroup,
                    agentGroup:{
                        ..._agentGroup
                    },
                    agentMap:Object.keys(agentMap).length > 0?agentMap:null,
                    admin:(user.admin === 1)
                }));
                

            }
            else {
                setDatacastProperties((prevState)=>({...prevState, 
                    loggedIn:true, 
                    name:user.name, 
                    email:user.email, 
                    id:user.id, 
                    accessGroup:user.accessGroup,
                    agentMap:null,
                    agentGroup:{
                        createAgent:1,
                        destroyAgent:1,
                        editAgent:1,
                        createStream:1,
                        destroyStream:1,
                        editStream:1,
                        editSource:1,
                        createSource:1,
                        destroySource:1
                    },
                    admin:(user.admin === 1)
                }));
            }
        }
        else {
            setShowMessage(true);
        }
    }

    const login = (_credentials) => {

        setCredentials(_credentials);

        processLogin(_credentials).catch((error) => {
            console.error(error);
        });

    }

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (
        <>
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="flex justify-content-center flex-column pt-6 px-3">
                    <i className="pi pi-times-circle" style={{ fontSize: '5rem', color: 'var(--red-500)' }}></i>
                    <div className='m-2 text-lg font-bold'>Login failed!</div>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        Login for account <b>{credentials.email}</b> failed. If your account is not activated, please check with your system administrator.
                    </p>
                </div>
            </Dialog>
            <div className='flex flex-column flex-wrap justify-content-between align-items-center h-screen v-screen'>
                <div className='sm:w-full md:w-6 lg:w-3'>
                    <div className='flex justify-content-center'>
                        <div className='flex-column align-items-center w-full'>
                            <div className='flex justify-content-center mt-7'>
                                <Image src="img/logo_big.png" />
                            </div>
                            <div className='flex justify-content-center mt-4'>
                                WELCOME TO DATACAST CLOUD
                            </div>
                            <div className='flex justify-content-center mt-4'>
                                Don't have an account? 
                                <Link to="/createuser">
                                    Create Today!
                                </Link>
                            </div>
                            <form onSubmit={handleSubmit(login)}>
                                <div className='flex-column justify-content-center align-items-center mt-8'>
                                    <div className='flex-column justify-content-center'>
                                        <div className='flex font-bold'>E-MAIL</div>
                                        <Controller name="email" control={control} rules={{ 
                                            required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }}} 
                                            render={({ field, fieldState }) => (
                                            <InputText  id={field.name} { ...field } className='border-round-lg border-none surface-50 w-full' />
                                        )} />
                                        {getFormErrorMessage('email')}
                                    </div>
                                    <div className='flex-column jusify-content-center mt-2'>
                                        <div className='flex font-bold'>PASSWORD</div>
                                        <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                            <Password  id={field.name} {...field} className='w-full' inputClassName='border-none border-round-lg surface-50 w-full' toggleMask={true} feedback={false}/>
                                        )} />
                                        {getFormErrorMessage('password')}
                                    </div>
                                </div>
                                <div className='flex justify-content-center mt-6'>
                                    <Button  type="submit" className='w-full bg-blue-600 p-4 border-round-lg ' label="LOG IN" ></Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                
                <div className='hidden md:w-full lg:w-8 xl:w-8'>
                    <div className='flex justify-content-center mb-8' style={{backgroundColor:"rgb(64,64,64)"}}>
                        <div className='flex justify-content-center align-items-center p-4'>
                            <div className='text-white  text-sm'>INTERNATIONAL DATACASTING
                                <span className='text-white  text-xs'> &copy; 2023</span>
                            </div>
                        </div>
                    </div>
                </div>    
                
                
            </div>
        </>            
    
    )
}

export default Login;