import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { DbAgentGroup, DbAgentGroupAgentsSet } from '../db/agentgroups';
import { DatacastContext } from '../context/datacastcloud';
import { DbAgents } from '../db/agents';

const EditAgentGroup = () => {
 
    const [ datacastProperties ] = useContext(DatacastContext);
    const [ agents, setAgents ] = useState([]);
    const [ dirty, setDirty ] = useState(false);

    const [totalRecords, setTotalRecords] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedAgents, setSelectedAgents] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    const group = location.state;

    useEffect(() => {

        const getAgents = async () => {

            const _agents = await DbAgents(datacastProperties.userIp);

            const map = {};
            for(let agentT of _agents) {
                map[agentT.id] = agentT;
            }

            const _selectedAgents = [];
            const _agent = await DbAgentGroup(datacastProperties.userIp, group.uuid);
     
            if(_agent.agent_groups_agents) {
                for(let group of _agent.agent_groups_agents) {
                    _selectedAgents.push(map[group.agent_id]);
                }
                setSelectedAgents(_selectedAgents);
            }
            


            //setSelectedAgents(_selectedStreams);
            //setTotalRecords(_streamsToShow.length);
            setAgents(_agents);

        }

        getAgents().catch((error) => {
            console.error(error);
        });

    },[group, datacastProperties]); 


    const onSelectionChange = (event) => {
        setDirty(true);
        const value = event.value;
        setSelectedAgents(value);
        setSelectAll(value.length === totalRecords);
    }

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            setSelectAll(true);
            setSelectedAgents(agents);
            setDirty(true);
        }
        else {
            setSelectAll(false);
            setSelectedAgents([]);
        }
    }

    const cancelStreams = () => {
        navigate('/admin');
    }

    const updateStreams = () => {
        if(dirty) {
            const _agents = [];
            for(let agent of selectedAgents ) {
                _agents.push({
                    agent_group_id: group.id,
                    agent_id: agent.id
                });
            }

            DbAgentGroupAgentsSet(datacastProperties.ip, group.uuid, _agents).then((result) => {
                
                if(result) {
                    setDirty(false);
                }

            }).catch((error) => {
                console.error(error);
            });
        }
    }

    const rowClassName = (data) => {
        return data.disabled ? 'p-disabled':'';
    }

    return (
        <> 
        <div style={{backgroundColor:"rgb(64,64,64)"}}>
            <div className='flex align-items-center'>
                <Link to="/admin" className="align-items-center" style={{ textDecoration: 'none' }} >
                    <Button className='p-button-text p-button-lg' icon="pi pi-arrow-left"></Button>
                </Link>
                <div className='p-3 text-white font-bold'>AGENT GROUP PERMISSIONS</div>
            </div>
        </div>                   
        <div className='grid col-12'>
            <div className='col-12'>
                <DataTable value={agents} editMode="cell" responsiveLayout="scroll"
                    onSelectionChange={onSelectionChange} 
                    selectAll={selectAll}
                    selection={selectedAgents}
                    onSelectAllChange={onSelectAllChange}
                    rowClassName={rowClassName}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field="name" header="Name"  ></Column>
                </DataTable>
            </div>
        </div>
        <div className='col-12'>
            {
            (dirty)?
                <div className='flex justify-content-end mt-4' >
                    <div>
                        <Button label="Cancel" className="p-button-danger" icon="pi pi-times" onClick={cancelStreams} />
                    </div>
                    <div className='pl-2'>
                        <Button label="Update" className="p-button-primary" icon="pi pi-check" onClick={updateStreams} />
                    </div>
                </div>
            :
                <></>
            }
        </div>
        </>            
    
    )
}

export default EditAgentGroup;