import React, { useEffect, useState } from 'react';
import { useInterval } from '../../hooks/useinterval';

import Chart from 'react-apexcharts';

//import { Chart } from 'primereact/chart';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';


import SrtStats from './srtstats';

const options = [
  {label:'55 Mb/s',value:55},
  {label:'34 Mb/s',value:34},
  {label:'13 Mb/s',value:13},
  {label:'8 Mb/s',value:8},
  {label:'5 Mb/s',value:5},
  {label:'3 Mb/s',value:3},
  {label:'2 Mb/s',value:2},
  {label:'1 Mb/s',value:1},
  {label:'0.5 Mb/s',value:0.5},
  {label:'0.25 Mb/s',value:0.25},
];

const rttOptions = [
  {label:'3000',value:3000},
  {label:'2000',value:2000},
  {label:'1000',value:1000},
  {label:'500',value:500},
  {label:'250',value:250},
  {label:'125',value:125},
  {label:'60',value:60},
  {label:'30',value:30},
  {label:'15',value:15}
];

const packetOptions = [
  {label:'10000',value:1000},
  {label:'5000',value:5000},
  {label:'3000',value:3000},
  {label:'2000',value:2000},
  {label:'1000',value:1000},
  {label:'500',value:500},
  {label:'250',value:250},
  {label:'125',value:125}
];

const plotDefault = {
  chart: {
    height: 250,
    type: 'line',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: true
    }
  },
  tooltip: {
    x:{
      format:'MM/dd/yy HH:mm:ss'
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  title: {
    text: 'SRT INTERVAL STATISTICS',
    align: 'left'
  },
  markers: {
    size: 1
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeUTC:false
    }
  }
}



const Status = ({ip}) => {
    const [internalIp, setInternalIp] = useState(ip);
    let   [delay] = useState(5000);
    const [streams, setStreams] = useState([]);
    const [plotData, setPlotData] = useState([]);
    const [stats, setStats] = useState(null);
    const [selectedStream, setSelectedStream] = useState(null);
    const [type, setType] = useState('dst');
    const [plotOptions, setPlotOptions] = useState(plotDefault);
    const [bitrateOptionSelect, setBitrateOptionSelect] = useState(options[3].value);
    const [rttOptionSelect, setRttOptionSelect] = useState(rttOptions[6].value);
    const [packetOptionSelect, setPacketOptionSelect] = useState(packetOptions[5].value);

    const getPlotOptions = ({bScale = 8, rScale=60, pScale=500}) => {

      return( {
        chart: {
          height: 250,
          type: 'line',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true
          }
        },
        tooltip: {
          x:{
            format:'MM/dd/yy HH:mm:ss'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'SRT INTERVAL STATISTICS',
          align: 'left'
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC:false
          }
        },
        yaxis: [
          {
            seriesName:'rtt',
            min: 0,
            max:rScale,
            decimalsInFloat:0,
            title: {
              text:'rtt (ms)'
            }
          },
          {
            seriesName:'bitrate',
            min: 0,
            max:(bScale?bScale:20),
            decimalsInFloat:3,
            title: {
              text:'bitrate (Mb/s)'
            }
          },
          {
            seriesName:'packets',
            opposite: true,
            decimalsInFloat:0,
            min: 0,
            max: pScale,
            title: {
              text:'packets'
            }
          },
          {
            seriesName:'dropped',
            opposite: true,
            decimalsInFloat:0,
            show:false,
            min: 0,
            max: 10000,
            title: {
              text:'units'
            }
          },
          {
            seriesName:'lost',
            show:false,
            decimalsInFloat:0,
            opposite: true,
            min: 0,
            max: 10000,
            title: {
              text:'units'
            }
          },
          {
            seriesName:'rtx',
            show:false,
            decimalsInFloat:0,
            opposite: true,
            min: 0,
            max: 10000,
            title: {
              text:'units'
            }
          }
      
          
        ],
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left'
        },
      })
    }


    const ExtractServerandAgent = (url) => {
      const appArr = url.split("/");
      let agent = undefined;
      let app = undefined;
      if ( appArr.length > 2 ) {
        app = appArr[1];
      }

      if ( appArr.length > 3 ) {
        agent = appArr[2];
      }

      return [app, agent];
    }

    /*
    mbpsRecvRate: "0.643839"
mbpsSendRate: "0.000000"
msRTT: "32.999000"
msTimeStamp: "11645276"
pktRcvDrop: "0"
pktRcvDropTotal: "0"
pktRcvLoss: "0"
pktRcvLossTotal: "93"
pktRecv: "498"
pktRecvTotal: "1161500"
pktRetrans: "0"
pktRetransTotal: "0"
pktSent: "0"
pktSentTotal: "0"
pktSndDrop: "0"
pktSndDropTotal: "0"
pktSndLoss: "0"
pktSndLossTotal: "0"
*/

    const AddData = (series, type, srtStats) => {
      let newSeries = [];

      
      newSeries = [
        {name:'rtt', data:[]},
        {name:'bitrate', data:[]},
        {name:'packets', data:[]},
        {name:'dropped', data:[]}, //dropped
        {name:'lost', data:[]},    //lost
      ];

      if(series.length > 0) {
        newSeries[0].data = series[0].data.concat({x:Date.now(), y:srtStats.msRTT});
        newSeries[1].data = series[1].data.concat({x:Date.now(), y:(type === 'dst')?srtStats.mbpsRecvRate:srtStats.mbpsSendRate});
        newSeries[2].data = series[2].data.concat({x:Date.now(), y:(type === 'dst')?srtStats.pktRcv:srtStats.pktSnd});
        newSeries[3].data = series[3].data.concat({x:Date.now(), y:(type === 'dst')?srtStats.pktRcvDrop:srtStats.pktSndDrop});
        newSeries[4].data = series[4].data.concat({x:Date.now(), y:(type === 'dst')?srtStats.pktRcvLoss:srtStats.pktSndLoss});

        if(series[0].data.length > 100) {
          newSeries[0].data.shift();
          newSeries[1].data.shift();
          newSeries[2].data.shift();
          newSeries[3].data.shift();
          newSeries[4].data.shift();
        }
      }
      else {
        newSeries[0].data.push({x:Date.now(), y:srtStats.msRTT});
        newSeries[1].data.push({x:Date.now(), y:(type === 'dst')?srtStats.mbpsRecvRate:srtStats.mbpsSendRate});
        newSeries[2].data.push({x:Date.now(), y:(type === 'dst')?srtStats.pktRcv:srtStats.pktSnd});
        newSeries[3].data.push({x:Date.now(), y:(type === 'dst')?srtStats.pktRcvDrop:srtStats.pktSndDrop});
        newSeries[4].data.push({x:Date.now(), y:(type === 'dst')?srtStats.pktRcvLoss:srtStats.pktSndLoss});
      }

      if(type === 'src') {
        newSeries.push({name:'rtx', data:[]})
        if (series.length > 0) {
          newSeries[5].data = series[5].data.concat({x:Date.now(), y:srtStats.pktRetrans});  
        }
        else {
          newSeries[5].data.push({x:Date.now(), y:srtStats.pktRetrans});
        }
      }
      
      

      return newSeries;

    }

    const fetchData = async () => {

      if(internalIp !== null) {
        const response = await fetch(`${internalIp}/api/v1/srtserver/streams`);
        const result = await response.json();

        const _streams = [];
        const _plotData = [];

        if(result.code === 0) {
            
            const streamT = result.data.streams;


            Object.keys(streamT).forEach((srcKey, srcIndex)=> {
                const src = streamT[srcKey];
                const children = [];

                //Handle URL
                Object.keys(src.destinations).forEach((destKey, destIndex) => {
                    const dest = streamT[srcKey].destinations[destKey];

                    const [server, agent] = ExtractServerandAgent(dest.url);

                    children.push({key:`${srcIndex}-${destIndex}`, data:{
                        remoteIp:`${dest.remoteIp}:${dest.remotePort}`, 
                        url:dest.url, 
                        agent:agent?agent:'unknown',
                        server:server?server:'unknown',
                        startTime:dest.startTime, 
                        srtStats:dest.srt
                    }});

                });

                const [server, agent] = ExtractServerandAgent(src.source.url);

                const treeRow = {
                    key:`${srcIndex}`,
                    data:{
                        name:srcKey, 
                        remoteIp:`${src.source.remoteIp}:${src.source.remotePort}`, 
                        url:src.source.url, 
                        agent:agent?agent:'unknown',
                        server:server?server:'unknown',
                        startTime:src.source.startTime,
                        srtStats:src.source.srt
                    }
                };

                if(children.length > 0) {
                    treeRow.children = children;
                }

                _streams.push(treeRow);
                
            })

            let _selected = selectedStream;
            if(!_selected) {
              _selected = '0';
              setSelectedStream(_selected);
            }

            if(_selected) {
              const values = _selected.split('-');
              if(values.length > 0) {
                const item = _streams[values[0]];
                if(item) {
                  const source = item.data;
                  if(values.length > 1) {
                    const destination = item.children[values[1]].data;
                    
                    if (destination.srtStats) {
                      setPlotData(AddData(plotData, 'src', destination.srtStats));
                      setStats(destination.srtStats);
                      setType('src');
                    }
                  }
                  else {
                    if (source.srtStats) {
                      setStats(source.srtStats);
                      setPlotData(AddData(plotData, 'dst', source.srtStats));
                      setType('dst');
                    }
                  } 
                }
              }
            }



            setStreams(_streams);
        }
      }
    }

    useInterval(() => {

        fetchData().catch((error)=>console.error(error));
        
    }, delay);
    

    useEffect(() => {

        //Initiale fetch
        if(ip !== null && ip.length > 6) {
          fetchData().catch((error)=>console.error(error));
        } 
        
        setPlotOptions(getPlotOptions);
        setInternalIp(ip);
    },[ip]); //Run each time id changes.

    const selecteStream = (stream) => {
      const values = stream.split('-');
      if(values.length > 0) {
        const item = streams[values[0]];
        const source = item.data;
        if(values.length > 1) {
          const destination = item.children[values[1]].data;
          if(destination.srtStats) {
            setStats(destination.srtStats);
            setPlotData([]);
            setType('src');
          }
        }
        else {
          if (source.srtStats) {
            setStats(source.srtStats);
            setPlotData([]);
            setType('dst');
          }
        } 
      }

      setSelectedStream(stream);
    } 

    const changeScale = (scale, value) => {
      setPlotOptions(getPlotOptions({[scale]:value}))

      if(scale === 'bScale') {
        setBitrateOptionSelect(value);
      }
      else if (scale === 'rScale') {
        setRttOptionSelect(value);
      }
      else {
        setPacketOptionSelect(value);
      }
    }

    //optionLabel="GroupName" optionValue="GroupUuid"
    return(
        <>
          <div className='flex-column'>
            <div className='flex'>
              <div className='col-1  mt-8'>
                <div className='flex-column'>
                  <div className='font-medium text-800'>Bitrate Scale</div>
                  <Dropdown className='w-full border-none' value={bitrateOptionSelect} options={options} onChange={(e) => changeScale('bScale', e.value)} placeholder="Select a scale" />
                </div>
                <div className='flex-column mt-2'>
                  <div className='font-medium text-800'>RTT Scale</div>
                  <Dropdown className='w-full border-none' value={rttOptionSelect} options={rttOptions} onChange={(e) => changeScale('rScale', e.value)} placeholder="Select a scale" />
                </div>
                <div className='flex-column mt-2'>
                  <div className='font-medium text-800'>Packet Scale</div>
                  <Dropdown className='w-full border-none' value={packetOptionSelect} options={packetOptions} onChange={(e) => changeScale('pScale', e.value)} placeholder="Select a scale" />
                </div>
              </div>
              <div className='col-7 justify-content-center'>
                <Chart options={plotOptions} series={plotData} type="line" height={400} />
              </div>
              <div className='col-4'>
                <SrtStats stats={stats} type={type}/>
              </div>
            </div>
            <TreeTable value={streams} selectable={true} scrollable scrollHeight="400px" selectionMode="single" selectionKeys={selectedStream} onSelectionChange={e => selecteStream(e.value)}>
                <Column field="name" header="Source"  expander></Column>
                <Column field="server" header="Server"></Column>
                <Column field="agent" header="Agent"></Column>
                <Column field="remoteIp" header="Remote IP"></Column>
            </TreeTable>
          </div>
        </>
    )
}

export default Status;