import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Badge } from 'primereact/badge';
import { Ripple } from 'primereact/ripple';

import { DatacastContext } from '../context/datacastcloud';
import { DbAlertsTotal } from '../db/alerts';

import { useInterval } from '../hooks/useinterval';

const AlertNotification = () => {
    const [ datacastProperties ] = useContext(DatacastContext);
    const [numAlerts, setNumAlerts] = useState([]);
    const [delay] = useState(5000);


    useEffect(() => {
    
        DbAlertsTotal(datacastProperties.userIp, datacastProperties.id).then((result) => {

            setNumAlerts(result);
        }).catch((error) => {
            console.error(error);
        });

    },[]); 

    useInterval(() => {

        DbAlertsTotal(datacastProperties.userIp, datacastProperties.id).then((result) => {
            setNumAlerts(result);
        }).catch((error) => {
            console.error(error);
        });

    }, delay);

    

    return (
                
        <>                                                                    
             <Link to="/alerts" style={{ textDecoration: 'none' }} className="p-ripple flex p-3 lg:px-4 lg:py-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors w-full">
                <i className="pi pi-bell mr-2 text-3xl lg:mr-0 p-overlay-badge">
                    {
                        numAlerts > 0 ?
                        <Badge severity="danger" value={`${numAlerts}`}></Badge>
                        :
                        <></>
                    }
                </i>
                <span className="block lg:hidden font-medium">Notifications</span>
                <Ripple />
            </Link>
        </>
    
    )
}

export default AlertNotification;