

export const DbSrtServerConfig = async (db) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`${db}/api/v1/srtserver/config`, requestOptions);
    if(response.ok) {
        const result = await response.json();

        if(result.code === 0)    {
            return result.config;
        }
    }
    
    return null;
}

export const DbSrtServerConfigFile = async (db) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`${db}/api/v1/srtserver/configFile`, requestOptions);
    if(response.ok) {
        const result = await response.json();

        if(result.code === 0)    {
            return result.configFile;
        }
    }
    
    return null;
}


export const DbSrtServerConfigSet = async (db, config) => {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(config)
    };

    const response = await fetch(`${db}/api/v1/srtserver/config`, requestOptions);
    if(response.ok) {
        const result = await response.json();
    }
    
    return null;
}






