import React, { useState, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import uuid from 'react-uuid';

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { DatacastAPICreateAgent } from '../db/datacast';
import { DbCreateAgent } from '../db/agents';
import { DatacastContext } from '../context/datacastcloud';
import { Toast } from 'primereact/toast';
import { DbAgentGroupAdd, DbAgentGroup } from '../db/agentgroups';

const CreateAgent = () => {

    const toast = useRef(null);
 
    const [agent, setAgent] = useState({
        name:"",
        uuid:"", //uuid()
        type:"MAP"
    })

    const [dirty, setDirty] = useState(false);

    const [ datacastProperties, setDatacastProperties ] = useContext(DatacastContext);
    const navigate = useNavigate();

    
    const typeOptions = [
        'GATEWAY',
        'MAP'
    ]

    const createAgent = () => {
        if(dirty) {

            DatacastAPICreateAgent(datacastProperties.ip, datacastProperties.networkKey ,{
                AgentName:agent.name,
                Uuid:agent.uuid
            }).then((result) => {
                const _agent= result.data;

            }).catch((error) => {
                console.error(error);
            });

            DbCreateAgent(datacastProperties.userIp, agent).then((result) => {
                const _agent= result.data;
                
                if(result.error === 0) {
                    //DbAgentGroupAdd
                    if(Object.hasOwn(datacastProperties.agentGroup,'id')) {

                        //Add to agent group.
                        DbAgentGroupAdd(datacastProperties.userIp, {uuid:agent.uuid, id:datacastProperties.agentGroup.id }).then(()=>{
                            //Get permissions again to use the new group.
                            
                            DbAgentGroup(datacastProperties.userIp, datacastProperties.agentGroup.uuid).then((_agentGroup)=>{
                                if(_agentGroup) {
                                    
                                    const agentMap = {};
                                    for(let agent of _agentGroup.agent_groups_agents) {
                                        agentMap[agent.agent_id] = agent;
                                    }

                                    setDatacastProperties((prevState)=>({...prevState, 
                                        agentGroup:_agentGroup,
                                        agentMap:agentMap
                                    }));
                                }
                                else {
                                    showResult('error', 'Create Agent', `Could not retrieve ${datacastProperties.agentGroup.name} group.`);
                                }

                                setDirty(false);
                                setAgent(_agent);
                                showResult('success', 'Create Agent', `Agent created and added to ${datacastProperties.agentGroup.name} group.`);
                                navigate('/agents');
                            });
                            
                        }).catch((err) => {
                            console.error(err);
                            setDirty(false);
                            setAgent(_agent);
                            showResult('error', 'Create Agent', `Could not add agent to ${datacastProperties.agentGroup.name} group.`);
                            navigate('/agents');
                        })
                        
                    }
                    else {
                        setDirty(false);
                        setAgent(_agent);
                        showResult('success', 'Create Agent', 'Agent created successfully');
                        navigate('/agents');
                    }
                }
                else {
                    showResult('error', 'Create Agent', result.message);
                }

            }).catch((error) => {
                console.error(error);
            });
        }

    }

    const userInput = (field, value) => {

        setAgent({...agent, [field]:value.substring(0,36)})


        setDirty(true);
    }

    const showResult = (severity, summary, detail) => {
        toast.current.show({severity:severity, summary:summary, detail:detail, life: 3000});
    }

    return (
        <>
            <Toast ref={toast} />
            <div className='flex align-content-center' style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='p-3 text-white font-bold'>CREATE AGENT</div>
            </div>
            <div className='border-solid surface-border border-1'>
                <div className='p-6'>
                    
                    <div className='grid col-12'>
                        <div className='col-6'>
                            <div className='font-bold mb-1'>Name</div>
                            <div className='flex w-full'>
                                <InputText className='surface-100 w-full border-round-md border-none' keyfilter={/[\w\d-'"?!\s]{1,36}/i} tooltip='Name must be atleast 6 characters'  value={agent.name} onChange={(e) => userInput('name',e.target.value)} placeholder="Joe Smith"/>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='font-bold mb-1'>UNIQUE IDENTIFIER</div>
                            <div className='flex '>
                                <InputText className='surface-100 w-full border-round-md border-none' tooltip='Must be unique' keyfilter={/[a-z0-9-]{1,36}/i} value={agent.uuid}  onChange={(e) => userInput('uuid',e.target.value)} placeholder="Equipment number"/>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='font-bold mb-1'>TYPE</div>
                            <div className='flex w-full'>
                                <Dropdown className='surface-100 w-full border-round-md border-none' value={agent.type} options={typeOptions} onChange={(e) => userInput('type',e.target.value)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='flex justify-content-end mt-4' >
                                <Link to="/agents" style={{ textDecoration: 'none' }} >
                                    <Button label="Cancel" className="p-button-danger" icon="pi pi-times" />
                                </Link>
                                <div className='pl-2'>
                                    <Button label="Create" className="p-button-primary" icon="pi pi-check" onClick={createAgent} disabled={!(agent.name.length > 5 && agent.uuid.length > 5 )}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>            
    
    )
}

export default CreateAgent;