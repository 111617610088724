


export const DbCreateAlert = async (db, alert) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(alert)
    };

    const response = await fetch(`/api/v1/mistiq/alerts/create`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbAlertsDelete = async (db, userId, ids=[]) => {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ids)
    };

    const response = await fetch(`/api/v1/mistiq/alerts/users/${userId}`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbAlerts = async (db, userId) => {


    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/alerts/${userId}`, requestOptions);
    const result = await response.json();

    if(result.error === 0) {
        return result.data;
    }

    return [];
}

export const DbAlertsTotal = async (db, userId) => {


    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/alerts/${userId}/total`, requestOptions);
    const result = await response.json();

    if(result.error === 0) {
        return result.data;
    }

    return [];
}

export const DbAlert = async (db, userId, streamId) => {


    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/alerts/${userId}/${streamId}`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbAlertSet = async (db, alerts = []) => {


    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(alerts)
    };

    if(alerts.length > 0) {
        const response = await fetch(`/api/v1/mistiq/alerts`, requestOptions);

        if(response.ok) {
            const data = await response.json();
            return data;
        }

        return [];
    }
}
