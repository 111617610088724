import React from 'react';
import PerformanceMonitor from '../system/performancemonitor';


const Performance = ({ip}) => {

    return(
        <>
            <PerformanceMonitor ip={ip}/>
        </>
    )
}

export default Performance;