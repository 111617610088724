import React, { useEffect, useState, useContext, } from 'react';

import appInfo from '../version.json';
import { DbCortexInfo } from '../db/cortex';
import { DatacastAPIInfo } from '../db/datacast';
import { DatacastContext } from '../context/datacastcloud';


const About = () => {

    const [cloud, setCloud] = useState({});
    const [cortex, setCortex] = useState({});

    const [ datacastProperties ] = useContext(DatacastContext);

    useEffect(() => {

        DbCortexInfo(datacastProperties.userIp).then((result) => {
            if(result) {
                setCortex(result);
            }

        }).catch((error) => {
            console.error(error);
        });

        DatacastAPIInfo(datacastProperties.ip).then((result) => {
            if(result) {
                setCloud(result);
            }

        }).catch((error) => {
            console.error(error);
        });

      
    
    },[datacastProperties]); 

   
    return(
        <div className='surface-ground p-5'>
            <div className='flex align-content-start'>
                <i className="text-4xl bg-primary pi pi-info-circle border-round-2xl"></i>
                <span className='text-2xl'>MISTiQ UI</span>
            </div>
            <div className='grid mt-3 ml-5'>
                <div className='col-6'>UI </div><div className='col-6'>{appInfo.version}</div>
                <div className='col-6'>CORTEX API</div><div className='col-6'>{cortex.version}</div>
                <div className='col-6'>DATACAST CLOUD API</div><div className='col-6'>{cloud.version}</div>
            </div>
        </div>
    )
}

export default About;