
export const DbCortexInfo = async (db) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbCortex = async (db) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/cortex`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbCortexSet = async (db, user) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    const response = await fetch(`/api/v1/mistiq/cortex`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbCortexDestroy = async (db, uuid) => {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/cortex/${uuid}`, requestOptions);
    const data = await response.json();

    return data;
}


export const DbCortexCreate = async (db, cortex) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(cortex)
    };

    const response = await fetch(`/api/v1/mistiq/cortex/create`, requestOptions);
    const data = await response.json();

    return data;
}
