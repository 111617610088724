import React, {useContext, useState, useRef, useEffect} from 'react';
import {Routes, Link, Route, useLocation} from 'react-router-dom';

import { classNames } from 'primereact/utils';

import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';

import { useInterval } from '../hooks/useinterval';

import Status from './status/status';
import Performance from './performance/performance';
import Config from './configuration/config';

import { DatacastContext } from '../context/datacastcloud';

async function putData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },

      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    return response.json(); // parses JSON response into native JavaScript objects
}

function SrtServer() {
  
  const [running, setRunning] = useState(true);
  const [name, setName] = useState('SRT Server');
  const [delay] = useState(5000);
  const [internalIp, setInternalIp] = useState(null);
  const toast = useRef(null);

  const [ datacastProperties ] = useContext(DatacastContext);

  const location = useLocation();
  const uuid = location.state;


  useEffect(() => {
    const fetchServerData = async () => {
      if(uuid) {
        const response = await fetch(`/api/v1/datacast/srtservers/${uuid}`,{mode:'cors'});
        const _serverData = await response.json();
        const match = _serverData.ip.match(/^(http:\/\/|https:\/\/|www\.)/g);
        const _ip = (match) ? _serverData.ip: `http://${_serverData.ip}:${_serverData.controlport}`;
        const newIp = _ip.replace(/\/\s*$/,'');// Cut off trailing / or space
        
        setInternalIp(newIp);
      }
    }

    fetchServerData().catch((error)=>console.error(error));

  }, [datacastProperties, uuid])

  useInterval(() => {
      
      const fetchData = async () => {
          const data = await fetch(`${internalIp}/api/v1/srtserver/state`,{mode:'cors'});
          const json = await data.json();
          
          if(json.code === 0) {
            setRunning(json.data.state.running);
            setName(json.data.name);
          }
      }

      if(internalIp) {
        fetchData(internalIp).catch(console.error);
      }
      
  }, delay);

  const showSuccess = (message) => {
    toast.current.show({severity:'success', summary:'Success Message', detail:message, life: 3000});
  }

  const showWarn = (message) => {
    toast.current.show({severity:'warn', summary: 'Warn Message', detail:message, life: 3000}); 
  }

  const showError = (message) => {
      toast.current.show({severity:'error', summary: 'Error Message', detail:message, life: 3000});
  }

  const onRestart = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to restart the SRT server? All Streams will be disconnected',
      icon: 'pi pi-exclamation-triangle',
      accept: () => acceptCommand('restart')
    });

  }; 

  const onStop = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to stop the SRT server?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => acceptCommand('stop')
    });

  }; 

  const onStart = (event) => {
    acceptCommand('start');
  }; 

  const acceptCommand = (command) => {
    putData(`${internalIp}/api/v1/srtserver/${command}`).then((data)=>{
      
      if(data.code === 0) {
        showSuccess(data.message);
        setRunning(command !== 'stop');
      }
      else if(data.code > 0){
        showWarn(data.message);
      }
      else {
        showError(data.message);
      }
      
    });
  }

  
  return (
        <div className="min-h-screen flex flex-column" >
          <Toast ref={toast} />
          <ConfirmPopup />
          <div className="surface-600 px-5 flex align-items-center justify-content-between relative lg:static border-round-top" >
            <div className="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-600 left-0 top-100 z-1 shadow-2 lg:shadow-none">
              
              <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                <li>
                  <Link to={`/servers`} style={{ textDecoration: 'none' }} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:surface-50 hover:surface-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                    <i className="pi pi-arrow-left mr-2"></i>
                    <Ripple />
                  </Link>
                </li>
                <li>
                  <Link to={`/srtserver`} style={{ textDecoration: 'none' }} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:surface-50 hover:surface-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                    <i className="pi pi-chart-line mr-2"></i>
                    <span>Home</span>
                    <Ripple />
                  </Link>
                </li>
                <li>
                  <Link to={`/srtserver/performance`}  style={{ textDecoration: 'none' }} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:surface-50 hover:surface-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                    <i className="pi pi-chart-bar mr-2"></i>
                    <span>Performance</span>
                    <Ripple />
                  </Link>
                </li>
                <li>
                  <Link to={`/srtserver/configuration`} style={{ textDecoration: 'none' }} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:surface-50 hover:surface-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                    <i className="pi pi-cog mr-2"></i>
                    <span>Configuration</span>
                    <Ripple />
                  </Link>
                </li>
                {/*
                <li>
                  <Link to={`/srtserver/events`}  style={{ textDecoration: 'none' }} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:surface-50 hover:surface-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                    <i className="pi pi-calendar mr-2"></i>
                    <span>Timeline</span>
                    <Ripple />
                  </Link>
                </li>
                */}
              </ul>
              
              <div className="text-lg text-50 font-medium">{name}</div>
          
              <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                <li className="p-2">
                  <Button icon="pi pi-refresh" className="p-button-rounded bg-green-500" onClick={(event)=>onRestart(event)} />
                </li>
                { (!running) ?
                  <li className="p-2">
                    <Button icon="pi pi-play" className="p-button-rounded bg-green-500" onClick={(event)=>onStart(event)} />
                  </li>
                  :
                  ''
                }
                { (running) ?
                  <li className="p-2">
                    <Button icon="pi pi-stop" className="p-button-rounded bg-orange-500" onClick={(event)=>onStop(event)}/>
                  </li>
                  :
                  ''
                }
                <li className="p-2"> 
                  <Button className={`p-button-rounded ${running ? 'bg-green-500':'bg-orange-500'}`} />
                </li>
              </ul>
            </div>


          </div>

          <div className="grid">
            <div className="col-12 lg:col-12">
              <div className="shadow-2 surface-card border-round p-3">
                <div className="mt-3">
                  <div className={classNames('layout-wrapper', { 'p-input-filled': true, 'layout-wrapper-dark': true })}>
                    <div className="layout-content">
                      <Routes>
                        <Route path={`/`} exact element={<Status ip={internalIp}/>} />
                        <Route path={`/performance`} exact element={<Performance ip={internalIp} />} />
                        <Route path={`/configuration`} exact element={<Config ip={internalIp} />} />
                        {/*<Route path={`/events`} exact element={<Events ip={internalIp} />} />*/}
                      </Routes>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
}

export default SrtServer;
