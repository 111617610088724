import React, { useState, useEffect, useContext, useRef } from 'react';

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Link } from 'react-router-dom';

import { DbStreamsMap } from '../db/streams';
import { DbAgentStreams } from '../db/agents';
import { DbStreamDestroy, DbStreamDisabled } from '../db/streams';
import { DatacastAPIDestroyStreams, DatacastAPIPublicStreams } from '../db/datacast';
import { GetBackupOptions } from '../common/common';
import { DatacastContext } from '../context/datacastcloud';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';

const Streams = ({agent}) => {
 
    const [refreshKey, setRefreshKey] = useState(0);
    const [ datacastProperties ] = useContext(DatacastContext);
    const [ streams, setStreams ] = useState([]);
    const [ streamsMap, setStreamsMap ] = useState({});
    const [ publicStreams, setPublicStreams ] = useState({});
    const [ serverOptions, setServerOptions ] = useState([]);

    const toast = useRef(null);

    useEffect(() => {

        const GetData = async (datacastProperties) => {

            const _serverOptions = await GetBackupOptions(datacastProperties);
            setServerOptions(_serverOptions);

            const _publicStreams = await DatacastAPIPublicStreams(datacastProperties.ip);
            setPublicStreams(_publicStreams);
            
                
            const _map = await DbStreamsMap(datacastProperties.userIp);
            setStreamsMap(_map);

            const _agent = await DbAgentStreams(datacastProperties.userIp, agent.uuid);
            
            for(let _stream of _agent.streams) {
                const src = _map[_stream.uuid];
                _stream.destinations = src.destinations.length;
            }
            setStreams(_agent.streams);

        }

        GetData(datacastProperties).catch((error)=>{
            console.error(error);
        })


    },[agent, datacastProperties, refreshKey]); 


    const getLabel = (array, value) => {

        for( let element of array) {
            if(element.value === value) {
                if(element.server) {
                    return element.server.label;
                }
                else {
                    return element.label;
                }
                
            }
        } 

        return "unknown";
    }

    const serversBodyTemplate = (rowData) => {
        return (
            `${getLabel(serverOptions, rowData.primaryServer)} ${(rowData.backupServer !== null && rowData.backupServer.length !== 0)? `, ${getLabel(serverOptions, rowData.backupServer)}`:''}`
        );
    }

    const sourceBodyTemplate = (rowData) => {
        
        //If it is the destination you need to look up the name.
        if(rowData.type === 'DESTINATION') {
            return (
                `${streamsMap[rowData.uuid] ? streamsMap[rowData.uuid].name : rowData.uuid}`
            );
        }
        else {
            return (
                rowData.name
            )
        }
    }

    const restartStream = (rowData) => {
            confirmPopup({
                message: 'Are you sure you want to restart this stream. It will return to the primary?',
                icon: 'pi pi-exclamation-triangle',
                accept: ()=>acceptRestart(rowData.id),
                reject: ()=>reject("Restart Stream", "Restart stream canceled")
            });
    }

    const acceptRestart = (streamId) => {

        DbStreamDisabled(datacastProperties.userIp, streamId, 2).then((result) => {
            if(result) {

                if(result.error === 0) {
                    setRefreshKey(oldKey => oldKey +1);
                    showResult('success', 'Stream Restart', "Stream restart message sent");
                }
            }

        }).catch((error) => {
            console.error(error);
        });
       
    }

    const deleteStream = (event, agent, rowData) => {

        if(rowData.type === 'SOURCE' && publicStreams[rowData.uuid]) {
            showResult('error', 'Permissions Error', 'Stream must be removed from all public access groups first');
        }
        else if(rowData.type === 'SOURCE' && rowData.destinations !== 0) {
            showResult('error', 'Permissions Error', 'All destination streams must be removed before the source');
        }
        else {
            const streamsToDelete = [];
            streamsToDelete.push({
                type:rowData.type.toLowerCase(),
                uuid:rowData.uuid,
                server:rowData.primaryServer,
                agent:agent.uuid
            });

            if(rowData.backupServer.length > 0) {
                streamsToDelete.push({
                    type:rowData.type.toLowerCase(),
                    uuid:rowData.uuid,
                    server:rowData.backupServer,
                    agent:agent.uuid
                });
            }
            confirmPopup({
                target: event.currentTarget,
                message: 'Are you sure you want to delete this stream?',
                icon: 'pi pi-exclamation-triangle',
                accept: ()=>accept(rowData.id, streamsToDelete),
                reject: ()=>reject("Delete Stream", "Stream delete canceled")
            });
        }
        
    }

    const accept = (streamId, streamsToDelete) => {

        streamsToDelete.forEach((stream)=>{
            //console.log(datacastProperties.ip,stream.type, stream.uuid, stream.server, stream.agent );
            DatacastAPIDestroyStreams(datacastProperties.ip,stream.type, stream.uuid, stream.server, stream.agent ).then((result)=> {

                if(result.error !== 0) {
                    showResult('error', 'Delete stream failed', result.message);
                }
                else {
                    showResult('success', 'Delete Stream', result.message);
                }
                
                setRefreshKey(oldKey => oldKey +1)
            }).catch((err)=>console.error(err));
        });

       
        DbStreamDestroy(datacastProperties.userIp, streamId).then((result) => {
            if(result) {

                if(result.error !== 0) {
                    showResult('error', 'Delete stream failed', result.message);
                }

                showResult('success', 'Delete Stream', result.message);
                setRefreshKey(oldKey => oldKey +1)
            }

        }).catch((error) => {
            console.error(error);
        });
    }

    const reject = (title, message) => {
        showResult('warn', title, message);
    }


    const editBodyTemplate = (rowData) => {

        return(
            <>
                {
                    (((rowData.type === 'DESTINATION') && (datacastProperties.agentGroup.editStream === 1)) ||
                    ((rowData.type === 'SOURCE') && (datacastProperties.agentGroup.editSource === 1)))?
                    <>
                    <Button icon="pi pi-refresh" 
                            className="p-button-rounded p-button-success p-button-text" 
                            disabled={parseFloat(agent.version) < 1.1}
                            onClick={()=>restartStream(rowData)}
                    />
                    <Link to={`/editstream`} state={{agent:agent, stream:rowData, from:'streams'}} style={{ textDecoration: 'none' }}>
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary p-button-text" />
                    </Link>
                    </>
                    :
                    <Link to={`/editstream`} state={{agent:agent, stream:rowData, from:'streams'}} style={{ textDecoration: 'none' }}>
                        <Button icon="pi pi-eye" className="p-button-rounded p-button-success p-button-text" />
                    </Link>
                }
                {
                    (((rowData.type === 'DESTINATION') && (datacastProperties.agentGroup.destroyStream === 1)) || 
                    ((rowData.type === 'SOURCE') && (datacastProperties.agentGroup.destroySource === 1)) )?
                    <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={(e)=>deleteStream(e, agent, rowData)}/>
                    :
                    <></>
                }
                
            </>
        );
    }

    const onCellEditComplete = (streamId, newValue) => {

        DbStreamDisabled(datacastProperties.userIp, streamId, (newValue)?0:1).then((result) => {
            if(result) {

                if(result.error === 0) {
                    setRefreshKey(oldKey => oldKey +1);
                    return newValue;
                }
            }

        }).catch((error) => {
            console.error(error);
        });
    }

    const enabledBodyTemplate = (rowData) => {
        return (
            <Button className={(rowData.disabled === 0)?'p-button-text p-button-success':'p-button-text p-button-danger'} 
                    icon={(rowData.disabled === 0)?'pi pi-check':((rowData.disabled === 2)?'pi pi-spin pi-spinner':'pi pi-times')}
                    onClick={(e)=>onCellEditComplete(rowData.id, rowData.disabled)} 
                    disabled={!(((rowData.type === 'DESTINATION') && (datacastProperties.agentGroup.editStream === 1)) || ((rowData.type === 'SOURCE') && (datacastProperties.agentGroup.editSource === 1)))} 
            />
        );
    }

    const showResult = (severity, summary, detail) => {
        toast.current.show({severity:severity, summary:summary, detail:detail, life: 3000});
    }

    return (
        <>
            <Toast ref={toast} />
            <ConfirmPopup />
            <div className='grid col-12'>
                <div className='col-12'>
                    <DataTable value={streams} editMode="cell" responsiveLayout="scroll" >
                        <Column field="source" header="Source" body={sourceBodyTemplate} ></Column>
                        <Column field="servers" header="Server(s)" body={serversBodyTemplate}></Column>
                        <Column field='type' header="Type"></Column>
                        <Column field='disabled' header="Enabled" body={(options)=>enabledBodyTemplate(options)} ></Column>
                        <Column field='Edit' body={editBodyTemplate} style={{ width: '10%' }}></Column>
                    </DataTable>
                </div>
                <div className={`align-content-center mt-2 ${((streams.length > 0 && agent.type === 'MAP') || datacastProperties.agentGroup.createStream === 0)?'hidden':''}`}>
                    <Button icon="pi pi-plus" className="p-button-rounded bg-blue-600"/>
                    <span className='ml-2 vertical-align-middle'>
                        <Link to="/addstream" state={{...agent}}>
                            Add Stream
                        </Link>
                    </span>
                </div>
            </div>
        </>            
    
    )
}

export default Streams;