import React, {useState, createContext}  from "react";

export const DatacastContext = createContext();

export const DatacastProvider = (props) => {
    const [datacastProperties, setDatacastProperties] = useState({
        ip:'',
        userIp:'',
        accessGroup:null,
        networkKey:null,
        networkName:'',
        admin:false,
        agentGroup:null,
        loggedIn:false,
        id:null,
        name:'',
        email:'',
        guiVersion:'',
        newAlerts:false,
        sshIp:undefined,
        session:null,
        portMin:undefined,
        portMax:undefined
    });

    return(
        <DatacastContext.Provider value={[datacastProperties, setDatacastProperties]}>
            {props.children}
        </DatacastContext.Provider>
    );
}