import React, { useEffect, useState, useContext, useRef } from 'react';

import { useLocation } from "react-router-dom";

import Chart from 'react-apexcharts';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Slider } from 'primereact/slider';
import { Button } from 'primereact/button';
import { plotOptions } from './chartoptions';
import { OverlayPanel } from 'primereact/overlaypanel';

import { getLocalTimeTicks } from '../utils/misc';
import { DbAgentsWithStreams, DbAgentStreams } from '../db/agents';
import { DbStreamsMap } from '../db/streams';
import { DatacastAPIGetPath, DatacastAPIGetStreamDropped, DatacastAPIGetAllStreamStats } from '../db/datacast';

import { DatacastContext } from '../context/datacastcloud';

import { formatUptime } from '../utils/misc';



const PathAnalysis = () => {
    const [selectedStreamTable, setSelectedStreamTable] = useState(null);


    const [ agents, setAgents] = useState([]);
    const [ selectedAgent, setSelectedAgent] = useState(null);
    const [ selectedSource, setSelectedSource] = useState(null);
    const [ selectedServer, setSelectedServer] = useState(null);

    const [ agentOptions, setAgentOptions] = useState([]);
    const [ sourceOptions, setSourceOptions] = useState([]);
    const [ serverOptions, setServerOptions] = useState([]);

    const [statData, setStatData] = useState([]);
    const [streamDrops, setStreamDrops] = useState([]);
    const [selectedDrop, setSelectedDrop] = useState(null);
    const [streams, setStreams] = useState([]);

    const [ datacastProperties ] = useContext(DatacastContext);

    const location = useLocation();

    const agent = location.state?.Agent;
    const source = location.state?.Source;
    const server = location.state?.Server;

    const [selectedTime, setSelectedTime] = useState(new Date());
    const [currentDate, setCurrentDate] = useState(new Date());
    
    const op = useRef(null);
  
  
    const timeIntervalOptions = [
      {label: '10 MIN', value:5},
      {label: '20 MIN', value:10},
      {label: '30 MIN', value:15},
      {label: '1 HR', value:30}
    ];
  
    const [selectedIntervalOption, setSelectedIntervalOption] = useState(timeIntervalOptions[0].value);
    const [sliderProperties, setSliderProperties] = useState({step:1, max:Math.floor(24*60 / timeIntervalOptions[0].value)});
    const [sliderValue, setSliderValue] = useState(Math.floor(24*60 / timeIntervalOptions[0].value) - 1);

    const getPathInfo = async (inAgent, inSource, inServer, time) => {

      const streamsMap = await DbStreamsMap(datacastProperties.userIp, 'SOURCE');
      const _agents = await DbAgentsWithStreams(datacastProperties.userIp);
      let _filteredAgents = [];

      //Get all the agents that have destinations.
      for(let agentT of _agents) {
        if (!datacastProperties.agentMap || (datacastProperties.agentMap && datacastProperties.agentMap[agentT.id])) {

          //As long as there is at least 1 destionation.
          for(let stream of agentT.streams) {
            if(stream.type === 'DESTINATION') {
              _filteredAgents.push(agentT);
              break;
            }
          }
        }
      }
      
      setAgents(_agents);
      setAgentOptions(_filteredAgents);

      //If an agent has been specified then
      //find the correct source.
      let _agent = null;
      let _found_source = null;
      if(inAgent) {
        _agent = _filteredAgents.find(element => element.uuid === inAgent);
        setSelectedAgent(_agent.uuid);
      
        if(_agent) {
          const _sourceOptions = getAvailableSources(_agent.streams, streamsMap);
          setSourceOptions(_sourceOptions);

          //If there is an input source then select it.
          if(inSource) {
            _found_source = _sourceOptions.find((e)=>e.value === inSource);
          }
        }
      }

      if(_agent && _found_source) {
        setSelectedSource(_found_source.value);

        const _serverOptions = (_found_source.p2p)?[{label:'None',value:''}, ...getAvailableServers(_agent.streams, _found_source.value)]:getAvailableServers(_agent.streams, _found_source.value);
        
        setServerOptions(_serverOptions);

        let _found_server = _serverOptions.find((e)=>e.value === inServer);
        if(_found_server) {
          setSelectedServer(_found_server.value);
        }
        else {
          _found_server = _serverOptions[0];
          setSelectedServer(_found_server.value);
        }


        getPathsAndSelect(_found_source.value, _agent.uuid, _found_server.value, time).then((result)=>{

        }).catch((err)=>console.error(err));
      }

    }

    useEffect(() => {
      
      const initialTime = new Date(new Date().getTime() - selectedIntervalOption*60000);

      setCurrentDate(new Date());
      setSelectedTime(initialTime);
      
      //*Get all agents.
      getPathInfo(agent, source, server, initialTime).then((result)=>{

      }).catch((err)=>{console.error(err)});


    },[]); //Run each time id changes.

    const onSelectedTimeChange = (value) => {
      
      setSelectedDrop(value);
      if(value && value.StatDate) {
        const newDate = new Date(value.StatDate);
        setSelectedTime(newDate);
        
        setSliderValue((newDate.getTime() - (currentDate.getTime() - 24*3600*1000))/(selectedIntervalOption*60000));

        //Select data to plot
        if(selectedServer && selectedStreamTable) {
          DatacastAPIGetAllStreamStats(datacastProperties.ip, selectedStreamTable.StreamID, newDate, selectedIntervalOption).then((result) => {
            
            setStatData(result);
          }).catch((error) => {
              console.error(error);
              return [];
          });
        }
      
      }
    }
  
    const onIntervalChange = (value) => {
  
      setSelectedIntervalOption(value);
  
      let ratio = null;
  
      if (value > selectedIntervalOption) {
        ratio = value / selectedIntervalOption;
      }
      else {
        ratio = selectedIntervalOption / value;
      }
  
      const newValue = (value > selectedIntervalOption) ? Math.floor(sliderValue / ratio): Math.floor(sliderValue * ratio);
  
      //Convert slider value
      setSliderProperties({step:1, max:Math.floor(24*60 / value)});
      setSliderValue(newValue);
  
      updateData({interval:value});
    }

    const onSliderEnd = (value) => {
      
      const _selectedTime = (currentDate.getTime() - 24*3600*1000) + value*selectedIntervalOption*60000;
      updateData({date:new Date(_selectedTime)});
    }

    const onSliderChange = (value) => {
      
      const _selectedTime = (currentDate.getTime() - 24*3600*1000) + value*selectedIntervalOption*60000;
      setSliderValue(value);

      setSelectedTime(new Date(_selectedTime));
    }

    const dropTemplate = (rowData) => {
      
      return (getLocalTimeTicks(new Date(rowData.StatDate)));
    }

    const updateData = ({date=null, interval=null}) => {
      const _date = date?date:selectedTime;
      const _interval = interval?interval:selectedIntervalOption;

      DatacastAPIGetAllStreamStats(datacastProperties.ip, selectedStreamTable.StreamID, _date, _interval).then((result) => {
        setStatData(result);
      }).catch((error) => {
        console.error(error);
        return [];
      });
    }


    const onSelectionChange = (rowData, time) => {
      
      setSelectedStreamTable(rowData);

      DatacastAPIGetStreamDropped(datacastProperties.ip, rowData.StreamID).then((result) => {
        setStreamDrops(result);
      }).catch((error) => {
          console.error(error);
      });

      const _selectedTime = (time)?time:selectedTime;

      DatacastAPIGetAllStreamStats(datacastProperties.ip, rowData.StreamID, _selectedTime, selectedIntervalOption).then((result) => {
        setStatData(result);
      }).catch((error) => {
          console.error(error);
      });
      

    }

    //Get available sources for a particular agent.
    const getAvailableSources = (streams, map) => {
      const _sourceOptions = [];

      //Get all of the sources of possible destinations.
      for(let stream of streams) {

        if(stream.type === 'DESTINATION') {
          _sourceOptions.push({
            value:stream.uuid,
            label:stream.name,
            p2p:(stream.port >= 65500) //Need to remove this eventually.
          })

          //Add the backup stream if it is specified.
          if(stream.source) {
            _sourceOptions.push({
              value:stream.source,
              label:map[stream.source].name,
              p2p: false
            })
          }
        }
      }

      return _sourceOptions;
    }

    //Get available servers for a particular stream
    const getAvailableServers = (agentStreams, source) => {

      const _destStream = agentStreams.find((e)=>(e.type === 'DESTINATION' && (e.uuid === source || e.source === source)));
      if(_destStream) {
        let _options = null;
        if(_destStream.backupServer) {
            _options = [
              {
                label:"PRIMARY",
                value:_destStream.primaryServer
              },
              {
                label:"BACKUP",
                value:_destStream.backupServer
              }
            ]
        }
        else {
          _options = [
            {
              label:"PRIMARY",
              value:_destStream.primaryServer
            }
          ]
        }

        return _options;
      }

      return [];
    }

    const getPathsAndSelect = async (source, agent, server, time) => {
      const _streams = await DatacastAPIGetPath(datacastProperties.ip, source, agent, server);
      
      setStreams(_streams);
      if(_streams && _streams.length > 0) {
        let _selected = null;
        if(!selectedStreamTable) {
          const find_stream = streams.find((e)=>e.StreamID === selectedStreamTable.StreamID);
          if(find_stream) {
            _selected = find_stream;
          }
        }
        
        //Select the first one if you couldn't find any others.
        if(!_selected && _streams && _streams.length > 0) {
          _selected = _streams[0];
          
        }

        onSelectionChange(_selected, time);

      }
      else {
        setSelectedStreamTable(null);
        setStatData([]);
      }
    }

    const onAgentChanged = (value) =>{
      
      setSelectedAgent(value);
      DbAgentStreams(datacastProperties.userIp, value).then((result) => {
        
        if(result) {          
          const _agent = result;
          let _source = null;
          let _server = null;

          const _sourceOptions = getAvailableSources(_agent.streams);
          if(_sourceOptions && _sourceOptions.length > 0) {
            _source = _sourceOptions[0];
          }

          setSourceOptions(_sourceOptions);

          //If a stream has already been selected. See if you can find it again.
          //Other select the first one.
          if(selectedSource) {
            const _found_source = _sourceOptions.find((e)=>e.value === selectedSource);
            if(_found_source) {
              _source = _found_source;
            }
          }

          setSelectedSource(_source.value); //Set the selected source.

          const _serverOptions = getAvailableServers(_agent.streams, _source.value);
          setServerOptions(_serverOptions);

          _server = _serverOptions[0];
          if(selectedServer) {
            const _found_server = _serverOptions.find((e)=>e.value === selectedServer);
            if(_found_server) {
              _server = _found_server;
            }
          }

          setSelectedServer(_server.value);
          
          //If we have everything last select it.
          if(_agent && _source && _server) {
            getPathsAndSelect(_source.value, _agent.uuid, _server.value).then((result)=>{
              
            }).catch((err)=>console.error(err));
          }
          
        }

      }).catch((error) => {
          console.error(error);
      });
    }

    const onStreamChanged = (value) => {
      setSelectedSource(value);

      
      const _sourceOption = sourceOptions.find(element => element.value === value);

      let server = selectedServer;
      if(_sourceOption.p2p) {
        setServerOptions([{label:'None',value:''}]);
        setSelectedServer('');
        server = '';
      }
      else {
        
        const _agent = agents.find(element => element.uuid === selectedAgent);
        const _serverOptions = getAvailableServers(_agent.streams, _sourceOption.value);

        if(_serverOptions.length > 0) {
          setServerOptions(_serverOptions);

          
          let _server = _serverOptions[0];
          if(selectedServer) {
            const _found_server = _serverOptions.find((e)=>e.value === selectedServer);
            if(_found_server) {
              _server = _found_server;
            }
          }

          setSelectedServer(_server.value);
        }
      }

      getPathsAndSelect( _sourceOption.value, selectedAgent, server).then((result)=>{
        
      }).catch((err)=>console.error(err));
    }

    const onServerChanged = (value) => {
      setSelectedServer(value);

      //Only store StreamID but need actualy source 
     const _sourceOption = sourceOptions.find(element => element.value === selectedSource);

      if(_sourceOption) {
        
        getPathsAndSelect( _sourceOption.value, selectedAgent, value).then((result)=>{
        
        }).catch((err)=>console.error(err));
        
      }
    }


    const droppedTemplate = (rowData) => {
      const valid = rowData.Packets && rowData.Packets > 0;
      return (`${valid?rowData.Dropped:'N/A'} (${valid ? `${(rowData.Dropped / rowData.Packets).toFixed(3)*100}%` : 'N/A'})`);
    }
    
    const lostTemplate = (rowData) => {
      const valid = rowData.Packets && rowData.Packets > 0;
      return (`${valid?rowData.RcvLost:'N/A'} (${valid ? `${(rowData.RcvLost / rowData.Packets).toFixed(3)*100}%` : 'N/A'})`);
    }
    
    const upSecsTemplate = (rowData) => {
      return formatUptime(Math.floor(rowData.UpSecs));
    }

    const retryTemplate = (rowData) => {
      const valid = rowData.Packets && rowData.Packets > 0;
      return (`${valid?rowData.Retry:'N/A'} (${valid ? `${(rowData.Retry / rowData.Packets).toFixed(3)*100}%` : 'N/A'})`);
    }

    const _GetSourceAgent = (agents, srcId) => {
      for(let agent of agents) {
        if (agent) {
          for(let stream of agent.streams) {
            if (stream && stream.uuid == srcId) {
              return agent;
            }
          }
        }
      }

      return null;
    }

    const typeTemplate = (rowData) => {
      
      //If it is not peer to peer.
      if(rowData.SrcName && rowData.DstName) {
        if(rowData.Type === 'destination') {
          return (`DST (${rowData.SrcName})`);
        }
        else if(rowData.Type === 'source') {
          return (`SRC (${rowData.DstName})`);
        }
        else {
          return (`LINK (${rowData.SrcName} - ${rowData.DstName})`);
        }
      }
      else {
        
        if(selectedAgent && selectedSource) {
          const dstAgent = agents.find((e)=>e.uuid === selectedAgent);
          const srcAgent = _GetSourceAgent(agents, selectedSource);
          

          if(rowData.Type === 'destination') {
            return (`DST (${dstAgent.name})`);
          }
          else if(rowData.Type === 'source') {
            return (`SRC (${srcAgent.name})`);
          }
        }
      }

    }

    const rowClass = (rowData) => {
      return `${(rowData.Packets === null || rowData.Packets === 0)?'surface-50 text-100':''}`;
    }

    const showSelection = (rowData) => {
      return true;//!(rowData.Packets === null || rowData.Packets === 0)
    }

    const moveSlide = (step) => {
      const newValue = sliderValue + step;

      setSliderValue(newValue);

      const newDateTime = (currentDate.getTime() - 24*3600*1000) + newValue * (selectedIntervalOption*60000);
      const newDate = new Date(newDateTime);

      setSelectedTime(newDate);
      updateData({date:newDate});
    }

    return(
      <>
        <div className='flex align-items-center' style={{backgroundColor:"rgb(64,64,64)"}}>
          <div className='p-3 text-white font-bold'>Agent</div>
          <Dropdown className="border-round-md" optionLabel="name" optionValue="uuid" value={selectedAgent} options={agentOptions} onChange={(e)=>onAgentChanged(e.value)} placeholder="Select an Agent"/>
          <div className='p-3 text-white font-bold'>Source</div>
          <Dropdown className="border-round-md" optionLabel="label" optionValue="value" value={selectedSource} options={sourceOptions} onChange={(e) => onStreamChanged(e.value)} placeholder="Select a Stream"/>
          <div className='p-3 text-white font-bold'>Server</div>
          <Dropdown className="border-round-md" optionLabel="label" optionValue="value" value={selectedServer} options={serverOptions} onChange={(e) => onServerChanged(e.value)} placeholder="Select Server"/>
          
        </div>
        <div className='w-full'>
          <div className='flex align-items-center m-2 justify-content-between'>
            <div className='flex align-items-center'>
              <div className='text-xl font-medium'>Path Analysis</div>
              {/*<Button icon="pi pi-chart-line" label="Plot" className="mx-2 p-button-rounded"  onClick={(e)=>updateData({date:null, interval:null})} />*/}
            </div>
            <div>
              {getLocalTimeTicks(selectedTime)}
            </div>
            <Dropdown options={timeIntervalOptions} optionLabel="label" optionValue="value" value={selectedIntervalOption} onChange={(e)=>onIntervalChange(e.value)} />
          </div>
          <div className="w-full">
            <Chart options={plotOptions} series={statData} type="line" height={300} />
          </div>

          <div className='flex align-content-center  w-full border-dashed border-1 surface-border surface-100 border-round-md'>
            <div className='flex w-2 m-3 align-items-center'>
              <Button icon="pi pi-table" className="border-round-md" style={{backgroundColor:"rgb(64,64,64)"}}  label="Select Drop" onClick={(e) => op.current.toggle(e)} />
              <OverlayPanel ref={op} id="overlay_panel" showCloseIcon >
                <DataTable value={streamDrops} rows={10} selection={selectedDrop} onSelectionChange={(e)=>onSelectedTimeChange(e.value)} paginator>
                  <Column selectionMode="single" headerStyle={{width:'3em'}} />
                  <Column field="StatDate" header="Time" body={dropTemplate} sortable />
                  <Column field="Dropped" header="Dropped Packets"  sortable />
                </DataTable>

              </OverlayPanel>
            </div>
            <div className='flex w-10 align-items-center m-3'>
              <div className='flex align-items-center'>
                {getLocalTimeTicks(new Date(currentDate.getTime() - 24*3600*1000))}
                <Button  className='p-button-text' icon="pi pi-chevron-left" onClick={()=>moveSlide(-1)}/>
              </div>
              <Slider className='w-full' {...sliderProperties} value={sliderValue} onChange={(e)=>onSliderChange(e.value)} onSlideEnd={(e)=>onSliderEnd(e.value)} />
              <div className='flex align-items-center'>
                <Button className='p-button-text' icon="pi pi-chevron-right" onClick={()=>moveSlide(1)} />
                {getLocalTimeTicks(currentDate)}
              </div>
            </div>
          </div>
          
          <div className='mt-3'>
            <DataTable value={streams} selection={selectedStreamTable} dataKey="StreamID" 
                      rowClassName={rowClass}
                      showSelectionElement={showSelection}
                      onSelectionChange={(e)=>onSelectionChange(e.value)} 
                      responsiveLayout="scroll" >
              <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
              <Column field="Type" body={typeTemplate} header="Type"></Column>
              <Column field="Bitrate" header="Bitrate (kbps)"></Column>
              <Column field="Packets" header="Total"></Column>
              <Column field="Lost" body={lostTemplate} header="Lost"></Column>
              <Column field="Retry" body={retryTemplate} header="Retry"></Column>
              <Column field="Dropped" body={droppedTemplate} header="Dropped"></Column>
              <Column field="upSecs" body={upSecsTemplate} header="Up Time"></Column>
            </DataTable>
          </div>
        </div>
      </>
    )
}

export default PathAnalysis;