

export const DbAgentGroups = async (db=null) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/agentgroups`, requestOptions);

    if(response.ok) {
        const data = await response.json();
        if(data.error === 0)    {
            return data.data;
        }
    }
    

    return [];
}



export const DbAgentGroup= async (db, id) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/agentgroups/${id}`, requestOptions);
    if(response.ok) {
        const data = await response.json();
        if(data.error === 0) {
            return data.data;
        }
    }

    return null;
}

export const DbAgentGroupSet = async (db, agent) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(agent)
    };

    const response = await fetch(`/api/v1/mistiq/agentgroups`, requestOptions);
    if(response.ok) {
        const data = await response.json();
        if(data.error === 0) {
            return data.data;
        }
    }

    return null;
}

export const DbAgentGroupAgentsSet = async (db, uuid, agents) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(agents)
    };

    const response = await fetch(`/api/v1/mistiq/agentgroups/${uuid}`, requestOptions);
    if(response.ok) {
        const data = await response.json();
        if(data.error === 0) {
            return true;
        }
    }

    return false;
}

export const DbAgentGroupAdd = async (db, agent) => {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(agent)
    };

    const response = await fetch(`/api/v1/mistiq/agentgroups/add`, requestOptions);
    if(response.ok) {
        const data = await response.json();
        if(data.error === 0) {
            return data.data;
        }
    }


    return null;
}


export const DbAgentGroupCreate = async (db, agent) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(agent)
    };

    const response = await fetch(`/api/v1/mistiq/agentgroups/create`, requestOptions);

    const data = await response.json();

    return data;
}

export const DbAgentGroupDestroy = async (db, uuid) => {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/agentgroups/${uuid}`, requestOptions);
    const data = await response.json();

    return data;
}