import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';

import { DatacastContext } from '../context/datacastcloud';
import { DatacastAPIGetPaths, DatacastAPIGetSourcesMap } from '../db/datacast';
import { DbAgents } from '../db/agents';
import { DbStreams } from '../db/streams';
import { DbAlerts } from '../db/alerts';
import { GetStreamInformation, GetServerOptions } from '../common/common';
import { useInterval } from '../hooks/useinterval';


const Status = () => {

    const initialStatus = {
        success:{
            value:true,
            count:0,
        },
        warning:{
            value:true,
            count:0
        },
        error:{
            value:true,
            count:0
        },
        information:{
            value:true,
            count:0
        }
    }

    const [delay] = useState(5000);

    const [ globalFilter, setGlobalFilter] = useState(null);
    const [ statuses, setStatuses] = useState(initialStatus);
    const [ sources, setSources] = useState({});

    const [paths, setPaths] = useState([]);

    const [ datacastProperties ] = useContext(DatacastContext);

    const statusMap = {
        success:{
            className:'bg-green-600 border-green-600',
        },
        warning:{
            className:'bg-yellow-600 border-yellow-600'
        },
        error:{
            className:'bg-red-600 border-red-600'
        },
        information:{
            className:'bg-gray-200 border-gray-200'
        }
    }

 

    const initGlobalFilter = () => {
        setGlobalFilter({
            'SourceT':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            'AgentT':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            'Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            'Status': {operator: FilterOperator.OR, constraints: [{value: 'success', matchMode: FilterMatchMode.EQUALS}, {value: 'warning', matchMode: FilterMatchMode.EQUALS},
                                                                  {value: 'error', matchMode: FilterMatchMode.EQUALS}, {value: 'information', matchMode: FilterMatchMode.EQUALS}]}
        });

    }

    const getAgentsandStreams = async() => {


        const _servers =  await GetServerOptions(datacastProperties);
        const _agents = await DbAgents(datacastProperties.userIp);
        const _agentObj = {};
        const _agentObjId = {};
        const _sourcesObj = {};

        if(_agents) {
            for(let agent of _agents) {
                _agentObj[agent.uuid] = agent;
                _agentObjId[agent.id] = agent;
            }
        }

        
        const _sources = await DbStreams(datacastProperties.userIp, 'source');
        if(_sources) {
            for(let source of _sources) {
                _sourcesObj[source.uuid] = source;
            }
        }

        const _alerts = await DbAlerts(datacastProperties.userIp, datacastProperties.id);
        const _destinations = await DbStreams(datacastProperties.userIp, 'destination');
        const _paths = await DatacastAPIGetPaths(datacastProperties.ip, datacastProperties.networkKey);
        const dcastSources = await DatacastAPIGetSourcesMap(datacastProperties.ip, datacastProperties.accessGroup);

        //Find Path if available. A path will be available if it has already been active on the datacast cloud.
        for (let _dest of _destinations) {
            const _agent = _agentObjId[_dest.agentId];

            const {primary, backup} = GetStreamInformation(_dest, _servers);

            const _path = _paths.find(p=>((p.Agent === _agent.uuid) && //Agent
                ( !p.Uuid || //P2P. The server uuid will be NULL if it is in peer to peer mode.
                ((primary && primary.server && p.Uuid === primary.server.uuid && p.Source === primary.source) || (backup && backup.server && p.Uuid === backup.server.uuid && p.Source === backup.source))) //Server id
                && (p.Active === 1))); //Check if active.

            //If there is path designated on the datacast cloud.
            if(_path) {
                _dest.path = _path;
                if(_path.Name) {
                    _dest.Server = _path.Name;
                }
                else {
                    _dest.Server = 'P2P';
                    _dest.path.DSTATE = 1;
                    _dest.path.SSTATE = 1;
                }
                
                _dest.Enabled = _path.Enabled;
            }
            else {
                //Check for any path even if it is not active
                const _pathPrimary = _paths.find(p=>((p.Agent === _agent.uuid) && 
                                            (!p.Uuid || p.Uuid === primary.server.uuid) && 
                                            (p.Source === primary.source)));

                if (_pathPrimary) {
                    _dest.path = _pathPrimary;
                    _dest.Server = null;
                    _dest.Enabled = _pathPrimary.Enabled;
                }
            }

            //The alerts are reported from datacast cloud with respect to the PATH and the path
            //is defined by the destation agent, the server feeding the destination, and the source uuid.
            //Each alert also contains a streamType. This is used to show where on the path the error occured. 
            
            const hasAlert = _alerts.reduce((sum, e)=>{
                //Alert types
                //-- 0 Stream started
                //-- 1 Stream stopped
                //-- 2 Availability
                //-- 3 No data 
                if(_dest.path) {

                    //If the type is an availabiliy or no data alert.
                    //And it has the same source
                    //And it has the same desination agent.
                    //I am not sure how this will work with primary/backup...
                    //if((e.type === 2) && e.source === _dest.path.Source &&   _agent.uuid === e.agent) {
                    if((e.type === 2) && 
                        (
                            ((((primary && (e.source === primary.source)) || (backup && (e.source === backup.source)))&& e.streamType === 'SOURCE') || 
                            e.source === _dest.path.Source) &&  _agent.uuid === e.agent )
                        ) {
                        return sum + 1;
                    }
                    else {
                        return sum;
                    }
                }

            }, 0);

            _dest.hasAlert = hasAlert;
        }
        
        const _statuses = {...statuses};
        
        _statuses.success.count = 0;
        _statuses.warning.count = 0;
        _statuses.error.count = 0;
        _statuses.information.count = 0;

        //Go through all of the destinations on the Cortex.
        let filteredPaths = [];
        for(let destItem of _destinations) {

            //If the user has access to all agents
            //of if the user is part of an agent group and only has access to specific units.
            const cortexAgent = _agentObjId[destItem.agentId];
            if(!datacastProperties.agentMap || (datacastProperties.agentMap && cortexAgent && datacastProperties.agentMap[cortexAgent.id])) {
                //Set global status.
                const path = destItem.path;
                
                if ( path ) {
                    //hasAlert can be cleared using alerts.
                    if(path.Active === 1) {
                        if(destItem.hasAlert > 0) {
                            destItem.Status = 'error';
                        }
                        else {
                            if(path.Packets !== null) {
                                if(path.Enabled === path.NumStreams) {
                                    destItem.Status = 'success';
    
                                }
                                else {
                                    if(path.Enabled !== 0) {
                                        if(path.Enabled > path.NumStreams) {
                                            destItem.Status = 'warning';
                                        }
                                        else {
                                            destItem.Status = 'information';
                                        }
                                    }
                                    else {
                                        destItem.Status = 'information';
                                    }
                                }
                            }
                            else {
                                destItem.Status = 'information';
                            }
                        }
                    }
                    else {
                        destItem.Status = 'information';
                    }
                }
                else {
                    // What if it is disabled.
                    destItem.Status = 'information';
                }
    
                _statuses[destItem.Status].count++;

                //Assign agent id;
                destItem.AgentT = _agentObjId[destItem.agentId].name;

                let uuid = (destItem.path)? destItem.path.Source : destItem.uuid;
                
                
                const _dSource = dcastSources[uuid];
                

                if(dcastSources && _dSource) {
                    destItem.Active = destItem.Active;
                }
                else {
                    destItem.Active = 0;
                }

                if(_sourcesObj && _sourcesObj[uuid]) {
                    const foundAgent = _agents.find((e)=>e.id === _sourcesObj[uuid].agentId);
                
                    destItem.SourceT = (foundAgent)?`${_sourcesObj[uuid].name} (${foundAgent.name}) `:_sourcesObj[uuid].name;
                    destItem.SourceName = _sourcesObj[uuid].name;
                    destItem.AgentObj = _agentObjId[destItem.agentId]; //Point it to the agent id.
                    destItem.SourceAgent = foundAgent;
                    
                }
                else {
                    destItem.SourceT =  path?path.Source:'Unkown';
                    destItem.SourceName = path?path.Source:'Unkown';
                }

                
                filteredPaths.push(destItem);
                
            }
        }

        filteredPaths.sort((a, b) => {
            const nameA = (a.path)?a.path.Source.replace('-',''): a.uuid.replace('-',''); // ignore upper and lowercase
            const nameB = (b.path)?b.path.Source.replace('-',''): b.uuid.replace('-',''); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            
            // names must be equal
            return 0;
        });

        

        //Alternate rows.
        let even = false;
        let lastSource = '';
        for (let path of filteredPaths) {
            const uuid = (path.path)?path.path.Source:path.uuid;
            if (uuid !== lastSource) {
                lastSource = uuid;
                even = !even;
            }

            path.even = even;
        }


        setSources(_sourcesObj);
        setPaths(filteredPaths);
        setStatuses(_statuses);
        
    }

    

    useEffect(() => {

        initGlobalFilter();

            getAgentsandStreams().then((result)=> {


        }).catch((err)=>console.error(err));
        
    
    },[datacastProperties]); 

    useInterval(() => {

        getAgentsandStreams().then((result)=> {


        }).catch((err)=>console.error(err));

    }, delay);

    const statusTemplate = (rowData, displayClass) => {
        if(rowData.path && rowData.path.Active === 1) {
            return (
                <Link to="/analysis/paths" state={{Source:rowData.path.Source, Agent:rowData.path.Agent, Server:rowData.path.Uuid}} style={{ textDecoration: 'none' }}>
                    <div className='flex justify-content-start align-items-center'>
                        <Button label={rowData.Status === 'error'?rowData.hasAlert:''} className={`p-button-rounded p-button-sm ${displayClass}`} />
                        <span className='ml-2 text-base'>{`${(rowData.path.Bitrate)?rowData.path.Bitrate.toFixed(2):'0'}`}</span>
                    </div>
                </Link>
            )
        }
        else {
            //There is a path but it is not active.
            if(rowData.path) {
                return (
                    <Link to="/analysis/paths" state={{Source:rowData.path.Source, Agent:rowData.path.Agent, Server:rowData.path.Uuid}} style={{ textDecoration: 'none' }}>
                        <div className='flex justify-content-start align-items-center'>
                            <Button label={rowData.Status === 'error'?rowData.Dropped:''} className={`p-button-rounded p-button-sm ${displayClass}`} />
                            <span className='ml-2 text-base'>{`${(rowData.Bitrate)?rowData.Bitrate.toFixed(2):'0'}`}</span>
                        </div>
                    </Link>
                )
            }
            else {
                //There is no path it has not been activated yet.
                return (
                    <Link to="/analysis/paths" style={{ textDecoration: 'none' }}>
                        <div className='flex justify-content-start align-items-center'>
                            <Button label={rowData.Status === 'error'?rowData.Dropped:''} className={`p-button-rounded p-button-sm ${displayClass}`} />
                            <span className='ml-2 text-base'>{`${(rowData.Bitrate)?rowData.Bitrate.toFixed(2):'0'}`}</span>
                        </div>
                    </Link>
                )
            }
        }
    }

    const statusBodyTemplate = (rowData) => {
        //If there are no alerts and we have data.
        return statusTemplate(rowData, statusMap[rowData.Status].className);
    }


    const selectAll = () =>{
        initGlobalFilter();

        const _statuses = {...statuses};

        _statuses.success.value = true;
        _statuses.warning.value = true;
        _statuses.error.value = true;
        _statuses.information.value = true;

        setStatuses(_statuses);

    }

    const selectState = (value, field) => {
        const newValues = {...statuses};
        newValues[field].value = value;

        let filterValues = [];
        for (const [key, status] of Object.entries(newValues)) {
            if(status.value) {
                filterValues.push({value: `${key}`, matchMode: FilterMatchMode.EQUALS});
            }
        }

        
        
        let _globalFilter = { ...globalFilter };
        _globalFilter['Status'].constraints = filterValues;

        setGlobalFilter(_globalFilter);

        setStatuses(newValues);
    }

    const badgeStatusMap = (disabled, active) => {
        if ( disabled ) {
            if (active) {
                return <div className='text-green-500 border-round-md text-xs'><i className="pi pi-ban"></i></div>;
            }
            else {
                return <div className='text-500 border-round-md text-xs'><i className="pi pi-ban"></i></div>;
            }
            
        }
        else {
            if (active) {
                return <div className='text-green-500 border-round-md text-xs'><i className="pi pi-check-circle"></i></div>; 
            }
            else {
                return <div className='text-red-500 border-round-md text-xs'><i className="pi pi-times-circle"></i></div>;
            }
        }            
    }

    const sourceTemplate = (rowData) => {
        const _sourceAgent = rowData.SourceAgent;
        const _cSource = sources[rowData.uuid];

        let active = rowData.path?rowData.path.SSTATE:rowData.Active !== 0;

        if(_sourceAgent) {
            return (
                <div className='flex align-items-center'>
                    {
                        badgeStatusMap(_cSource.disabled, active)
                    }
                    <div className='ml-1'>{`${rowData.SourceName}`}</div>
                    <Link to={`/editagent`} state={{agent:{..._sourceAgent}, from:'status'}} style={{ textDecoration: 'none' }}>
                        {` (${_sourceAgent.name})`}
                    </Link>
                </div>
            )
        }
        else {
            return rowData.SourceT;
        }
    }

    const destinationTemplate = (rowData) => {
        const _destAgent = rowData.AgentObj;
        let active = rowData.path?rowData.path.DSTATE:false;

        if(_destAgent) {
            return (
                <div className='flex align-items-center'>
                    {badgeStatusMap(rowData.disabled, active)}
                    <Link to={`/editstream`} state={{agent:_destAgent, stream:rowData, from:'status'}} style={{ textDecoration: 'none' }}>
                        <div className='ml-1'>{` ${rowData.AgentT}`}</div>
                    </Link>
                    
                </div>
            )
        }
        else {
            return rowData.AgentT;
        }
    }

    const serverBodyTemplate = (rowData) => {
        if(rowData.Server) {
            return (<div className='text-green-600'>{rowData.Server}</div>)
        }
        else {
            return (<div>Not Connected</div>)
        }
    }
    const rowClass = (rowData) => {
        let even = rowData.even?'bg-gray-50':'';
        return even;
    }
    //groupRowsBy="SourceT"
    //<Column className="text-base" field="SourceT" header="Source" body={sourceTemplate} filter style={{ flexGrow: 1, flexBasis: '200px' }}></Column>

    return (
        <>
            <div className='flex align-content-center' style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='p-3 text-white font-bold'>STATUS</div>
            </div>
            <div className='flex justify-content-between align-items-center'>
                <div className='flex p-2'>
                    <Button className='p-button-text' label={`ALL (${paths.length})`} onClick={selectAll}/>
                </div>
                <div className='flex p-2'>
                    <Button label={`${statuses.success.count}`} tooltip="Good" className={`p-button-rounded ${(statuses.success.value)?'bg-green-600':'bg-white text-600'} border-green-600 mx-1`} onClick={(e)=>selectState(!statuses.success.value, 'success')} />
                    <Button label={`${statuses.warning.count}`} tooltip="No data" className={`p-button-rounded ${(statuses.warning.value)?'bg-yellow-600':'bg-white text-600'} border-yellow-600 mx-1`} onClick={(e)=>selectState(!statuses.warning.value, 'warning')}/>
                    <Button label={`${statuses.error.count}`} tooltip="Dropping packets" className={`p-button-rounded ${(statuses.error.value)?'bg-red-600':'bg-white text-600'} border-red-600 mx-1`} onClick={(e)=>selectState(!statuses.error.value, 'error')}/>
                    <Button label={`${statuses.information.count}`} tooltip="No connection" className={`p-button-rounded ${(statuses.information.value)?'bg-gray-200':'bg-white text-600'} border-gray-200 mx-1`} onClick={(e)=>selectState(!statuses.information.value, 'information')}/>
                </div>
            </div>
            <div className='flex align-content-center' style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='p-3 text-white font-bold'>PATHS</div>
            </div>
            <div>
                <DataTable value={paths} responsiveLayout="scroll" sortMode="single" filterDisplay="row"
                    filters={globalFilter}
                    globalFilterFields={['Status']}
                    rowGroupMode="rowspan"
                    rowClassName={rowClass}
                    groupRowsBy="SourceT"
                    scrollHeight="650px"
                     >

                    <Column className="text-base" field="SourceT" header="Source" body={sourceTemplate} filter style={{ flexGrow: 1, flexBasis: '200px' }}></Column>
                    <Column className="text-base" field="AgentT" header="Destination" body={destinationTemplate} filter style={{ flexGrow: 1, flexBasis: '200px' }}></Column>
                    <Column className="text-base" field="Server" header="Connection"  body={serverBodyTemplate}  filter style={{ flexGrow: 1, flexBasis: '200px' }}></Column>
                    <Column field='Status' header="Status" body={statusBodyTemplate} style={{ flexGrow: 1, flexBasis: '200px' }} ></Column>
                </DataTable>
            </div>
        </>
    
    )
}

export default Status;