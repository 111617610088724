import React, {useState, useEffect, useContext, useRef} from 'react';


import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Ripple } from 'primereact/ripple';

import { DbUser, DbUserSet } from '../db/users';
import { DatacastContext } from '../context/datacastcloud';
import { Button } from 'primereact/button';

import bcrypt from 'bcryptjs';


const Settings = () => {
 
    const [user, setUser] = useState({
        name:'',
        email:''
    });

    const [dirty, setDirty] = useState(false);
    const [panelCollapsed, setPanelCollapsed] = useState(true);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const toast = useRef(null);

    const [datacastProperties] = useContext(DatacastContext);

    useEffect(() => {    
        DbUser(datacastProperties.userIp, datacastProperties.id).then((result) => {
            const user = result.data;
   
            if(result.error === 0) {
                
                setUser(user);
            }

        }).catch((error) => {
            console.error(error);
        });

    },[datacastProperties]); //Run each time id changes.

    const template = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
                <span className={titleClassName}>
                    Change Password
                </span>
            </div>
        )
    }

    const updatePassword = () => {
        
        const matched = bcrypt.compareSync(password, user.password);
        
        if(matched === true) {
            if(newPassword.length > 0 && newPassword2.length > 0 && newPassword === newPassword2) {
                
                const salt = bcrypt.genSaltSync(10);
                const hashedPassword = bcrypt.hashSync(newPassword, salt) // hash created previously created upon sign up
                const newUser = {...user, password:hashedPassword};

                DbUserSet(datacastProperties.userIp, {id:newUser.id, password:newUser.password}).then((result) => {
                    const user = result.data;
           
                    if(result.error === 0) {
                        showResult('success', 'Password', 'Password updated successfully');
                        setNewPassword('');
                        setNewPassword2('');
                        setPanelCollapsed(true);
                        setUser(user);
                    }
                    else {
                        showResult('error', 'Password', 'Password could not be updated');
                    }
        
                }).catch((error) => {
                    console.error(error);
                });
            }
        }
        else {
            showResult('error', 'Invalid Password', 'Current password is incorrect');
        }
    }

    const userInput = (field, value) => {

        setUser({...user, [field]:value})
        setDirty(true);
    }

    const updateUser = () => {

        DbUserSet(datacastProperties.userIp, {id:user.id, name:user.name, email:user.email}).then((result) => {
            const _user = result.data;
   
            if(result.error === 0) {
                setUser(_user);
                setDirty(false);
                showResult('success', 'User updated', result.message);
            }
            else {
                showResult('error', 'User could not be updated', result.message);
            }

        }).catch((error) => {
            console.error(error);
        });
    }

    const showResult = (severity, summary, detail) => {
        toast.current.show({severity:severity, summary:summary, detail:detail});
    }

    return (
        <>
            <Toast ref={toast} />
            <div className='flex align-content-center' style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='p-3 text-white font-bold'>SETTINGS</div>
            </div>
            <div className='border-solid surface-border border-1'>
                <div className='flex p-6'>
                    
                    <div className='grid col-12'>
                        <div className='col-6'>
                            <div className='font-bold mb-1'>Name</div>
                            <div className='flex w-full'>
                                <InputText className='surface-100 w-full border-round-md border-none' value={user && user.name} onChange={(e) => userInput('name',e.target.value)} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='font-bold mb-1'>Email</div>
                            <div className='flex '>
                                <InputText className='surface-100 w-full border-round-md border-none' value={user && user.email} onChange={(e) => userInput('email', e.target.value)} keyfilter="email" />
                            </div>
                        </div>
                        <div className='col-12'>
                            <Panel headerTemplate={template} toggleable collapsed={panelCollapsed} onToggle={(e) => setPanelCollapsed(e.value)}>
                                <div className='flex-column jusify-content-center mt-2'>
                                    <div className='flex font-bold'>OLD PASSWORD</div>
                                    <div className='flex'><Password className='w-full' inputClassName='border-none border-round-lg surface-50 w-full' value={password} onChange={(e)=>setPassword(e.target.value)} toggleMask={true} placeholder="Old password" feedback={false}/></div>
                                </div>
                                <div className='flex-column jusify-content-center mt-2'>
                                    <div className='flex font-bold'>NEW PASSWORD</div>
                                    <div className='flex'><Password className='w-full' inputClassName='border-none border-round-lg surface-50 w-full' value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} toggleMask={true} placeholder="New password" /></div>
                                </div>
                                <div className='flex-column jusify-content-center mt-2'>
                                    <div className='flex font-bold'>RETYPE NEW PASSWORD</div>
                                    <div className='flex'><Password className='w-full' inputClassName='border-none border-round-lg surface-50 w-full' value={newPassword2} onChange={(e)=>setNewPassword2(e.target.value)} toggleMask={true} placeholder="Retype new password" feedback={false}/></div>
                                </div>
                                {
                                    (newPassword.length > 0 && newPassword2.length > 0 && newPassword === newPassword2)?
                                    <div className='flex justify-content-end mt-4'>
                                        <Button label='UPDATE' onClick={updatePassword} />
                                    </div>
                                    : <></>
                                }
                            </Panel>  
                        </div>
                        <Divider />
                        <div className='col-12' >
                            {
                                 (dirty)?
                                    <div className='flex justify-content-end'>
                                        <Button label='UPDATE SETTINGS' onClick={updateUser} />
                                    </div>
                                : <></>
                            }
                        </div>

                    </div>
                    
                    
                </div>
            </div>
        </>            
    
    )
}

export default Settings;