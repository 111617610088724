
export const DbManagerAgent= async (db, id) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/manager/${id}`, requestOptions);
    const data = await response.json();

    if(data.error === 0) {
        return data.data;
    }

    return [];
}


export const DbManagerAgentModify = async (db, id, properties) => {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(properties)
    };

    const response = await fetch(`/api/v1/manager/${id}`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbManagerAgentReboot = async (db, id, enable) => {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({reboot:enable?1:0})
    };

    const response = await fetch(`/api/v1/manager/${id}`, requestOptions);
    const data = await response.json();

    return data;
}



