export const DbStreams= async (db, type=null) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    const response = await fetch(`/api/v1/mistiq/streams/${(type !== null)?type:''}`, requestOptions);
    if(response.ok) {
        const result = await response.json();
        if(result.error === 0) {
            return result.data;
        }
    }
    

    return [];
}

export const DbStreamsMap = async(db, type=null) => {

    const _streams = await DbStreams(db, type);

    if(_streams) {
        const _sourcesObj = {};
        for(let stream of _streams) {
            //Create primary source object if it doesn't exist.
            if(!_sourcesObj[stream.uuid]) {
                _sourcesObj[stream.uuid] = {destinations:[]};
            }

            //Create backup source object if it doesn't exist.
            if(!_sourcesObj[stream.source] && stream.source && stream.source.length > 0) {
                _sourcesObj[stream.source] = {destinations:[]};
            }


            if(stream.type === 'SOURCE') {
                _sourcesObj[stream.uuid] = {..._sourcesObj[stream.uuid],...stream};
            }
            else {
                //Set

                _sourcesObj[stream.uuid].destinations.push(stream);
                if(stream.source && stream.source.length > 0) {
                    _sourcesObj[stream.source].destinations.push(stream);
                }
            }
            
        }

        return _sourcesObj;
    }

    return null;
}

export const DbStreamSet= async (db, stream) => {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stream)
    };

    const response = await fetch(`/api/v1/mistiq/streams/`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbStreamDisabled= async (db, streamId, disabled) => {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({id:streamId, disabled:disabled})
    };

    const response = await fetch(`/api/v1/mistiq/streams/`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbStreamDestroy= async (db, streamid) => {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/streams/${streamid}`, requestOptions);
    const data = await response.json();

    return data;
}


