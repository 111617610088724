import React, {useState}  from "react";
import { useInterval } from "../../hooks/useinterval";
import Chart from 'react-apexcharts';
import { SrtServerAPIGetHealth } from "../../db/srtserver";

function PerformanceMonitor({ip})
{

    const performanceOptions = {
        chart: {
          type: 'line',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Server Performance',
          align: 'left'
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC:false
          }

        },
        yaxis: [
          {
            seriesName:'eth0_tx',
            min: 0,
            title: {
              text:'kbps'
            }
          },
          {
            min: 0,
            seriesName:'eth0_tx',
            show: false
          }
          ,
          {
            seriesName:'cpu',
            opposite: true,
            min: 0,
            max: 100,
            title: {
              text: '%'
            }
          },
          {
            min: 0,
            max: 100,
            seriesName:'cpu',
            show: false
          },
          {
            min: 0,
            max: 100,
            seriesName:'cpu',
            show: false
          }
          
      ],
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left'
        },
      };
    

    const [networkState, setNetworkState] = useState(
                                                      [
                                                        {name:'eth0_tx', data:[]},
                                                        {name:'eth0_rx', data:[]},
                                                        {name:'cpu', data:[]},
                                                        {name:'mem', data:[]},
                                                        {name:'disk', data:[]}
                                                      ]
                                                    );

    useInterval(async () => {

        const [cpu, memory, disk, network] = await SrtServerAPIGetHealth(ip);
        

        let series = [
          {name:'eth0_tx', data:[]},
          {name:'eth0_rx', data:[]},
          {name:'cpu', data:[]},
          {name:'mem', data:[]},
          {name:'disk', data:[]}
        ];

        for(let temp of network) {
            if(networkState[0].data.length > 100) {
                networkState[0].data.shift();
                networkState[1].data.shift();
            }

            series[0].data = networkState[0].data.concat({x:Date.now(), y:(temp.tx_sec/128.0).toFixed(2)});
            series[1].data = networkState[1].data.concat({x:Date.now(), y:(temp.rx_sec/128.0).toFixed(2)});
        }

        if(cpu) {
          if(networkState[2].data.length > 100) {
              networkState[2].data.shift();
          }

          series[2].data = networkState[2].data.concat({x:Date.now(), y:(100 - cpu.currentLoadIdle).toFixed(2)});
        }

        if(memory) {
          if(networkState[3].data.length > 100) {
              networkState[3].data.shift();
          }

          series[3].data = networkState[3].data.concat({x:Date.now(), y:((memory.active / memory.total)*100).toFixed(2)});
        }

        if(disk && disk.length > 0) {
          const disk1 = disk[0];

          if(networkState[4].data.length > 100) {
              networkState[4].data.shift();
          }

          series[4].data = networkState[4].data.concat({x:Date.now(), y:(disk1.use).toFixed(2)});
        }
    
        setNetworkState(series);
        

    }, 3000);
    
    return (
        <>
            <Chart options={performanceOptions} series={networkState} type="line" height={600} />
        </>
    );
}

export default PerformanceMonitor;