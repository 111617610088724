import React, { useState, useContext, useEffect, useRef } from 'react';

import { useLocation, useNavigate } from "react-router-dom";

import uuid from 'react-uuid';

import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Slider } from 'primereact/slider';
import { Checkbox } from 'primereact/checkbox';
import { Panel } from 'primereact/panel';

import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { DatacastAPIGetServers } from '../db/datacast';
import { DbAgentStreamCreate } from '../db/agents';
import { DbCreateAlert } from '../db/alerts';
import { GetSourceOptions, GetBackupOptions } from '../common/common';
import { DatacastContext } from '../context/datacastcloud';
import { DbAgents } from '../db/agents';

const AddStream = () => {

    const [stream, setStream] = useState({
        type:'DESTINATION',
        source:'',
        primaryServer:'',
        backupServer:'',
        primaryStreamId:'',
        backupStreamId:'',
        name:'',
        uuid:uuid(),
        ipAddr:'',
        port:0,
        backupIpAddr:'',
        backupPort:0,
        agentIpAddr:'127.0.0.1',
        agentPort:44047,
        mode:'SRT',
        latency:125,
        disabled:0
    })

    const navigate = useNavigate();
    const location = useLocation();

    const agent = location.state;

    const typeOptions = (agent.type === 'MAP')?[
        {label:'SOURCE', value:'SOURCE', disabled:true},
        {label:'DESTINATION', value:'DESTINATION', disabled:false}]:
        [{label:'SOURCE', value:'SOURCE', disabled:false},
         {label:'DESTINATION', value:'DESTINATION', disabled:false}];

    const toast = useRef(null);

    const [ dirty, setDirty ] = useState(false);
    const [ serverOptions, setServerOptions ] = useState([]);
    const [ backupServerOptions, setBackupServerOptions ] = useState([]);
    const [ primaryOptions, setPrimaryOptions ] = useState([]);
    const [ backupOptions, setBackupOptions ] = useState([]);
    const [ agentOptions, setAgentOptions] = useState([]);
    const [ p2pAgent, setP2pAgent] = useState({label:'N/A', value:''});
    const [ sourceOptions, setSourceOptions ] = useState([]);
    const [ datacastProperties ] = useContext(DatacastContext);
    const modes = ['CLOUD', 'P2P'];
    const [mode, setMode] = useState(modes[0]);

    const srtModes = ['RENDEZVOUS', 'LISTENER', 'CALLER'];
    const [srtMode, setSrtMode] = useState(srtModes[0]);

    const nameMap ={
        0:'Connected',
        1:'Disconnected',
        2:'Availability',
        3:'Data Stopped',
        4:'Data Started'
    }

    const subscriptionTemplate = [{name: nameMap[0], type: 0, enabled:0}, {name: nameMap[1], type: 1, enabled:0}, {name: nameMap[2], type: 2, enabled:0}, {name: nameMap[3], type: 3, enabled:0}, {name: nameMap[4], type: 4, enabled:0}]

    const [alerts, setAlerts] = useState(subscriptionTemplate);

    const onAlertChange = (e) => {

        let _alerts = [...alerts];

        const alertIndex = _alerts.findIndex(element=> element.type === e.value.type)
        if(alertIndex !== -1 ) {
            _alerts[alertIndex].enabled = (e.checked)?1:0;
        }

        setDirty(true);


        setAlerts(_alerts);
    }

    useEffect(() => {

        const Initialize = async () => {
            const _sourceOptions = await GetSourceOptions(datacastProperties);
            setSourceOptions(_sourceOptions);

            const sourceOption = _sourceOptions.find(element=>element.value === stream.uuid);

            if(stream.primaryStreamId) {
                const found = stream.primaryStreamId.match(/mode=(listener|rendezvous|caller)/i);
                if (found) {
                    setSrtMode(found[1].toUpperCase());
                }
                
            }

            const _agents = await DbAgents(datacastProperties.userIp);
            const _agentOptions = _agents.filter((e)=>(e.uuid !== agent.uuid && e.externalIp.length > 0)).map((a)=>{

                return {
                    label:a.name,
                    uuid:a.uuid,
                    externalIp: a.externalIp,
                    port:(sourceOption && sourceOption.server)?sourceOption.server.port : stream.port
                }
            });

            setAgentOptions([{label:'N/A', uuid:''}, ..._agentOptions]);


            const _serverOptions = await DatacastAPIGetServers(datacastProperties.ip);

            const _primaryOptions = _serverOptions.filter((e)=>e.value !== stream.backupServer);
            setPrimaryOptions(_primaryOptions);

            
            const _backupOptions2 = await GetBackupOptions(datacastProperties, stream.uuid);
            setBackupServerOptions(_backupOptions2);

            const _backupOptions = ((stream.type === 'DESTINATION')?_backupOptions2:_serverOptions).filter((e)=>e.value !== stream.primaryServer);
            setBackupOptions([{label:'N/A', value:''}, ..._backupOptions]);

            setServerOptions(_serverOptions);

            setStream({...stream, primaryServer:_primaryOptions[0].value});

        }
            
        Initialize().catch((error) => {
            console.error(error);
        });
        
    
    },[agent, datacastProperties]); 

    const userInput = (field, value) => {

        if(field === 'primaryServer') {
            const _backupOptions = backupServerOptions.filter((e)=>e.value !== value);
            setBackupOptions([{label:'N/A', value:''}, ..._backupOptions]);   
        }
        else if (field === 'backupServer') {
            
            const _primaryOptions = serverOptions.filter((e)=>e.value !== value);

            setPrimaryOptions(_primaryOptions); 
        }

        let newValue = (typeof value === 'string')? value.substring(0,36) : value;
        if (field === 'disabled') {
            newValue = (value)?0:1;
        }


        const update = {
            [field]:newValue
        }

        if(field === 'type') {
            if(value === 'SOURCE') {
                update.uuid = uuid();
            }
        }

        setStream({...stream, ...update})
        setDirty(true);
    }
    
    

    const createStream = () => {
        const _stream = {...stream};
        const sourceOption = sourceOptions.find(element=>element.value === stream.uuid);

        if(mode === 'CLOUD') {
            const _primaryServer = serverOptions.find(element => element.value === stream.primaryServer);
            const _backupServer = (_stream.type === 'DESTINATION'?backupOptions : serverOptions ).find(element => element.value === stream.backupServer);

            if(_stream.type === 'DESTINATION') {

                const sourceOption = sourceOptions.find(element=>element.value === stream.uuid);
                if(sourceOption) {
                    _stream.name = sourceOption.label;
                    _stream.uuid = sourceOption.uuid;
                }

                if(_primaryServer) {
                    _stream.primaryStreamId = `${_primaryServer.destination}/${agent.uuid}/${_stream.uuid}`;
                    _stream.ipAddr = _primaryServer.ipAddr;
                    _stream.port = _primaryServer.port;
                }

                if(_backupServer) {
                    _stream.backupStreamId = `${_backupServer.destination}/${agent.uuid}/${_backupServer.stream}`;
                    _stream.backupServer = _backupServer.server?_backupServer.server.value : stream.backupServer;
                    _stream.backupIpAddr = _backupServer.ipAddr;
                    _stream.backupPort = _backupServer.port;
                    _stream.source = _backupServer.stream;
                }
                
            }
            else {
                if(_primaryServer) {
                    _stream.primaryStreamId = `${_primaryServer.source}/${agent.uuid}/${stream.uuid}`;
                    _stream.ipAddr = _primaryServer.ipAddr;
                    _stream.port = _primaryServer.port;
                }

                if(_backupServer) {
                    _stream.backupStreamId = `${_backupServer.source}/${agent.uuid}/${stream.uuid}`;
                    _stream.backupIpAddr = _backupServer.ipAddr;
                    _stream.backupPort = _backupServer.port;
                }
            }

            _stream.source = _stream.source?_stream.source:'';
        }
        else {
            const _peerAgent = agentOptions.find(element => element.uuid === p2pAgent);
            if(_peerAgent) {
                if(_stream.type === 'DESTINATION') {
                    if(sourceOption) {
                        _stream.name = sourceOption.label;
                        _stream.uuid = sourceOption.uuid;
                        _stream.primaryStreamId = `direct.datacast.com/direct/${_peerAgent.uuid}/${_stream.uuid}&mode=${srtMode.toLocaleLowerCase()}`;
                        _stream.ipAddr = (srtMode !== 'LISTENER')?_peerAgent.externalIp:'';
                        _stream.port = parseInt(sourceOption.server.port);
                        _stream.backupStreamId = _peerAgent.uuid;
                        _stream.backupIpAddr = '';
                        _stream.backupPort = 0;
                        _stream.primaryServer = '';
                        _stream.backupServer = '';
                        _stream.source = '';
                    }
                }
                else {
                    _stream.primaryStreamId = `direct.datacast.com/direct/${agent.uuid}/${_stream.uuid}&mode=${srtMode.toLocaleLowerCase()}`;
                    _stream.ipAddr = (srtMode !== 'LISTENER')?_peerAgent.externalIp:'';
                    _stream.backupStreamId = _peerAgent.uuid;
                    _stream.backupIpAddr = '';
                    _stream.backupPort = 0;
                    _stream.primaryServer ='';
                    _stream.backupServer = '';
                    _stream.source = '';
                }
            }
        }

        DbAgentStreamCreate(datacastProperties.userIp, agent.uuid, _stream).then((result) => {

            if(result.error === 0) {
                const stream = result.data;
                const _alert = {
                    userAlert:{
                        userId:datacastProperties.id,
                        streamId:stream.id
                    },
                    alerts:[ ...alerts ]
                }

                DbCreateAlert(datacastProperties.userIp, _alert).then((result) => {
        
                    setDirty(false);
        
                }).catch((error) => {
                    console.error(error);
                });
                
                
                navigate(`/editagent`,{state:{agent:{...agent}, from:'createStream'}});
            }
            else {
                showResult('error', 'Create Failed', result.message);
            }

        }).catch((error) => {
            console.error(error);
        });
    }

    const cancelStream = () => {

        navigate(`/editagent`,{state:{agent:{...agent}, from:'createStream'}});
    }

    const showResult = (severity, summary, detail) => {
        toast.current.show({severity:severity, summary:summary, detail:detail, life: 3000});
    }

    const onP2pAgent = (value) => {
        
        setDirty(true);
        setP2pAgent(value);
    }

    const onSetSrtMode = (value) => {
        setDirty(true);
        setSrtMode(value);
    }

    return (
        <>
            <Toast ref={toast} />

            <div className='flex align-content-center' style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='p-3 text-white font-bold'>CREATE STREAM</div>
            </div>
            
            <div className='border-solid surface-border border-1'>
                <div className='p-6'>    
                    <div className='grid'>
                        <div className='col-6'>
                            <div className='font-bold mb-1'>Type</div>
                            <div className='flex'>
                                <Dropdown className='surface-100 w-full border-round-md border-none'  value={stream.type} options={typeOptions} onChange={(e) => userInput('type',e.target.value)} disabled={datacastProperties.agentGroup.createSource === 0}/>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='font-bold mb-1'>{`Source ${(datacastProperties.admin && stream.type === 'SOURCE')?`(${stream.uuid})`:''}`}</div>
                            <div className='flex '>
                                <InputText className={`${(stream.type === 'DESTINATION')?'hidden ':''} surface-100 w-full border-round-md border-none`}  keyfilter={/[\w\d-'"?!\s]{1,36}/i} value={stream.name}  onChange={(e) => userInput('name',e.target.value)}/>
                                <Dropdown className={`${(stream.type === 'DESTINATION')?'':'hidden '} surface-100 w-full border-round-md border-none`} optionLabel="label" optionValue="value" value={stream.uuid} options={sourceOptions} onChange={(e) => userInput('uuid',e.value)} />
                            </div>
                        </div>
                        <div className='col-2'>
                            <div className='font-bold mb-1'>Mode</div>
                            <div className='flex w-full'>
                                <Dropdown className="surface-100 w-full border-round-md border-none" value={mode} options={modes} onChange={(e) => setMode(e.value)} placeholder="Select  mode" />
                            </div>
                        </div>
                        { (mode === 'CLOUD')?
                        <>
                        <div className='col-4'>
                            <div className='font-bold mb-1'>Primary Server</div>
                            <div className='flex w-full'>
                                <Dropdown className='surface-100 w-full border-round-md border-none' optionLabel="label" optionValue="value" value={stream.primaryServer} options={primaryOptions} onChange={(e) => userInput('primaryServer',e.value)} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='font-bold mb-1'>Backup</div>
                            <div className='flex w-full'>
                                <Dropdown className='surface-100 w-full border-round-md border-none' optionLabel="label" optionValue="value" value={stream.backupServer} options={backupOptions} onChange={(e) => userInput('backupServer',e.value)} />
                            </div>
                        </div>
                        </>
                        :
                        <>
                            <div className='col-4'>
                                <div className='font-bold mb-1'>Mode</div>
                                <div className='flex w-full'>
                                    <Dropdown className="surface-100 w-full border-round-md border-none" value={srtMode} options={srtModes} onChange={(e) => onSetSrtMode(e.value)} placeholder="Select SRT mode" />
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='font-bold mb-1'>Agent</div>
                                <div className='flex w-full'>
                                    <Dropdown className='surface-100 w-full border-round-md border-none' optionLabel="label" optionValue="uuid" value={p2pAgent} options={agentOptions} onChange={(e) => onP2pAgent(e.value)}/>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='font-bold mb-1'>Port</div>
                                <div className='flex w-full'>
                                    <InputNumber className='w-full' min={65500} max={65535} inputClassName='surface-100 w-full border-round-md border-none' value={stream.port} onValueChange={(e) => userInput('port',e.value)}  mode="decimal" minFractionDigits={0} useGrouping={false} disabled={stream.type === 'DESTINATION'}/>
                                </div>
                            </div>
                        </>
                        }
                        
                        {
                            (agent.type === 'GATEWAY')?
                                <>
                                <div className='col-6' >
                                    <div className='font-bold mb-1'>{`${(stream.type === 'DESTINATION')?'OUTPUT':'INPUT'} IP ADDRESS`}</div>
                                    <div className='flex w-full'>
                                        <InputText className='surface-100 w-full border-round-md border-none' keyfilter={/(?:[0-9.])/} value={stream.agentIpAddr}  onChange={(e) => userInput('agentIpAddr',e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='font-bold mb-1'>{`${(stream.type === 'DESTINATION')?'OUTPUT':'INPUT'} PORT`}</div>
                                    <div className='flex w-full'>
                                        <InputNumber className='w-full' min={1024} max={65499} inputClassName='surface-100 w-full border-round-md border-none' value={stream.agentPort} onValueChange={(e) => userInput('agentPort',e.value)}  mode="decimal" useGrouping={false} />
                                    </div>
                                </div>
                                </>
                            :
                            ''
                        }
                        <div className='col-6'>
                            <Inplace>
                                <InplaceDisplay>
                                    <div className='font-bold mb-4'>Latency ({stream.latency} ms)</div>
                                </InplaceDisplay>
                                <InplaceContent>
                                    <span className='font-bold mb-4'>Latency</span>
                                    <span className='font-bold mb-4' ><InputNumber  value={stream.latency} autoFocus min={10} max={10000} onChange={(e)=>userInput('latency', e.value)} /></span>
                                    <span className='font-bold mb-4'>ms</span>
                                </InplaceContent>
                            </Inplace>
                            
                            <div className='flex w-full'>
                                <Slider className='surface-100 w-full border-round-md border-none' value={stream.latency} onChange={(e) => userInput('latency',e.value)}  min="10" max="10000" />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='font-bold mb-4'>Enable</div>
                            <div className='flex w-full align-items-center'>
                                <Checkbox name="enable" value={stream.disabled} onChange={(e) => userInput('disabled', e.value)} checked={stream.disabled === 0} />
                            </div>
                        </div>
                        <div className='col-12 mt-3'>
                            <Panel header="Subscribe To Stream Alerts">
                            {
                                alerts.map((alert) => {
                                    return (
                                        <div key={alert.type} className="field-checkbox ml-2">
                                            <Checkbox inputId={alert.type} name="alert" value={alert} onChange={onAlertChange} checked={alert.enabled === 1}  />
                                            <label htmlFor={alert.type}>{nameMap[alert.type]}</label>
                                        </div>
                                    )
                                })
                            }
                            </Panel>
                        </div>
                        
                        <div className='col-12'>
                            
                            <div className='flex justify-content-end mt-4' >
                                <div>
                                    <Button label="Cancel" className="p-button-danger" icon="pi pi-times" onClick={cancelStream} />
                                </div>
                                {
                                    (dirty)?
                                        <div className='pl-2'>
                                            <Button label="Create" className="p-button-primary" icon="pi pi-check" onClick={createStream} />
                                        </div>
                                    :
                                        <></>
                                }
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>
        </>            
    
    )
}

export default AddStream;