import React, { useEffect, useState, useContext, useRef } from 'react';

import { Link } from 'react-router-dom';

import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { Button } from 'primereact/button';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { Tag } from 'primereact/tag';

import { DatacastContext } from '../context/datacastcloud';
import { DbStreamDestroy, DbStreamsMap, DbStreamDisabled } from '../db/streams';
import { DbAgentStreams, DbAgentDestroy } from '../db/agents';
import { DbManagerAgentReboot } from '../db/manager';
import { DatacastAPIDestroyAgent, DatacastAPIDestroyStreams, DatacastAPIPublicStreams } from '../db/datacast';
import { useInterval } from '../hooks/useinterval';
import { formatUptime } from '../utils/misc';


const Agents = () => {
    
    const [delay] = useState(5000);
    const [refreshKey, setRefreshKey] = useState(0);
    const [agentsMap, setAgentsMap] = useState([]);
    const [treeAgents, setTreeAgents] = useState([]);
    const [publicStreams, setPublicStreams] = useState({});
    const [datacastProperties] = useContext(DatacastContext);
    const toast = useRef(null);

    const getAgents = async () => {
        const _agents = await DbAgentStreams(datacastProperties.userIp);
        if (!publicStreams) {
            const _publicStreams = await DatacastAPIPublicStreams(datacastProperties.ip);
            setPublicStreams(_publicStreams);
        }

        const _streamsMap = await DbStreamsMap(datacastProperties.userIp);

        let _agentsList = [];
        let _agentMap = {};
        if(datacastProperties.agentMap) {
            for(let agentT of _agents) {
                if(datacastProperties.agentMap[agentT.id]) {
                    _agentsList.push(agentT);
                    _agentMap[agentT.uuid] = agentT;
                }
            }

            setAgentsMap(_agentMap);
        }
        else {
            for(let agentT of _agents) {
                _agentsList.push(agentT);
                _agentMap[agentT.uuid] = agentT;
            }

            setAgentsMap(_agentMap);
        }

        const _treeAgents = [];
        for(let _agent of _agentsList) {
            let item = {
                key:_agent.uuid,
                data:{
                    id:_agent.id,
                    name:_agent.name,
                    type:_agent.type,
                    uuid:_agent.uuid,
                    version: _agent.version,
                    upTime:_agent.upTime,
                    externalIp: _agent.externalIp,
                    cpuUsage:_agent.cpuUsage,
                    memoryUsage:_agent.memoryUsage,
                    diskUsage:_agent.diskUsage,
                    disabled:_agent.disabled,
                    noTunnel:!(_agent.keyPath && _agent.keyPath.length > 0),
                    updatedAt:_agent.updatedAt,
                    createdAt:_agent.createdAt
                },
                children:[]
            }

            for(let _stream of _agent.streams) {
                const src = _streamsMap[_stream.uuid];
                if (src) {
                    _stream.name = src.name; //Use the source name
                    _stream.destinations = src.destinations.length;
                    let childItem = {
                        key:_stream.id,
                        data:{
                            ..._stream,
                            agent:item.data
                        }
                    }
                    item.children.push(childItem);
                }
            }

            _treeAgents.push(item);
        }

        setTreeAgents(_treeAgents);
    }

    useEffect(() => {

        getAgents().catch((error) => {
            console.error(error);
        });
    
    },[refreshKey, datacastProperties]); 

    useInterval(() => {

        getAgents();

    }, delay);

    const nameBodyTemplate = (treeData) =>{
        const rowData = treeData.data;
        if(rowData.cpuUsage > 0) {
            return (`${rowData.name} (U:${formatUptime(rowData.upTime)}, C:${rowData.cpuUsage}%, M:${rowData.memoryUsage}%, D:${rowData.diskUsage}%)`);
        }
        else {
            if(rowData.upTime > 0) {
                return (`${rowData.name} (${formatUptime(rowData.upTime)})`);
            }
            else {
                return (`${rowData.name}`);
            }
            
        }
        
    }

    const statusBodyTemplate2 = (treeData) => {
        
        const rowData = treeData.data;

        let enabled = false;

        let type = undefined;
        if(rowData.type === 'MAP' || rowData.type === 'GATEWAY') {
            const currentTime = new Date();
            const lastUpdate = new Date(rowData.updatedAt);
            const createdAt = new Date(rowData.createdAt);

            const cDiff = (currentTime.getTime() - createdAt.getTime())/1000;
            const difference = (currentTime.getTime() - lastUpdate.getTime())/1000;
            
            enabled = (difference < 120 && cDiff > 120); //The agents run 1 per minute. 
            type = 'agent';
        }
        else {
            type='stream';
            enabled = rowData.disabled === 0;
        }
        
        if ( type === 'agent') {
            if(enabled) {
                return (
                    <div className='flex justify-content-end'>
                        <Tag className="p-mr-2 bg-green-600 border-green-600" value={'Online'} rounded></Tag>
                        {/*<Button className="p-button-rounded p-button-sm bg-green-600 border-green-600" icon="pi pi-check" disabled/>*/}
                    </div>
                )
            }
            else {
                return (
                    <div className='flex justify-content-end'>
                        <Tag className="p-mr-2 bg-gray-300 border-gray-300" value={'Offline'} rounded></Tag>
                        {/*<Button className="p-button-rounded p-button-sm bg-gray-300 border-gray-300" icon="pi pi-times" disabled/>*/}
                    </div>
                )
            }
        }
        else {
            if(!enabled) {
                return (
                    <div className='flex justify-content-end'>
                        <i className="pi pi-ban text-red-600"></i>
                        {/*<Button className="p-button-rounded p-button-sm bg-green-600 border-green-600" icon="pi pi-check" disabled/>*/}
                    </div>
                )
            }
        }

    }

    const deleteAgent = (event, agentId, agentUuid, name, rowData) => {

        const agent = agentsMap[agentUuid];
        let hasAccessGroup = false;
        if(Object.hasOwn(agent, 'streams')) {
            for(let stream of agent.streams) {
                if(stream.type === 'SOURCE' && publicStreams[stream.uuid]) {
                    hasAccessGroup = true;
                    break;
                }
            }
        }

        if(hasAccessGroup) {
            showResult('error', 'Permissions Error', 'Contains streams from public access groups. Remove from access groups first.');
        }
        else {
            confirmPopup({
                target: event.currentTarget,
                message: `Are you sure you want to delete ${name}?`,
                icon: 'pi pi-exclamation-triangle',
                accept: ()=>accept(agentId, agentUuid),
                reject: ()=>reject("Agent Delete", "Agent delete was canceled")
            });
        }

    }

    const acceptDeleteStream = (stream) => {

        const streamsToDelete = [];
        streamsToDelete.push({
            type:stream.type.toLowerCase(),
            uuid:stream.uuid,
            server:stream.primaryServer,
            agent:stream.agent.uuid
        });

        if(stream.backupServer.length > 0) {
            streamsToDelete.push({
                type:stream.type.toLowerCase(),
                uuid:stream.uuid,
                server:stream.backupServer,
                agent:stream.agent.uuid
            });
        }

        streamsToDelete.forEach((stream2)=>{
            
            setRefreshKey(oldKey => oldKey +1);
            //This doesn't actually delete it, it just schedules it for deletion.
            DatacastAPIDestroyStreams(datacastProperties.ip,stream.type, stream.uuid, stream.server, stream.agent ).then((result)=> {

                if(result.error !== 0) {
                    console.error(`Delete stream  from Datacast Cloud failed ${result.message}`);
                }

                setRefreshKey(oldKey => oldKey +1);
            }).catch((err)=>console.error(err));
        });

        //Removes it from Cortex.
        DbStreamDestroy(datacastProperties.userIp, stream.id).then((result) => {
            if(result) {

                if(result.error !== 0) {
                    console.error("Could not destroy stream from cortex");
                    showResult('error', 'Delete Stream Failed', result.message);
                }
                else {
                    showResult('success', 'Delete Stream Success', result.message);
                }

                
                setRefreshKey(oldKey => oldKey +1)
            }

        }).catch((error) => {
            console.error(error);
        });
    }

    const deleteStream = (event, stream) => {

        if(stream.type === 'SOURCE' && publicStreams[stream.uuid]) {
            showResult('error', 'Permissions Error', 'Stream must be removed from all public access groups first');
        }
        else if(stream.type === 'SOURCE' && stream.destinations !== 0) {
            showResult('error', 'Permissions Error', 'All destination streams must be removed before the source');
        }
        else {
            confirmPopup({
                target: event.currentTarget,
                message: 'Are you sure you want to delete this stream?',
                icon: 'pi pi-exclamation-triangle',
                accept: ()=>acceptDeleteStream(stream),
                reject: ()=>reject(`Delete canceled`, 'Stream delete canceled')
            });
        }
    }

    const accept = (agentId, agentUuid) => {
        DatacastAPIDestroyAgent(datacastProperties.ip, agentUuid).then((result) => {

        }).catch((error) => {
            console.error(error);
        });

        DbAgentDestroy(datacastProperties.userIp, agentId).then((result) => {
            if(result) {

                if(result.error !== 0) {
                    showResult('error', 'Could not destroy agent', result.message);
                }
                else {
                    showResult('success', 'Agent destroyed', result.message);
                }

                setRefreshKey(oldKey => oldKey +1)
            }

        }).catch((error) => {
            console.error(error);
        });
    }

    const acceptRestart = (streamId) => {

        DbStreamDisabled(datacastProperties.userIp, streamId, 2).then((result) => {
            if(result) {

                if(result.error === 0) {
                    setRefreshKey(oldKey => oldKey +1);
                    showResult('success', 'Stream Restart', "Stream restart message sent");
                }
            }

        }).catch((error) => {
            console.error(error);
        });
       
    }

    const restartStream = (rowData) => {
        confirmPopup({
            message: 'Are you sure you want to restart this stream. It will return to the primary?',
            icon: 'pi pi-exclamation-triangle',
            accept: ()=>acceptRestart(rowData.id),
            reject: ()=>reject("Restart Stream", "Restart stream canceled")
        });
}

    const reject = (title, message) => {
        showResult('warn', title, message);
    }

    const acceptReboot = (agent) => {
        DbManagerAgentReboot(datacastProperties.userIp, agent.uuid, true).then((result)=>{

            showResult('success', `Agent Reboot`, 'Agent reboot successful');
        });
    }

    const rebootAgent = (event, agent) => {

        confirmPopup({
            message: `Are you sure you want to reboot ${agent.name}?`,
            icon: 'pi pi-exclamation-triangle',
            accept: ()=>acceptReboot(agent),
            reject: ()=>reject("Agent Reboot", "Reboot of agent was canceled")
        });

        
    }

    const editBodyTemplate2 = (treeData) => {
        const rowData = treeData.data;
        
        if(rowData.type === 'MAP' || rowData.type === 'GATEWAY') {
            return(
            <div className='flex justify-content-end'>
                
                <Button className="p-button-rounded p-button-text p-button-sm p-button-success" icon={"pi pi-refresh"} onClick={(e)=>rebootAgent(e, rowData)} />
                <Link to={`/viewagent`} state={{agent:{...rowData}, from:'agents'}} style={{ textDecoration: 'none', pointerEvents:rowData.noTunnel?"none":"auto" }} disabled={rowData.noTunnel}>
                    <Button className="p-button-rounded p-button-text p-button-sm p-button-success" icon={"pi pi-desktop"} disabled={rowData.noTunnel}/>
                </Link>
                <Link to={`/editagent`} state={{agent:{...rowData}, from:'agents'}} style={{ textDecoration: 'none' }}>
                    <Button className="p-button-rounded p-button-text p-button-sm p-button-warning" icon={(datacastProperties.agentGroup.editAgent)?"pi pi-pencil":"pi pi-eye"}/>
                </Link>
                {
                    (datacastProperties.agentGroup.destroyAgent)?
                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={(e)=>deleteAgent(e, rowData.id, rowData.uuid, rowData.name, rowData)}/>
                    : <></>
                }
                
            </div>
            );
        }
        else {
            const edit = (((rowData.type === 'DESTINATION') && (datacastProperties.agentGroup.editStream === 1)) ||
                                ((rowData.type === 'SOURCE') && (datacastProperties.agentGroup.editSource === 1)));

            const destroy  = (((rowData.type === 'DESTINATION') && (datacastProperties.agentGroup.destroyStream === 1)) ||
            ((rowData.type === 'SOURCE') && (datacastProperties.agentGroup.destroySource === 1)));
            
            return(
                <div className='flex justify-content-end'>
                    {
                        <>
                            <Button className={'p-button-rounded p-button-success p-button-text'} 
                                icon={(rowData.disabled === 2)?'pi pi-spin pi-spinner':'pi pi-refresh'}
                                disabled={rowData.disabled === 2 || parseFloat(rowData.agent.version) < 1.1}
                                onClick={()=>restartStream(rowData)} 
                            />
                            <Link to={`/editstream`} state={{agent:rowData.agent, stream:rowData, from:'agents'}} style={{ textDecoration: 'none' }}>
                                <Button icon={(edit)?"pi pi-pencil":"pi pi-eye"} className="p-button-rounded p-button-secondary p-button-text" />
                            </Link>
                        </>
                    }
                    {(destroy)?
                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={(e)=>deleteStream(e, rowData)}/>
                        :
                        <></>
                    }
                </div>
            );
        }
    }

    const showResult = (severity, summary, detail) => {
        toast.current.show({severity:severity, summary:summary, detail:detail});
    }

    return (
        <>
            <Toast ref={toast} />
            <ConfirmPopup />
            <div className='flex align-content-center' style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='p-3 text-white font-bold'>AGENTS</div>
            </div>
            <div>
                <TreeTable value={treeAgents}>
                    <Column field="name" header="Name"  body={nameBodyTemplate} expander></Column>
                    <Column field="type" header="Type"></Column>
                    <Column field='disabled' body={statusBodyTemplate2} style={{width:'10%'}}></Column>
                    <Column body={editBodyTemplate2} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                </TreeTable>
            </div>
            {
                (datacastProperties.agentGroup.createAgent === 1)?    
                    <div className='align-content-center mt-2'>
                        <Button icon="pi pi-plus" className="p-button-rounded bg-blue-600" aria-label="User" />
                        <span className='ml-2 vertical-align-middle'>
                            <Link to="/createagent">
                                Add Agent
                            </Link>
                        </span>
                    </div>
                    :<></>
            }
        </>            
    
    )
}

export default Agents;