import React, { useEffect, useState, useContext, useRef } from 'react';

import { Link, Navigate } from 'react-router-dom';

import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';

import { DatacastContext } from '../context/datacastcloud';
import { DbUsers, DbUserSet, DbUserDestroy } from '../db/users';
import { DbCortex, DbCortexSet } from '../db/cortex';
import { DatacastAPIGroups, DatacastAPIGroupDestroy, DatacastAPIGroupSet, DatacastAPIUpdateAgentGroup } from '../db/datacast';

import bcrypt from 'bcryptjs';
import { DbAgentGroupDestroy, DbAgentGroups, DbAgentGroupSet } from '../db/agentgroups';



const Admin = () => {
    const [newPassword, setNewPassword] = useState('');
    const [visible, setVisible] = useState(false);
    const [cortex, setCortex] = useState({});
    const [dirty, setDirty] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [users, setUsers] = useState([]);
    const [accessGroups, setAccessGroups] = useState([]);
    const [agentGroups, setAgentGroups] = useState([]);
    const [agentTree, setAgentTree] = useState([]);
    const [accessGroupOptions, setAccessGroupOptions] = useState([]);
    const [agentGroupOptions, setAgentGroupOptions] = useState([]);
    const [ datacastProperties ] = useContext(DatacastContext);
    const toast = useRef(null);

    useEffect(() => {

        DbCortex(datacastProperties.userIp).then((result) => {
            const _cortex = result.data;
            
            if(result.error === 0) {
                setCortex(_cortex);
            }

        }).catch((error) => {
            console.error(error);
        });

        DbUsers(datacastProperties.userIp).then((_users) => {
                setUsers(_users);
        }).catch((error) => {
            console.error(error);
        });

        DbAgentGroups(datacastProperties.userIp).then((_agentGroups) => {
            
            const _agentTree = [];
            let even = false;
            for(let group of _agentGroups) {
                _agentTree.push({
                        id:group.id,
                        uuid:group.uuid,
                        group:group.name,
                        name:'Agents',
                        type:'Agent',
                        create:group.createAgent,
                        edit:group.editAgent,
                        destroy:group.destroyAgent,
                        even:even
                    });   
                    _agentTree.push({
                        id:group.id,
                        uuid:group.uuid,
                        group:group.name,
                        name:'Sources',
                        type:'Source',
                        create:group.createSource,
                        edit:group.editSource,
                        destroy:group.destroySource,
                        even:even
                    });

                    _agentTree.push({
                        id:group.id,
                        uuid:group.uuid,
                        group:group.name,
                        name:'Destinations',
                        type:'Stream',
                        create:group.createStream,
                        edit:group.editStream,
                        destroy:group.destroyStream,
                        even:even
                    });

                    even = !even;
            }

            setAgentTree(_agentTree);
            setAgentGroups(_agentGroups);
            setAgentGroupOptions([{name:'ALL', uuid:'ALL'}, ..._agentGroups]);
        })

        DatacastAPIGroups(datacastProperties.ip).then((result) => {
            const _accessGroups = result;

            if(result) {
                setAccessGroups(_accessGroups);
                setAccessGroupOptions([{GroupName:'ALL', GroupUuid:'ALL'}, ..._accessGroups]);
            }

        }).catch((error) => {
            console.error(error);
        });
    
    },[datacastProperties, refreshKey]); 


    const onRowEditComplete = (e) => {
        let _accessGroups = [...accessGroups];
        let _agentGroups = [...agentGroups];
        let { newData, index } = e;

        newData.activated = (newData.activated === true || newData.activated === 1)?1:0;
        newData.admin = (newData.admin === true || newData.admin === 1)?1:0;
        newData.accessGroup = (newData.accessGroup === undefined || newData.accessGroup === null || newData.accessGroup === 'ALL')? 'ALL':newData.accessGroup;
        newData.agentGroup = (newData.agentGroup === undefined || newData.agentGroup === null || newData.agentGroup === 'ALL')? 'ALL':newData.agentGroup;
        
        DbUserSet(datacastProperties.userIp, 
            {
                id:newData.id,
                activated:newData.activated,
                admin:newData.admin,
                accessGroup:newData.accessGroup,
                agentGroup:newData.agentGroup
            }).then((result) => {
            
            if(result.error === 0) {
                _accessGroups[index] = newData;
                _agentGroups[index] = newData;
                showResult('success', `User ${newData.name} updated`, result.message);
                setRefreshKey(oldKey => oldKey +1)
            }
            else {
                showResult('error', `Could not update ${newData.name}`, result.message);
            }

        }).catch((error) => {
            console.error(error);
        });

        setAccessGroups(_accessGroups);
        setAgentGroups(_agentGroups);
    }

    const checkEditor = (options) => {

        return <Checkbox onChange={(e) => options.editorCallback(e.target.checked)} checked={options.value === true || options.value === 1}></Checkbox>;
    }

    const activatedBodyTemplate = (rowData) => {
        return (
            <Checkbox checked={rowData.activated === 1 || rowData.activated === true} value={rowData.activated}></Checkbox>
        );
    }

    const adminBodyTemplate = (rowData) => {
        return (
            <Checkbox checked={rowData.admin === 1 || rowData.admin === true} value={rowData.admin}></Checkbox>
        );
    }

    const generateRandomPassword = () => {
        const length = 8;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{[}]|;:,./";
        let retVal = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }

        return retVal;
    }


    const getLabel = (array, value) => {
        for(let element of array) {
            if(element.GroupUuid === value) {
                return element.GroupName;
            }
        }

        return 'ALL';
    }
    

    const accessBodyTemplate = (rowData) => {

        return (
            `${getLabel(accessGroupOptions,rowData.accessGroup)}`
        )

    }

    const getAgentLabel = (array, value) => {
        for(let element of array) {
            if(element.uuid === value) {
                return element.name;
            }
        }

        return 'ALL';
    }

    const agentBodyTemplate = (rowData) => {

        return (
            `${getAgentLabel(agentGroupOptions,rowData.agentGroup)}`
        )

    }


    const deleteAgentGroupConfirmation = (rowData) => {
        confirmDialog({
            message: `Are you sure you want to delete agent group ${rowData.name}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteAgentGroup(rowData.uuid)
        });
    }

    const deleteAgentGroup = (uuid) => {

        DbAgentGroupDestroy(datacastProperties.userIp, uuid).then((result) => {
            if(result.error === 0) {
                showResult('success', 'Agent Group destroyed', result.message);
                setRefreshKey(oldKey => oldKey +1);
            }
            else {
                showResult('error', 'Could not destroy agent group', result.message);
            }

        }).catch((error) => {
            console.error(error);
        });
    }

    const deleteGroupConfirmation = (rowData) => {
        confirmDialog({
            message: `Are you sure you want to delete group ${rowData.GroupName}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteGroup(rowData.GroupUuid)
        });
    }

    const deleteGroup = (uuid) => {

        DatacastAPIGroupDestroy(datacastProperties.ip, uuid).then((result) => {
            if(result.error === 0) {
                showResult('success', 'Group destroyed', result.message);
                setRefreshKey(oldKey => oldKey +1);
            }
            else {
                showResult('error', 'Could not destroy group', result.message);
            }

        }).catch((error) => {
            console.error(error);
        });
    }


    const editAgentBodyTemplate = (rowData) => {
        if(rowData.type === 'Agent') {
            return(
                <>
                    <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={(e)=>deleteAgentGroupConfirmation(rowData)}/>
                </>
            );
        }
    }

    const editAgentsTemplate = (rowData) => {
        if(rowData.type === 'Agent') {
            return(
                <>
                    <Link to={`/editagentgroup`} state={rowData} style={{ textDecoration: 'none' }}>
                        <Button icon="pi pi-users" className="p-button-rounded p-button-secondary p-button-text" />
                    </Link>
                </>
            );
        }
    }
    


    const onTextEditAgentComplete = (e) => {
        let { rowData, newValue, field } = e;

        DbAgentGroupSet(datacastProperties.userIp, {id:rowData.id, name:newValue }).then((result) => {
            if(result) {
                rowData[field] = newValue;
                setRefreshKey(oldKey => oldKey +1);
            }

        }).catch((error) => {
            console.error(error);
        });

    }

    const editBodyTemplate = (rowData) => {
        return(
            <>
                <Link to={`/editgroup`} state={rowData} style={{ textDecoration: 'none' }}>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary p-button-text" />
                </Link>
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={(e)=>deleteGroupConfirmation(rowData)}/>
            </>
        );
    }


    const onTextEditComplete = (e) => {
        let { rowData, newValue, field } = e;
        
        DatacastAPIGroupSet(datacastProperties.ip, {GroupUuid:rowData.GroupUuid, GroupName:newValue }).then((result) => {
            if(result) {

                if(result.error === 0) {
                    rowData[field] = newValue;
                    setRefreshKey(oldKey => oldKey +1)
                }
            }

        }).catch((error) => {
            console.error(error);
        });

    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }


    const groupEditor = (options) => {
        return (
            <Dropdown value={options.value?options.value:'ALL'} options={accessGroupOptions} optionLabel="GroupName" optionValue="GroupUuid"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Select a group" />
        );
    }

    const groupAgentEditor = (options) => {
        return (
            <Dropdown value={options.value?options.value:'ALL'} options={agentGroupOptions} optionLabel="name" optionValue="uuid"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Select a group" />
        );
    }

    const acceptDeleteUser = (userId) => {
        DbUserDestroy(datacastProperties.userIp, userId).then((result) => {
            if(result) {

                if(result.error !== 0) {
                    showResult('error', 'Could not destroy user', result.message);
                }
                else {
                    showResult('success', 'User destroyed', result.message);
                    setRefreshKey(oldKey => oldKey +1)
                }
            }

        }).catch((error) => {
            console.error(error);
        });
    }

    const deleteUser = (rowData) => {
        confirmDialog({
            message: `Are you sure you want to delete user ${rowData.name}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptDeleteUser(rowData.id)
        });
    }

    const editUserBodyTemplate = (rowData) => {
        return(
            <>
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={(e)=>deleteUser(rowData)}/>
            </>
        );
    }


    const acceptReset = (id) => {
        const newPassword = generateRandomPassword();
        setNewPassword(newPassword);

        const salt = bcrypt.genSaltSync(10);
        const hashedPassword = bcrypt.hashSync(newPassword, salt) // hash created previously created upon sign up
        
        DbUserSet(datacastProperties.userIp, 
            {
                id:id,
                password:hashedPassword
            }).then((result) => {
            
            if(result.error === 0) {
                setVisible(true);
            }
            else {
                showResult('error', `Password`, 'Could not reset password');
            }

        }).catch((error) => {
            console.error(error);
        });

    }
    
    const resetPassword = (rowData) => {
        confirmDialog({
            message: `Are you sure you want to reset the password for ${rowData.name}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptReset(rowData.id)
        });
        
    }

    const resetBodyTemplate = (rowData) => {
        return(
            <>
                <Button icon="pi pi-refresh" className="p-button-rounded p-button-text" onClick={(e)=>resetPassword(rowData)}/>
            </>
        );
    }

    const showResult = (severity, summary, detail) => {
        toast.current.show({severity:severity, summary:summary, detail:detail});
    }

    const userInput = (field, value) => {

        setCortex({...cortex, [field]:value.substring(0,36)})
        setDirty(true);
    }

    const updateCortex = () => {
        if(Object.keys(cortex).length > 0 ) {
            DbCortexSet(datacastProperties.userIp, cortex).then((result)=>{
                if(result.error === 0) {

                    DatacastAPIUpdateAgentGroup(datacastProperties.ip, {GroupUuid:cortex.uuid, GroupName:cortex.name}).then((result)=>{

                        if(result.error === 0) {
                            showResult('success', `Cortex`, 'Cortex updated');
                        }
                        else {
                            showResult('error', `Cortex`, 'Datacast Cloud could not be updated');
                        }
                    })
                    
                }
                else {
                    showResult('error', `Cortex`, 'Cortex could not be updated');
                }
            })
        }
    }

    const setPermission = (rowData, field, value) => {
        const group = {...rowData, [field]:value};
        
        DbAgentGroupSet(datacastProperties.userIp, group).then((result) => {
            showResult('success', `Permissions`, 'Permissions updated');
            setRefreshKey(oldKey => oldKey +1)
        }).catch((error) => {
            console.error(error);
        });
    }

    const editPermissionsTemplate = (rowData, field) =>{
        if(rowData[field] === 1) {
            return(
                <Button icon="pi pi-check" className="p-button-rounded p-button-text p-button-success" onClick={()=>setPermission(rowData, field, 0)}/>
            );
        }
        else {
            return(
                <Button icon="pi pi-times" className="p-button-rounded p-button-text p-button-danger" onClick={()=>setPermission(rowData, field, 1)}/>
            );
        }
    }

    const editPermissionsTreeTemplate = (rowData, field) =>{
        let _field = `${field}${rowData.type}`;

        const data = {
            id:rowData.id,
        }

        if(rowData[field] === 1) {
            return(
                <Button icon="pi pi-check" className="p-button-rounded p-button-text p-button-success" onClick={()=>setPermission(data, _field, 0)}/>
            );
        }
        else {
            return(
                <Button icon="pi pi-times" className="p-button-rounded p-button-text p-button-danger" onClick={()=>setPermission(data, _field, 1)}/>
            );
        }
    }

    const panelHeader = (options, title) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';

        return(
        <div className='flex w-full align-content-center' style={{backgroundColor:"rgb(64,64,64)"}}>
            <Button className={`${options.togglerClassName} p-button-text`} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
            </Button>
            <div className='p-3 text-white font-bold'>{`${title}`}</div>
        </div>)
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <div>{`${rowData.name} (${rowData.email})`}</div>
        )
    }

    const rowClass = (rowData) => {
        return(rowData.even?"bg-gray-50":'');
    }

    if(datacastProperties.admin) {
        return (
            
            <>
                <Toast ref={toast} />
                <Dialog visible={visible} header="New Password" onHide={()=>setVisible(false)}>
                    {`The new password is set to  ${newPassword}`}
                </Dialog>
                <div className='flex justify-content-between align-items-center' style={{backgroundColor:"rgb(64,64,64)"}}>
                    <div className='p-3 text-white font-bold'>CORTEX</div>
                    <div className={`${dirty?'':'hidden'}`}>
                        <Button className='mr-2 p-button-outlined p-button-rounded p-button-success' icon='pi pi-check' onClick={()=>updateCortex()}/>
                    </div>
                </div>
                <div className='flex flex-wrap my-2 align-items-center'>
                    <div className='col-12 md:col-6 xl:col-3'>
                        <div className='mb-1'>Name</div>
                        <div className='flex w-full'>
                            <InputText className='surface-100 w-full border-round-md border-none' value={cortex.name} keyfilter={/[\w\d]{1,36}/i} onChange={(e) => userInput('name',e.target.value)} placeholder="Cortex Name"/>
                        </div>
                    </div>
                    <div className='col-12 md:col-6 xl:col-3'>
                        <div className='mb-1'>NETWORK ID</div>
                        <div className='flex '>
                            <InputText className='surface-100 w-full border-round-md border-none' value={cortex.uuid} disabled/>
                        </div>
                    </div>
                    <div className='col-12 md:col-6 xl:col-3'>
                        <div className='mb-1'>DATACAST IP</div>
                        <div className='flex w-full'>
                            <InputText className='surface-100 w-full border-round-md border-none' value={cortex.cloudIp} keyfilter={/[0-9.:]{1,36}/i} onChange={(e) => userInput('cloudIp',e.target.value)} placeholder="CLOUD IP"/>
                        </div>
                    </div>
                    <div className='col-12 md:col-6 xl:col-3'>
                        <div className='mb-1'>CORTEX IP</div>
                        <div className='flex '>
                            <InputText className='surface-100 w-full border-round-md border-none' value={cortex.cortexIp} keyfilter={/[0-9.:]{1,36}/i} onChange={(e) => userInput('cortexIp',e.target.value)} placeholder="CORTEX IP"/>
                        </div>
                    </div>
                </div>                
                <Panel headerTemplate={(options)=>panelHeader(options, 'USERS')} toggleable >
                    <div>
                        <DataTable value={users} editMode="row" onRowEditComplete={onRowEditComplete} responsiveLayout="scroll" paginator rows={4}>
                            <Column field="email" header="User Name" body={nameBodyTemplate} ></Column>
                            <Column field="activated" header="Activated" body={activatedBodyTemplate} editor={(options) => checkEditor(options)}  ></Column>
                            <Column field="admin" header="Admin" body={adminBodyTemplate} editor={(options) => checkEditor(options)}  ></Column>
                            <Column field="accessGroup" header="Public Group" body={accessBodyTemplate} editor={(options) => groupEditor(options)} ></Column>
                            <Column field="agentGroup" header="Agent Group" body={agentBodyTemplate} editor={(options) => groupAgentEditor(options)} ></Column>
                            <Column header="Password" body={resetBodyTemplate} ></Column>
                            <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'end' }}></Column>
                            <Column field='Edit' body={editUserBodyTemplate} style={{ width: '10%' }}></Column>
                        </DataTable>
                    </div>
                </Panel>
                <Panel headerTemplate={(options)=>panelHeader(options,'AGENT GROUPS')} toggleable collapsed={true}>
                    {/*<DataTable value={agentGroups} responsiveLayout="scroll" editMode='cell' paginator rows={4}>
                        <Column field="name" header="Group Name" editor={(options) => textEditor(options)} onCellEditComplete={onTextEditAgentComplete}></Column>
                        <Column body={(e)=>editPermissionsTemplate(e, 'createAgent')} field="createAgent" header="Create Agent"  ></Column>
                        <Column body={(e)=>editPermissionsTemplate(e, 'destroyAgent')} field="destroyAgent" header="Delete Agent"  ></Column>
                        <Column body={(e)=>editPermissionsTemplate(e, 'editAgent')} field="editAgent" header="Edit Agent"  ></Column>
                        <Column body={(e)=>editPermissionsTemplate(e, 'createStream')} field="createStream" header="Create Stream"  ></Column>
                        <Column body={(e)=>editPermissionsTemplate(e, 'destroyStream')} field="destroyStream" header="Delete Stream"  ></Column>
                        <Column body={(e)=>editPermissionsTemplate(e, 'editStream')} field="editStream" header="Edit Stream"  ></Column>
                        <Column body={(e)=>editPermissionsTemplate(e, 'createSource')} field="createSource" header="Create Source"  ></Column>
                        <Column body={(e)=>editPermissionsTemplate(e, 'destroySource')} field="destroySource" header="Delete Source"  ></Column>
                        <Column body={(e)=>editPermissionsTemplate(e, 'editSource')} field="editSource" header="Edit Source"  ></Column>
                        <Column body={(e)=>editAgentsTemplate(e)} field="editAgents" header="Agents"  ></Column>
                        <Column  body={editAgentBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }} ></Column>
                    </DataTable>*/}
                    <DataTable value={agentTree} 
                            paginator rows={6}
                            rowGroupMode="rowspan"
                            groupRowsBy="group"
                            rowClassName={rowClass}>
                        <Column field="group" header="Agent Group" />
                        <Column field="name" />
                        <Column body={(e)=>editPermissionsTreeTemplate(e, 'create')} field="create" header="Create"  ></Column>
                        <Column body={(e)=>editPermissionsTreeTemplate(e, 'destroy')} field="destroy" header="Delete"  ></Column>
                        <Column body={(e)=>editPermissionsTreeTemplate(e, 'edit')} field="edit" header="Edit"  ></Column>
                        <Column body={(e)=>editAgentsTemplate(e)} field="editAgents" header="Agents"  ></Column>
                        <Column  body={editAgentBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }} ></Column>
                    </DataTable>
                
                    <div className='align-content-center mt-2'>
                        <span className='ml-2 vertical-align-middle'>
                            <Link to="/createagentgroup" style={{ textDecoration: 'none' }}>
                                <div className='flex align-items-center'>
                                    <div><Button icon="pi pi-plus" className="p-button-rounded bg-blue-600" /></div>
                                    <div className='ml-2 border-bottom-1'>Create Agent Group</div>
                                </div>
                            </Link>
                        </span>
                    </div>
                </Panel>
                <Panel headerTemplate={(options)=>panelHeader(options,'PUBLIC GROUPS')} toggleable collapsed={true}>
                    <DataTable value={accessGroups} responsiveLayout="scroll" editMode='cell'>
                        <Column field="GroupName" header="Group Name" editor={(options) => textEditor(options)} onCellEditComplete={onTextEditComplete}></Column>
                        <Column field="GroupUuid" header="UUID"  ></Column>
                        <Column  body={editBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }} ></Column>
                    </DataTable>
                    <div className='align-content-center mt-2'>
                        <span className='ml-2 vertical-align-middle'>
                            <Link to="/creategroup" style={{ textDecoration: 'none' }} >
                                <div className='flex align-items-center'>
                                    <div><Button icon="pi pi-plus" className="p-button-rounded bg-blue-600" /></div>
                                    <div className='ml-2 border-bottom-1'>Create Public Group</div>
                                </div>
                            </Link>
                        </span>
                    </div>
                </Panel>
            </>            
        
        )
    }
    else {
        return(
            <Navigate to="/" />
        )
    }
}

export default Admin;