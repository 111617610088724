
export const DatacastAPIGetServers = async (ip) => {
    const response = await fetch(`/api/v1/datacast/srtservers/streamid`);
    const data = await response.json();

    if(data){
        const _appServers = [];

        data.forEach(element => {
            
            _appServers.push({
                value:element.Uuid,
                label:element.Name,
                source:element.SourceStreamID,
                destination:element.DestinationStreamID,
                app:element.App,
                ipAddr:element.PublicIp,
                port:element.DataPort
            });
        });

        return _appServers;

    }   
    
    return [];
    
}

export const DatacastAPIGetSourcesMap = async (ip,access = null) => {

    const response = await fetch(`/api/v1/datacast/streams/sources/${(access !== 'ALL' && access !== null )?access:''}`);
    const data = await response.json();

    if(data){
        const _appServers = {};

        data.forEach(element => {
          _appServers[element.Source] = element;
        });

        return _appServers;

    }   
    
    return [];
    
}

export const DatacastAPICreateAgentGroup= async (db, group) => {

  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(group)
  };

  const response = await fetch(`/api/v1/datacast/agents/creategroup`, requestOptions);
  const data = await response.json();

  return data;
}

export const DatacastAPIUpdateAgentGroup= async (db, agent) => {

  const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(agent)
  };

  const response = await fetch(`/api/v1/datacast/agents/updategroup`, requestOptions);
  const data = await response.json();

  return data;
}

export const DatacastAPICreateAgent= async (db, agentkey, agent) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(agent)
    };

    const response = await fetch(`/api/v1/datacast/agents/create/${agentkey}`, requestOptions);
    const data = await response.json();

    return data;
}

export const DatacastAPIDestroyAgent= async (db, uuid) => {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/datacast/agents/${uuid}`, requestOptions);
    const data = await response.json();

    return data;
}


export const DatacastAPIUpdateAgent= async (db, agent) => {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(agent)
    };

    const response = await fetch(`/api/v1/datacast/agents`, requestOptions);
    const data = await response.json();

    return data;
}


export const DatacastAPICreateGroup= async (db, group) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(group)
    };

    const response = await fetch(`/api/v1/datacast/accessgroups/create`, requestOptions);
    const data = await response.json();

    return data;
}

export const DatacastAPIGroupSet= async (db, group) => {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(group)
    };

    const response = await fetch(`/api/v1/datacast/accessgroups/`, requestOptions);
    const data = await response.json();

    return data;
}

export const DatacastAPIGroups= async (db) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/datacast/accessgroups/`, requestOptions);
    const data = await response.json();

    return data;
}

export const DatacastAPIGroupDestroy= async (db, uuid) => {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/datacast/accessgroups/${uuid}`, requestOptions);
    const data = await response.json();

    return data;
}

export const DatacastAPIInfo= async (db, uuid) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/datacast/`, requestOptions);
    const data = await response.json();

    return data;
}

export const DatacastAPIPublicStreams= async (db) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/datacast/accessgroups/streams/public`, requestOptions);
    if(response.ok) {
      const data = await response.json();
        let map = {}
        for(let stream of data) {
          map[stream.Source] = stream;
        }

        return map;
    }

    return {};
}

export const DatacastAPIGroupStreams= async (db, uuid) => {

  const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
  };

  const response = await fetch(`/api/v1/datacast/accessgroups/streams/${uuid}`, requestOptions);
  const data = await response.json();

  return data;
}

export const DatacastAPIDestroyStreams= async (db, type, source, server, agent) => {

  const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
  };

  const response = await fetch(`/api/v1/datacast/streams?type=${type}&source=${source}&server=${server}&agent=${agent}`, requestOptions);
  const data = await response.json();

  return data;
}

export const DatacastAPIGroupStreamSet= async (db, uuid, group) => {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(group)
    };

    const response = await fetch(`/api/v1/datacast/accessgroups/stream/${uuid}`, requestOptions);
    const data = await response.json();

    return data;
}

export const DatacastAPIGroupStreamsSet= async (db, uuid, group) => {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(group)
    };

    const response = await fetch(`/api/v1/datacast/accessgroups/streams/${uuid}`, requestOptions);
    const data = await response.json();

    return data;
}

export const DatacastAPIGetPaths = async (db, access = null) => {

    const response = await fetch(`/api/v1/datacast/paths/group${(access !==null)?`/${access}`:''}`);
    if(response.ok) {
        const _paths = await response.json();

        return _paths;
    }
    

    return [];
}

export const DatacastAPIGetPath = async (db, source, agent, server) => {

    const response = await fetch(`/api/v1/datacast/paths/${source}/${agent}/${server}`);

    if(response.ok) {
        const _paths = await response.json();

        return _paths;
    }
    

    return [];
}


export const DatacastAPIGetStreams = async (db) => {
    const response = await fetch(`/api/v1/datacast/streams`);
    const data = await response.json();

    return data;
}


export const DatacastAPIGetAlerts = async (db, group = null) => {
    const response = await fetch(`/api/v1/datacast/alerts/${(group !== null)?group:''}`);
    const data = await response.json();

    return data;
}


export const DatacastAPIGetServerDrops = async (db, server) => {
    const response = await fetch(`/api/v1/datacast/srtservers/${server}/Dropped`);
    const servers = await response.json();

    return servers;
}

export const DatacastAPIGetStreamStatsOverview = async (db, server, inDate, interval=5, type='Rtt') => {
    
    //2022-04-28 14:28:25 
    const date = new Date(inDate.toISOString());
    const searchDate = `${date.getUTCFullYear()}-${("00" + (date.getUTCMonth() + 1)).slice(-2)}-${("00" + date.getUTCDate()).slice(-2)} ${("00" + date.getUTCHours()).slice(-2)}:${("00" + date.getUTCMinutes()).slice(-2)}:00`;

    const response = await fetch(`/api/v1/datacast/srtservers/${server}/${type}/overview?interval=${interval} MINUTE${(searchDate)?`&date=${encodeURIComponent(searchDate)}`:''}`);
    const _streams = await response.json();

    return _streams;
}

export const DatacastAPIGetStreamStats = async (db, server, inDate, serviceIds, interval = 5, type="Rtt") => {

    
    const date = new Date(inDate.toISOString());

   //2022-04-28 14:28:25 
    const searchDate = `${date.getUTCFullYear()}-${("00" + (date.getUTCMonth() + 1)).slice(-2)}-${("00" + date.getUTCDate()).slice(-2)} ${("00" + date.getUTCHours()).slice(-2)}:${("00" + date.getUTCMinutes()).slice(-2)}:00`;
    
    const response = await fetch(`/api/v1/datacast/srtservers/${server}/${type}/data?interval=${interval} MINUTE${(searchDate)?`&date=${encodeURIComponent(searchDate)}`:''}&services=[${serviceIds}]`);
    const _data = await response.json();

    const _dataMap = {};
    const _dataStreams = [];
      
    for(let item of _data) {
      if(_dataMap[item.StreamID] === undefined) {
        _dataMap[item.StreamID]  = _dataStreams.length;
        
        _dataStreams.push({
          name:`${item.StreamID}`,
          data:[]
        })
      }


      _dataStreams[_dataMap[item.StreamID]].data.push({
        x:new Date(item.StatDate),
        y:item.Value
      })

    }

    return(_dataStreams);
  }

  export const DatacastAPIGetServersAvailability = async (db) => {
    const response = await fetch(`/api/v1/datacast/srtservers/availability`);
    if(response.ok) {
      const servers = await response.json();

      return servers;
    }
  }

  export const DatacastAPIGetStreamDropped = async (db, streamId) => {
    const response = await fetch(`/api/v1/datacast/streams/${streamId}/Dropped`);

    if(response.ok) {
      const raw = await response.json();

      return raw.data;
    }
    else {
        return []
    }
  }

  export const DatacastAPIGetAllStreamStats = async (db, streamId, inDate, interval=5) => {

    let searchDate = null;
    
    if(inDate) {
      //2022-04-28 14:28:25 
      const date = new Date(inDate.toISOString());

      searchDate = `${date.getUTCFullYear()}-${("00" + (date.getUTCMonth() + 1)).slice(-2)}-${("00" + date.getUTCDate()).slice(-2)} ${date.getUTCHours()}:${date.getUTCMinutes()}:00`;
    }
    
    const response = await fetch(`/api/v1/datacast/streams/${streamId}/statistics?interval=${interval} MINUTE${(searchDate)?`&date=${encodeURIComponent(searchDate)}`:''}`);

    if(response.ok) {
      const raw = await response.json();
      const _statData = raw.data;

      const _data = [
        {name:'Bitrate', data:[]},
        {name:'Packets', data:[]},
        {name:'Lost', type: 'column', data:[]},
        {name:'Dropped', type: 'column', data:[]},
        {name:'Retry', type: 'column', data:[]},
        {name:'RTT', data:[]},
      ];

      const dataMap = {
        RcvBitrate:0,
        SndBitrate:0,
        RcvPackets:1,
        SndPackets:1,
        RcvLost:2, 
        SndLost:2,
        Retry:4,
        Dropped:3,
        Rtt:5
      };
      
      if(_statData) {
        //Check to see what kind of stats we have.
        let hasSrc = false;
        let hasDst = false;
        for(let idx=0; idx < Math.min(_statData.length, 15);idx++) {
          const stat = _statData[idx];
          if(stat.Type === 'dst') {
            hasDst = true;
          }

          if(stat.Type === 'src') {
            hasSrc = true;
          }

        }

        if(hasDst && hasSrc) {
          for(let stat of _statData) {
            const date = new Date(stat.StatDate);

            if(stat.Type === 'dst') {
              _data[dataMap.RcvBitrate].data.push({
                  x:date,
                  y:stat.RcvBitrate
              });

              _data[dataMap.RcvPackets].data.push({
                x:date,
                y:stat.RcvPackets
              });

              if(stat.RcvLost > 0) {
                _data[dataMap.RcvLost].data.push({
                  x:date,
                  y:stat.RcvLost
                });
              }

              if(stat.Dropped > 0) {
                _data[dataMap.Dropped].data.push({
                  x:date,
                  y:stat.Dropped
                });
              }

              _data[dataMap.Rtt].data.push({
                x:date,
                y:stat.Rtt
              });


            }
            else {
              if(stat.Retry > 0) {
                _data[dataMap.Retry].data.push({
                    x:date,
                    y:stat.Retry
                });
              }
            }

          }
        }
        else {
          if(hasSrc) {
            for(let stat of _statData) {
              const date = new Date(stat.StatDate);
              if(stat.Type === 'src') {
                _data[dataMap.SndBitrate].data.push({
                    x:date,
                    y:stat.SndBitrate
                });

                _data[dataMap.SndPackets].data.push({
                  x:date,
                  y:stat.SndPackets
                });

                if(stat.SndLost > 0) {
                  _data[dataMap.SndLost].data.push({
                    x:date,
                    y:stat.SndLost
                  });
                }

                if(stat.Dropped > 0) {
                  _data[dataMap.Dropped].data.push({
                    x:date,
                    y:stat.Dropped
                  });
                }

                _data[dataMap.Rtt].data.push({
                  x:date,
                  y:stat.Rtt
                });
                
                if(stat.Retry > 0) {
                  _data[dataMap.Retry].data.push({
                      x:date,
                      y:stat.Retry
                  });
                }
              }
            }
          }

          if(hasDst) {
            for(let stat of _statData) {
              const date = new Date(stat.StatDate);
  
              if(stat.Type === 'dst') {
                _data[dataMap.RcvBitrate].data.push({
                    x:date,
                    y:stat.RcvBitrate
                });
  
                _data[dataMap.RcvPackets].data.push({
                  x:date,
                  y:stat.RcvPackets
                });
  
                if(stat.RcvLost > 0) {
                  _data[dataMap.RcvLost].data.push({
                    x:date,
                    y:stat.RcvLost
                  });
                }
  
                if(stat.Dropped > 0) {
                  _data[dataMap.Dropped].data.push({
                    x:date,
                    y:stat.Dropped
                  });
                }
  
                _data[dataMap.Rtt].data.push({
                  x:date,
                  y:stat.Rtt
                });

                if(stat.Retry > 0) {
                  _data[dataMap.Retry].data.push({
                      x:date,
                      y:stat.Retry
                  });
                }
              }
            }
          }
        }
      }

      return(_data);
    } 

    return [];
  }

