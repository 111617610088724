

export const DbAgents = async (db=null) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/agents`, requestOptions);

    if(response.ok) {
        const data = await response.json();
        if(data.error === 0)    {
            return data.data;
        }
    }

    return [];
}

export const DbAgentsWithStreams = async (db=null) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/agents/streams`, requestOptions);

    if(response.ok) {
        const data = await response.json();
        if(data.error === 0)    {
            return data.data;
        }
    }

    return [];
}

export const DbAgentsMap = async (db=null) => {

    const agents = await DbAgents(db);

    if(agents) {
        const _agentMap = {};
        for(let agent of agents) {
            _agentMap[agent.uuid] = agent;
        }

        return _agentMap;
    }

    return null;
}

export const DbAgentStreams= async (db, agent) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/agents/${agent?`${agent}/`:''}streams`, requestOptions);

    if(response.ok) {
        const result = await response.json();

        if(result.error === 0 && result.data && result.data.length > 0){
            if(agent) {
                return result.data[0];
            }
            else {
                return result.data;
            }
        }
    }
    
    return [];
}

export const DbAgentStreamCreate= async (db, agentId, stream) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stream)
    };

    const response = await fetch(`/api/v1/mistiq/agents/${agentId}/stream/create`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbAgent= async (db, id) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/agents/${id}`, requestOptions);
    const data = await response.json();

    return data;
}


export const DbAgentTunnelRef= async (db, id, tunnel) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(tunnel)
    };

    const response = await fetch(`/api/v1/mistiq/agents/${id}/tunnel/ref`, requestOptions);
    if(response.ok) {
        const result = await response.json();
        if(result.error === 0) {
            return result.data;
        }
    }

    return null;
}

export const DbAgentTunnelDref= async (db, tunnel) => {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(tunnel)
    };

    const response = await fetch(`/api/v1/mistiq/agents/tunnel/dref`, requestOptions);
    if(response.ok) {
        const result = await response.json();
        if(result.error === 0) {
            return result.data;
        }
    }

    return null;
}

export const DbAgentTunnelsSet= async (db, id, tunnel) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(tunnel)
    };

    const response = await fetch(`/api/v1/mistiq/agents/${id}/tunnel`, requestOptions);
    if(response.ok) {
        const result = await response.json();
        if(result.error === 0) {
            return result.data;
        }
    }

    return null;
}

export const DbAgentTunnelDelete= async (db, id) => {

    const requestOptions = {
        method: 'DELETE'
    };

    const response = await fetch(`/api/v1/mistiq/agents/tunnel?id=${id}`, requestOptions);
    if(response.ok) {
        const result = await response.json();
        if(result.error === 0) {
            return result.data;
        }
    }

    return null;
}

export const DbAgentTunnels= async (db, id) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/agents/${id}/tunnels`, requestOptions);
    if(response.ok) {
        const result = await response.json();
        if(result.error === 0) {
            return result.data;
        }
    }

    return null;
}



export const DbAgentSet = async (db, agent) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(agent)
    };

    const response = await fetch(`/api/v1/mistiq/agents`, requestOptions);
    const data = await response.json();

    return data;
}


export const DbCreateAgent = async (db, agent) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(agent)
    };

    const response = await fetch(`/api/v1/mistiq/agents/create`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbAgentDestroy = async (db, uuid) => {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/agents/${uuid}`, requestOptions);
    const data = await response.json();

    return data;
}