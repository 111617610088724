import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { DatacastAPICreateGroup } from '../db/datacast';
import { DatacastContext } from '../context/datacastcloud';

import uuid from 'react-uuid';

const CreateGroup = () => {
 
    const [group, setGroup] = useState({
        GroupName:"",
        GroupUuid:uuid(),
    })

    const [dirty, setDirty] = useState(false);

    const [ datacastProperties ] = useContext(DatacastContext);
    const navigate = useNavigate();

    

    const createGroup = () => {
        if(dirty) {
            DatacastAPICreateGroup(datacastProperties.ip, group).then((result) => {
                
                if(result.error === 0) {
                    navigate('/admin');
                }

            }).catch((error) => {
                console.error(error);
            });
        }

    }

    const userInput = (field, value) => {

        setGroup({...group, [field]:value.substring(0,36)})
        setDirty(true);
    }

    return (
        <>
            <div className='flex align-content-center' style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='p-3 text-white font-bold'>CREATE ACCESS GROUP</div>
            </div>
            <div className='border-solid surface-border border-1'>
                <div className='p-6'>
                    
                    <div className='grid col-12'>
                        <div className='col-12 md:col-6'>
                            <div className='font-bold mb-1'>Name</div>
                            <div className='flex w-full'>
                                <InputText className='surface-100 w-full border-round-md border-none' keyfilter="alphanum" value={group.GroupName} onChange={(e) => userInput('GroupName',e.target.value)} placeholder="Joe Smith"/>
                            </div>
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='font-bold mb-1'>UNIQUE IDENTIFIER</div>
                            <div className='flex '>
                                <InputText className='surface-100 w-full border-round-md border-none' value={group.GroupUuid}  disabled/>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='flex justify-content-end mt-4' >
                                <Link to="/admin" style={{ textDecoration: 'none' }} >
                                    <Button label="Cancel" className="p-button-danger" icon="pi pi-times" />
                                </Link>
                                <div className='pl-2'>
                                    <Button label="Create" className="p-button-primary" icon="pi pi-check" onClick={createGroup} disabled={!dirty}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>            
    
    )
}

export default CreateGroup;