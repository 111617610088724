import React, { useState, useEffect, useContext, useRef } from 'react';


import { useLocation, useNavigate } from "react-router-dom";
import { useInterval } from 'primereact/hooks';

import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

import { DatacastContext } from '../context/datacastcloud';

import { DbAgentTunnelRef, DbAgentTunnelDref, DbAgentTunnelDelete, DbAgentTunnels } from '../db/agents';


const ViewAgent = () => {
 
    const [datacastProperties] = useContext(DatacastContext);
    const tunnelRef = useRef(null);
    const iFrameRef = useRef(null);

    const location = useLocation();
    const initAgent = location.state?.agent;
    const prevUrl = location.state?.from;
    const [hideSpinner, setHideSpinner] = useState(false);
    
    const navigate = useNavigate();
    const [url, setUrl] = useState('');

    useInterval(
        () => {

            const checkAndReconnect = async () =>{
                const _agent = await DbAgentTunnels(datacastProperties.ip, initAgent.uuid);
                let reconnect = false;
                if(_agent.agent_tunnels && _agent.agent_tunnels.length > 0) {
                    const _httpTunnel = _agent.agent_tunnels.find((e)=>e.localPort === 80);
                    //If there is no valid tunnel then request 
                    if(!(_httpTunnel && _httpTunnel.enabled > 0)) {
                        reconnect = true;
                    }
                    else {
                        const newUrl = `${window.location.protocol}//${datacastProperties.sshIp}:${_httpTunnel.hostPort}`;
                        if(newUrl !== url) {
                            setUrl(newUrl);
                        }

                        sessionStorage.setItem("tunnel_id",`${_httpTunnel.id}`);
                        tunnelRef.current = _httpTunnel;
                    }
                }
                else {
                    reconnect = true;
                }

                if(reconnect) {
                    const _tunnel = await DbAgentTunnelRef(datacastProperties.userIp, initAgent.uuid, {localPort:80, localIp:"127.0.0.1"});
                    if(_tunnel && datacastProperties.sshIp && datacastProperties.sshIp.length > 0) {
                        setUrl(`${window.location.protocol}//${datacastProperties.sshIp}:${_tunnel.hostPort}`);
                        sessionStorage.setItem("tunnel_id",`${_tunnel.id}`);
                        tunnelRef.current = _tunnel;
                        setHideSpinner(false);
                    }
                }
            }

            if(url) {
                const requestOptions = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json', 
                    },
                    mode:'cors'
                };

                fetch(`${url}/api/v1/gateway/cortex`, requestOptions).then((response)=>{
                    if(response.ok) {
                        setHideSpinner(true);
                    }
                    else {
                        setHideSpinner(false);
                        checkAndReconnect();
                    }
                }).catch((err)=>{
                    setHideSpinner(false);
                    checkAndReconnect();
                    console.log(err);
                });
            }
        },
        5000,
        true
    );

    useEffect(() => {
        
        //Remove tunnel when exiting.
        return () =>{
            //Remove tunnel reference
            if(tunnelRef && tunnelRef.current) {
                const _tunnel = tunnelRef.current;
                if( _tunnel.id ) {
                    DbAgentTunnelDref(datacastProperties.userIp, {id:_tunnel.id}).then((result)=>{
                        sessionStorage.removeItem("tunnel_id");
                    });
                }
            }
        }
        
    }, [])


    //Runs when page loads.
    useEffect(() => {
        const initView = async (_datacastProperties, _initAgent) => {

            const _tunnel = await DbAgentTunnelRef(_datacastProperties.userIp, _initAgent.uuid, {localPort:80, localIp:"127.0.0.1"});
            if(_tunnel && _datacastProperties.sshIp && _datacastProperties.sshIp.length > 0) {
                setUrl(`${window.location.protocol}//${_datacastProperties.sshIp}:${_tunnel.hostPort}`);
                sessionStorage.setItem("tunnel_id",`${_tunnel.id}`);
                tunnelRef.current = _tunnel;
            }
        }
 
        initView(datacastProperties, initAgent).catch((error)=>console.error(error));
        
    },[]); 

    const goBack = () => {
        if(prevUrl === 'agents') {
            navigate('/agents');
        }
        else if (prevUrl === 'status') {
            navigate('/');
        }
        else {
            navigate('/agents');
        }
    }

    return (
        <>
            <div style={{backgroundColor:"rgb(64,64,64)"}}>
                <div className='flex align-items-center'>
                    <Button className='p-button-text p-button-lg' icon="pi pi-arrow-left" onClick={()=>goBack(-1)}></Button>
                    <div className='p-3 text-white font-bold'>VIEW AGENT</div>
                </div>
            </div> 
            <div className='border-solid surface-border border-1 w-full'>
                {
                    (hideSpinner)?
                    <iframe ref={iFrameRef} src={url} width={1600} height={800} ></iframe>
                    :
                    <div className="card flex w-full h-12rem justify-content-center align-items-center" >
                        <div className='flex flex-column mt-3'>
                            <div className='flex align-items-center justify-content-center text-900	text-lg'>Waiting for connection,  this could take up to 60 seconds...</div>
                            <div className='flex align-items-center justify-content-center my-5'>
                                <ProgressSpinner className={(hideSpinner)?'hidden':''} style={{width: '100px', height: '100px'}} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    
    )
}

export default ViewAgent;